import FileSaver from "file-saver";
import api from "services/api";

import { appActions } from "@/store/actions/app.actions";
import { hideModal } from "@/store/actions/modal.actions";

const isLoaded = value => {
	return {
		type: "VIEWER_ISLOADED",
		isLoading: !value,
		value
	};
};

const setIsMobile = value => {
	return {
		type: "VIEWER_ISMOBILE",
		value
	};
};

const isProcessing = value => {
	return {
		type: "VIEWER_ISPROCESSING",
		value
	};
};

const setNoAccess = value => {
	return {
		type: "VIEWER_NOACCESS",
		value
	};
};

const receiveData = data => {
	return {
		type: "VIEWER_RECEIVE_DATA",
		data
	};
};

const receiveVerseFormatting = (data, verseId) => {
	return {
		type: "VIEWER_RECEIVE_VERSE_FORMATTING",
		data,
		verseId
	};
};

const receiveInfoJson = data => {
	return {
		type: "VIEWER_RECEIVE_INFOJSON",
		data
	};
};

const receiveCharMap = data => {
	return {
		type: "VIEWER_RECEIVE_CHARMAP",
		data
	};
};

const setVerseActive = data => {
	return {
		type: "VIEWER_SET_VERSE_ACTIVE",
		data
	};
};

const setVerseEdit = data => {
	return {
		type: "VIEWER_SET_VERSE_EDIT",
		data
	};
};

const setSelectionEdit = data => {
	return {
		type: "VIEWER_SET_SELECTION_EDIT",
		data
	};
};

const setStyleActive = data => {
	return {
		type: "VIEWER_SET_STYLE_ACTIVE",
		data
	};
};

const setStyleCurrent = data => {
	return {
		type: "VIEWER_SET_STYLE_CURRENT",
		data
	};
};

const setInsertCharacter = data => {
	return {
		type: "VIEWER_SET_INSERT_CHARACTER",
		data
	};
};

const setVerseContent = (verseId, verseContent) => {
	return {
		type: "VIEWER_SET_VERSE_CONTENT",
		verseId,
		verseContent
	};
};

const setVerseStudentContent = (verseId, verseContent) => {
	return {
		type: "VIEWER_SET_VERSE_STUDENT_CONTENT",
		verseId,
		verseContent
	};
};

const setVerseStudentStatus = (verseId, verseStudentStatus) => {
	return {
		type: "VIEWER_SET_VERSE_STUDENT_STATUS",
		verseId,
		verseStudentStatus
	};
};

const setVerseImage = verseId => {
	return {
		type: "VIEWER_SET_VERSE_IMAGE",
		verseId
	};
};

const setVerseStatus = (verseId, verseStatus) => {
	return {
		type: "VIEWER_SET_VERSE_STATUS",
		verseId,
		verseStatus
	};
};

const updateVerseCommentsCount = (verseId, verseCommentsCount) => {
	return {
		type: "VIEWER_UPDATE_VERSE_COMMENTS_COUNT",
		verseId,
		verseCommentsCount
	};
};

const updateVerseSetAsPersonCount = (verseId, actionType, indexType) => {
	return {
		type: "VIEWER_UPDATE_VERSE_SET_AS_INDEX_COUNT",
		verseId,
		actionType,
		indexType
	};
};

const setCorrectTranscription = () => {
	return {
		type: "VIEWER_SET_CORRECT_TRANSCRIPTION"
	};
};

const setEmptyTranscription = () => {
	return {
		type: "VIEWER_SET_EMPTY_TRANSCRIPTION"
	};
};

const clearTranscriptionData = () => {
	return {
		type: "CLEAR_TRANSCRIPTION_DATA"
	};
};

const loadData = (transcriptionId, pageId) => {
	return dispatch => {
		dispatch(isLoaded(false));
		dispatch(setNoAccess(false));
		api.get(`/transcriptions/${transcriptionId}/pages/${pageId}`)
			.then(response => {
				let data = response.data;
				data.verses.sort((verse1, verse2) => { 
					if(verse1.verseNo === verse2.verseNo){
						return verse1.pageLayer?.layerId - verse2.pageLayer?.layerId;
					}
					return verse1.verseNo - verse2.verseNo; 
				});

				let layerIdToNumber = new Map();
				let layerNumberToId = new Map();
	
				response.data.pageLayers.sort((a, b) => {
					return a.layer.id - b.layer.id;
				});

				let savedLayerData = localStorage.getItem("transcription" + transcriptionId + "LayerData");
				let currentActiveLayer;
				if(savedLayerData){
					let saveLayerDataObject = JSON.parse(savedLayerData);
					if(saveLayerDataObject.selectedLayer && response.data.pageLayers.includes(saveLayerDataObject.selectedLayer)){
						dispatch(selectTranscriptionLayer(transcriptionId, saveLayerDataObject.selectedLayer));
						currentActiveLayer = saveLayerDataObject.selectedLayer;
					}else{
						dispatch(selectTranscriptionLayer(transcriptionId, response.data.pageLayers[0].layer.id));
						currentActiveLayer = response.data.pageLayers[0].layer.id;
					}
				}else{
					dispatch(selectTranscriptionLayer(transcriptionId, response.data.pageLayers[0].layer.id));
				}

				const readOnly = data.viewMode === 'read-only';

				let layerNumber = 0;
				for(let singleLayer of response.data.pageLayers){
					let visibility = true;
					if(savedLayerData){
						let saveLayerDataObject = JSON.parse(savedLayerData);
						visibility = saveLayerDataObject?.layerVisibility?.[singleLayer.layer.id];
						if(visibility === undefined) { visibility = true; }
					}

					layerNumber++;
					layerIdToNumber.set(singleLayer.layer.id, layerNumber);
					layerNumberToId.set(layerNumber, singleLayer.layer.id);
					singleLayer.isShown = !readOnly && visibility || singleLayer.layer.id == currentActiveLayer;
					dispatch(selectTranscriptionLayerVisibility(transcriptionId, singleLayer.layer.id, singleLayer.isShown));
				}

				dispatch(setTranscriptionLayersData({idToNumber: layerIdToNumber, numberToId: layerNumberToId, layersNum: layerNumber, layers: response.data.pageLayers}));

				api.get(`/transcriptions/${transcriptionId}/indexes`).then(response => {
					data = {
						...data,
						pageLayers: data.pageLayers.map(layerToEdit => {
							return {
								...layerToEdit,
							};
						}),
						verses: data.verses.map(verse => {
							let termIndexCount = 0;
							let placeIndexCount = 0;
							let personIndexCount = 0;
							response.data.filter(index => {
								for (let i = 0; i < index.verseIds.length; i++) {
									if (index.verseIds[i] === verse.id) {
										if(index.type === "term"){
											termIndexCount += 1;
										}else if(index.type === "person"){
											personIndexCount += 1;
										}else if(index.type === "place"){
											placeIndexCount += 1;
										}
									}
								}
							});
							return {
								...verse,
								indexes:{
									terms: termIndexCount,
									places: placeIndexCount,
									persons: personIndexCount,
								}
							};
						})
					};
					dispatch(changeWorkingPageLayer(1));
					dispatch(receiveData(data));
					dispatch(loadInfoJson(pageId));
				});
			})
			.catch(error => {
				if (error.status === 403) {
					dispatch(setNoAccess(true));
				}
			});
	};
};

const loadInfoJson = pageId => {
	return dispatch => {
		api.get(`/iiif/pages/${pageId}/info.json`).then(response => {
			dispatch(receiveInfoJson(response.data));
			dispatch(isLoaded(true));
		});
	};
};

const loadCharMap = () => {
	return dispatch => {
		api.get("/info/symbols").then(response => {
			dispatch(receiveCharMap(response.data));
		});
	};
};

const removeVerseFormatting = verseId => {
	return dispatch => {
		api.put(`/verses/${verseId}/formatting/clear`).then(response => {
			dispatch(setVerseActive(null));
			dispatch(setVerseEdit(null));
			dispatch(receiveVerseFormatting(response.data, verseId));
		});
	};
};

const deleteVerse = (verseId, pageId, transcriptionId) => {
	return dispatch => {
		dispatch(appActions.setLoading(true));
		api.delete(`/verses/${verseId}`).then(() => {
			api.get(`/transcriptions/${transcriptionId}/pages/${pageId}`).then(response => {
				dispatch(appActions.setLoading(false));
				dispatch(setVerseActive(null));
				dispatch(setVerseEdit(null));
				dispatch(reloadVerses(response.data.verses, transcriptionId));
				dispatch(hideModal());
			});
		});
	};
};

const deleteVerses = (versesIds, pageId, transcriptionId) => {
	return dispatch => {
		dispatch(appActions.setLoading(true));
		api.delete(`/verses?versesIds=${versesIds}`).then(() => {
			api.get(`/transcriptions/${transcriptionId}/pages/${pageId}`).then(response => {
				dispatch(appActions.setLoading(false));
				dispatch(setVerseActive(null));
				dispatch(setVerseEdit(null));
				dispatch(reloadVerses(response.data.verses, transcriptionId));
				dispatch(hideModal());
			});
		});
	};
};

const submitVerseFormatting = (verseId, typeFormatting, transcriptionId) => {
	return dispatch => {
		api.put(`/verses/${verseId}/${typeFormatting}`, "").then(response => {
			dispatch(setVerseActive(null));
			dispatch(setVerseEdit(null));
			switch (typeFormatting) {
				case "modify-content":
					dispatch(setVerseContent(verseId, ""));
					break;
				case "image":
					dispatch(setVerseContent(verseId, ""));
					dispatch(setVerseImage(verseId));
					break;
				case "move-up":
					dispatch(reloadVerses(response.data, transcriptionId));
					break;
				case "move-down":
					dispatch(reloadVerses(response.data, transcriptionId));
					break;
				default:
					dispatch(receiveVerseFormatting(response.data, verseId));
			}
		});
	};
};

const submitVerseContent = (verseId, verseContent, transcriptionId, pageId) => {
	return dispatch => {
		api.put(`/verses/${verseId}/modify-content`, JSON.stringify(verseContent.replace(/\n/g, "").toString())).then(() => {
			// dispatch(setVerseActive(null));
			dispatch(setVerseEdit(null));
			dispatch(setVerseContent(verseId, verseContent.replace(/\n/g, "")));
			api.get(`/transcriptions/${transcriptionId}/pages/${pageId}`).then(response => {
				dispatch(reloadVerses(response.data.verses, transcriptionId));
			});
		});
	};
};

const submitVerseStudentContent = (verseId, verseContent) => {
	return dispatch => {
		dispatch(setVerseStudentStatus(verseId, "none"));
		api.put(`/verses/${verseId}/student-content`, verseContent.replace(/\n/g, "")).then(({ data }) => {
			dispatch(setVerseActive(null));
			dispatch(setVerseEdit(null));
			dispatch(setVerseStudentContent(verseId, verseContent.replace(/\n/g, "")));
			dispatch(setVerseStudentStatus(verseId, data));
		});
	};
};

const submitCorrectTranscription = pageId => {
	return dispatch => {
		api.put(`/verses/${pageId}/change-all-status`).then(() => {
			dispatch(setVerseActive(null));
			dispatch(setVerseEdit(null));
			dispatch(setCorrectTranscription());
		});
	};
};

const submitEmptyTranscription = pageId => {
	return dispatch => {
		api.put(`/pages/${pageId}/empty`).then(() => {
			dispatch(setEmptyTranscription());
		});
	};
};

const submitAutoTranscribeVerse = (verseId, lang) => {
	return dispatch => {
		dispatch(appActions.setLoading(true));
		api.put(`/verses/${verseId}/content-recognition?lang=${lang}`)
			.then(response => {
				dispatch(appActions.setLoading(false));
				dispatch(setVerseActive(null));
				dispatch(setVerseEdit(null));
				dispatch(setVerseContent(verseId, response.data));
				dispatch(hideModal());
			})
			.catch(() => {
				dispatch(hideModal());
			});
	};
};

const submitAutoTranscribePage = (transcriptionId, pageId, lang, ocrMode, selectedLayer) => {
	return dispatch => {
		dispatch(appActions.setLoading(true));
		api.put(
			`/transcriptions/${transcriptionId}/pages/${pageId}/recognize?lang=${lang}&layerId=${selectedLayer}&ocrMode=${ocrMode}`
		)
			.then(response => {
				dispatch(appActions.setLoading(false));
				dispatch(setVerseActive(null));
				dispatch(setVerseEdit(null));
				dispatch(reloadVerses(response.data, transcriptionId));
				dispatch(hideModal());
			})
			.catch(() => {
				dispatch(hideModal());
			});
	};
};

const getFileByFormat = (pageId, format) => {
	return () => {
		api.get(`/pages/${pageId}/content?type=${format}`, { responseType: "blob" }).then(response => {
			let fileName = response.headers["content-disposition"].split("filename=")[1];
			FileSaver.saveAs(response.data, fileName);
		});
	};
};

const reloadVerses = (verses, transcriptionId) => {
	return dispatch => {
		verses.sort((verse1, verse2) => { 
			if(verse1.verseNo === verse2.verseNo){
				return verse1.pageLayer.layerId - verse2.pageLayer.layerId;
			}
			return verse1.verseNo - verse2.verseNo; 
		});
		const data = verses;
		let dataWithIndex;
		api.get(`/transcriptions/${transcriptionId}/indexes`).then(response => {
			dataWithIndex = data.map(verse => {
				let termIndexCount = 0;
				let placeIndexCount = 0;
				let personIndexCount = 0;
				response.data.filter(index => {
					for (let i = 0; i < index.verseIds.length; i++) {
						if (index.verseIds[i] === verse.id) {
							if(index.type === "term"){
								termIndexCount += 1;
							}else if(index.type === "person"){
								personIndexCount += 1;
							}else if(index.type === "place"){
								placeIndexCount += 1;
							}
						}
					}
				});
				return {
					...verse,
					indexes:{
						terms: termIndexCount,
						places: placeIndexCount,
						persons: personIndexCount,
					}
				};
			});
			dispatch(reloadVersesAfterAddingIndexes(dataWithIndex));
		});
	};
};

const reloadVersesAfterAddingIndexes = verses => {
	return {
		type: "VIEWER_RELOAD_VERSES",
		verses
	};
};

const submitVerseStatus = (verseId, verseStatus, callback) => {
	return dispatch => {
		api.put(`/verses/${verseId}/status`, verseStatus)
			.then(function (response) {
				dispatch(setVerseStatus(verseId, response.data));
			})
			.catch(function (error) {
			}).then(() => {
			if (callback && typeof callback == "function") {
				callback();
			}
		});
	};
};

const changeWorkingPageLayer = workingPageLayer => {
	return {
		type: "VIEWER_CHANGE_WORKING_PAGE_LAYER",
		workingPageLayer
	};
};

const setLayerVisibility = (layerId, value) => {
	return {
		type: "VIEWER_SET_LAYER_VISIBILITY",
		layerId,
		value
	};
};

const setSingleVisibleLayer = layerId => {
	return {
		type: "VIEWER_SET_SINGLE_VISIBLE_LAYER",
		layerId
	};
};

const reloadVersesAfterChangingVisibility = verses => {
	return {
		type: "VIEWER_RELOAD_VERSES_AFTER_CHANGING_VISIBILITY",
		verses
	};
};

const searchTranscriptionForPhrase = (transcriptionId, phrase) => {
	return dispatch => {
		api.get(`/iiif/transcriptions/${transcriptionId}/search?q=${phrase}`)
		.then(response => {
			let totalHits = 0;
			let pages = new Map();
			
			if(response.data.resources){
				for(let i = 0; i < response.data.resources.length; ++i){
					let id = response.data.resources[i]["@id"];
					let verseId = id.split("/").pop();

					let on = response.data.resources[i]["on"];
					let pageId = on.split("#")[0].split("/").pop();

					totalHits++;

					if(pages.has(pageId)){
						pages.get(pageId).verses.push(verseId);
					}else{
						pages.set(pageId, {verses:[verseId]});
					}
				}
			}

			let results = {
				totalHits: totalHits,
				pages: pages
			}

			dispatch(setSearchResults(phrase, results));
		});
	}
};

const setSearchResults = (phraseSearchedFor, results) => {
	return {
		type: "VIEWER_SET_SEARCH_RESULTS",
		phraseSearchedFor,
		results
	}
};

const getTranscriptionPagesData = (transcriptionId) => {
	return dispatch => {
		api.get(`/transcriptions/${transcriptionId}/pages?fields=id,page-no`)
		.then(response => {
			let idToPageno = new Map();
			let pagenoToId = new Map();

			for(const singlePage of response.data){
				idToPageno.set(singlePage.id, singlePage.pageNo);
				pagenoToId.set(singlePage.pageNo, singlePage.id);
			}

			dispatch(setTranscriptionPagesData({idToPage: idToPageno, pageToId: pagenoToId, pageNum: idToPageno.size}));
		});
	}
}

const setTranscriptionPagesData = (transcriptionPagesData) => {
	return {
		type: "VIEWER_SET_TRANSCRIPTION_PAGES_DATA",
		transcriptionPagesData
	}
};

const setTranscriptionLayersData = (transcriptionLayersData) => {
	return {
		type: "VIEWER_SET_TRANSCRIPTION_LAYERS_DATA",
		transcriptionLayersData
	}
};

const selectTranscriptionLayer = (transcriptionId, layerId) => {
	return dispatch => {
		let currentItem = localStorage.getItem("transcription" + transcriptionId + "LayerData");

		let newItem = { layerVisibility: {} }
		if(currentItem){
			newItem = JSON.parse(currentItem);
			newItem.selectedLayer = layerId;
		}else{
			newItem.selectedLayer = layerId;
		}
		localStorage.setItem("transcription" + transcriptionId + "LayerData", JSON.stringify(newItem));

		dispatch(setSelectedTranscriptionLayer(layerId));
	}
};

const setSelectedTranscriptionLayer = (selectedLayer) => {
	return {
		type: "VIEWER_SET_SELECTED_TRANSCRIPTION_LAYER",
		selectedLayer
	}
};

const selectTranscriptionLayerVisibility = (transcriptionId, layerId, visibility) => {
	return dispatch => {
		let currentItem = localStorage.getItem("transcription" + transcriptionId + "LayerData");

		let newItem = { layerVisibility: {} }
		if(currentItem){
			newItem = JSON.parse(currentItem);
			newItem.layerVisibility[layerId] = visibility;
		}else{
			newItem.layerVisibility[layerId] = visibility;
		}
		localStorage.setItem("transcription" + transcriptionId + "LayerData", JSON.stringify(newItem));

		dispatch(setLayerVisibility(layerId, visibility));
	}
};

const setAllVersesOnPageAndLayerStatus = (transcriptionId, pageId, layerId, status) => {
	return dispatch => {
		api.put(`/verses/${pageId}/change-all-status?layerId=${layerId}&status=${status}`)
			.then((res) => {
				dispatch(reloadVerses(res.data, transcriptionId));
			});
	}
};

export const viewerActions = {
	loadData,
	loadCharMap,
	removeVerseFormatting,
	submitVerseFormatting,
	deleteVerse,
	deleteVerses,
	submitVerseContent,
	submitVerseStudentContent,
	submitVerseStatus,
	submitCorrectTranscription,
	submitEmptyTranscription,
	submitAutoTranscribeVerse,
	submitAutoTranscribePage,
	setVerseActive,
	setVerseEdit,
	setSelectionEdit,
	setStyleActive,
	setStyleCurrent,
	setInsertCharacter,
	reloadVerses,
	isLoaded,
	setIsMobile,
	isProcessing,
	getFileByFormat,
	clearTranscriptionData,
	updateVerseCommentsCount,
	updateVerseSetAsPersonCount,
	receiveVerseFormatting,
	changeWorkingPageLayer,
	setLayerVisibility,
	setSingleVisibleLayer,
	reloadVersesAfterChangingVisibility,
	searchTranscriptionForPhrase,
	setSearchResults,
	getTranscriptionPagesData,
	setTranscriptionPagesData,
	setTranscriptionLayersData,
	selectTranscriptionLayer,
	setSelectedTranscriptionLayer,
	selectTranscriptionLayerVisibility,
	setAllVersesOnPageAndLayerStatus
};
