import React, { Suspense, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Switch, useLocation, useHistory, Prompt } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import ReactGA from 'react-ga';
import ReactTooltip from 'react-tooltip';

import { appActions } from "@/store/actions/app.actions";
import { authActions } from "@/store/actions/auth.actions";

import routes from "@/router/routes";
import RouteLeavingGuard from "@/router/routeLeavingGuard";
import AppRoute from "@Components/App/AppRoute";

import wordpressApi, {setWordpressApiAddress} from "@/services/wordpressApi";

import Cookies from "@Components/Cookies/Cookies";
import Spinner from "@Elements/Spinner/Spinner";

import AppHeader from "@Templates/header/AppHeader/AppHeader";
import AuthHeader from "@Templates/header/AuthHeader/AuthHeader";
import AppFooter from "@Templates/footer/AppFooter/AppFooter";
import Error500 from "@Pages/Error/Error500";
import Error404 from "@Pages/Error/Error404";

import useStorageState from "@/utils/useStorageState";

import 'moment/locale/pl';
import moment from 'moment';

/**
 * Main component, that handles error pages and switching between pages.
 *
 * @component
 */
const App = () => {
	moment().locale("pl");

	const { i18n } = useTranslation();
	const history = useHistory();
	const [ configLoaded, setConfigLoaded ] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();
	const currentAppState = useSelector((state) => state.app);
	const isContrast = currentAppState.isContrast;
	const [isMourning, setIsMourning ] = useStorageState("mourn",currentAppState.isMourning);
	const currentLang = useSelector((state) => state.app.currentLang);
	const { t } = useTranslation();

	const isLoggedIn = useSelector((state) => state.auth.credentials.isLoggedIn);

	const googleAnalyticsID = useSelector(state => state.app.config.googleAnalyticsID);

	const discoveredLang = localStorage.getItem(i18n.options.detection.lookupLocalStorage);
	if (discoveredLang !== currentLang) {
		localStorage.setItem(i18n.options.detection.lookupLocalStorage, currentLang);
		i18n.changeLanguage(currentLang).then(value => {});
	}

	const loadConfigFile = async () => {
		const response = await fetch(
			'/config/config.json',
			{
				method: 'GET',
			},
		);
		const config = await response.json();
		dispatch(appActions.setAppConfig(config));
		setWordpressApiAddress(config.wordpressApi);
		setConfigLoaded(true);
	};

	useEffect(() => {
		loadConfigFile();
	}, []);

	useEffect(() => {
		document.title =t('title');
	}, [ currentLang ]);

	const isFontSize = currentAppState.fontSize;

	useEffect(() => {
		dispatch(appActions.saveCookie());
	}, [ currentAppState ]);

	useEffect(() => {
		if(configLoaded) {
			wordpressApi.get(`wp/v2/pages?slug=homepage`)
				.then(({data}) => {
					const isMourn = data[0].acf?.isMourn;
					setIsMourning(isMourn);
					dispatch(appActions.setMourning(isMourn));
				})
				.catch(() => {
				});
		}
	}, [configLoaded]);

	useEffect(() => {
		if (googleAnalyticsID) {
			ReactGA.initialize(googleAnalyticsID);
			ReactGA.pageview(location.pathname + location.search);
			ReactTooltip.rebuild();
		}
	}, [ location.pathname, googleAnalyticsID ]);

	useEffect(() => {
		if(configLoaded)
		{
			if (location.search) {
				const email = new URLSearchParams(location.search).get("email");
				if (email) {
					dispatch(authActions.fedSignIn(email, location.pathname));
				}
			}

			dispatch(appActions.getOCRProfiles());
			dispatch(authActions.checkAuth());
			dispatch(appActions.getQuestionnaireLink());
		}
	}, [configLoaded]);

	useEffect(() => {
		document.querySelector("html").classList[isMourning ? "add" : "remove"]("mourn");
	}, [ isMourning ]);

	useEffect(() => {
		document.querySelector("body").classList[isContrast ? "add" : "remove"]("contrast");
	}, [ isContrast ]);

	useEffect(() => {
		document.querySelector("html").classList.add(isFontSize);
	}, [ isFontSize ]);

	const handleBlockedNavigation = nextLocation => {
		if(!isLoggedIn && nextLocation.state?.isProtected)
			return true;

		return false;
	};

	return (
		<>
			{(currentAppState.isError || currentAppState.isNotFound) ? (
				<>
					{currentAppState.isError &&
						<>
							<AuthHeader />
							<Error500 />
						</>}
					{currentAppState.isNotFound &&
						<>
							<AppHeader />
							<Error404 />
							<AppFooter />
						</>}
				</>
			) : (
				<>
					{ configLoaded &&
					<Suspense fallback={ <Spinner /> }>
						<RouteLeavingGuard
							navigate={path => {
								console.log("navigate " + path);
								if(path.startsWith('https://'))
									window.location = path;
								else
									history.push(path);
							}}
							shouldBlockNavigation={handleBlockedNavigation}
						/>

						<Switch>
							{routes.map(route => (
								<AppRoute
									{ ...route }
									key={ route.id }
								/>
							))}
						</Switch>

					</Suspense>}
				</>
			)}

			{currentAppState.cookiePermission !== true &&
				<Cookies />}

			<ReactTooltip />
		</>
	);
};

App.propTypes = {
	routes: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			path: PropTypes.string,
			exact: PropTypes.bool,
			component: PropTypes.object,
		}).isRequired,
	),
};

export default App;