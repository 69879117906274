import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Financing from "@Pages/Home/parts/Financed/Financing";
import MainDescription from "@Pages/Home/parts/MainDescription/MainDescription";
import HomeTranscriptions from "@Pages/Home/parts/HomeTranscriptions/HomeTranscriptions";
import SearchTranscriptions from "@Elements/Search/SearchTranscriptions";
import Modules from "@Pages/Home/parts/Modules/Modules";
import Alert from "@Elements/Alert/Alert";

import api from "@/services/api";
import { appActions } from '@/store/actions/app.actions';

/** 
 * Home page with information about the website
 * 
 * @component
 */
const Home = () => {
	const dispatch = useDispatch();
	const [ lastTranscriptions, setLastTranscriptions ] = useState({});
	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);

	const isObject = (obj) => {
		return typeof obj === "object";
	};

	const isNotEmptyObject = (obj) => {
		return Object.keys(obj).length > 0 && obj.constructor === Object;
	};

	useEffect(() => {
		getLastTranscriptionsData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ isLoggedIn, dispatch ]);
    
	const getLastTranscriptionsData = () => {
		dispatch(appActions.setLoading(true));
		api.get('/last-transcriptions')
			.then(({data}) => {                              	
				setLastTranscriptions(data);
				dispatch(appActions.setLoading(false));				
			})
			.catch(() => {
				dispatch(appActions.setLoading(false));
			});
	};

	return (
		<>
			<MainDescription />
			<Alert />
			<main>
				<SearchTranscriptions isHomePage/>

				{lastTranscriptions.length > 0 &&
					<HomeTranscriptions
						transcriptions={ lastTranscriptions } 
						homeComponent='lastTranscribed'
					/>}
			</main>
			<Modules />
			<Financing />
		</>
	);
};

export default Home;
