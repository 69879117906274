import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { viewerActions } from '@/store/actions/viewer.actions';
import { CheckViewModePermission } from "@/permissions/ViewModes";

/** 
 * Current status of verse transcription, if it's to be transcribed, already transcribed, accepted or rejected
 * 
 * @component
 */
const VerseStatus = ({ verse }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const isMobile = useSelector((state) => state.viewer.isMobile);
	const viewMode = useSelector((state) => state.viewer.data.viewMode);

	const [ isTooltip, setIsTooltip ] = useState(false);

	const changeStatus = (event) => {
		const target = event.currentTarget;
		target.disabled = true;
		const statuses = viewMode === 'transcribe' ? [ "none", "transcribed" ] : [ "transcribed", "verified", "incorrect" ];
		const index = statuses.indexOf(verse.status) >= 0 ? statuses.indexOf(verse.status) : 0;
		const nextStatus = (statuses[index + 1]) ? statuses[index + 1] : statuses[0];
		dispatch(viewerActions.submitVerseStatus(verse.id, nextStatus, () => {
			target.disabled = false;
		}));
	};

	return (
		<div className='verse__status'>
			{isTooltip && (viewMode === 'transcribe' ? ( 
				<div className='verse__tooltip--status'>
					<div><div className='verse__tooltip--accept'><i className='icon-accept' /></div> {t('viewer.tooltip.status.clickTranscribe')}</div>
					<div><div className='verse__tooltip--accept'></div> {t('viewer.tooltip.status.clickNew')}</div>
				</div>
			):
				<div className='verse__tooltip--status'>
					<div><div className='verse__tooltip--accept-double'><i className='icon-accept' /><i className='icon-accept' /></div> {t('viewer.tooltip.status.click1')}</div>
					<div><div className='verse__tooltip--decline'><i className='icon-close' /></div> {t('viewer.tooltip.status.click2')}</div>
					<div><div className='verse__tooltip--accept'><i className='icon-accept' /></div> {t('viewer.tooltip.status.click3')}</div>
				</div>
			)}
			<button
				className={ `verse__checkbox${CheckViewModePermission("canCheckVerses", viewMode) && !isMobile ? ' verse__checkbox--active' : ''}` }
				onClick={ changeStatus }
				onMouseEnter={ () => setIsTooltip(true) }
				onMouseLeave={ () => setIsTooltip(false) }
				disabled={ !CheckViewModePermission("canCheckVerses", viewMode) }
			>
				<span className='d-none'>Status</span>
				{verse.status === 'transcribed' &&
					<div className='verse__checkbox--accept'>
						<i className='icon-accept' />
					</div>}

				{verse.status === 'verified' &&
					<div className='verse__checkbox--accept-double'>
						<i className='icon-accept' />
						<i className='icon-accept' />
					</div>}

				{verse.status === 'incorrect' &&
					<div className='verse__checkbox--decline'>
						<i className='icon-close' />
					</div>}

			</button>
		</div>
	);
};

VerseStatus.propTypes = {
	verse: PropTypes.object,
};

export default memo(VerseStatus);