import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router";
import { reverse } from "named-urls";
import { useHistory } from "react-router-dom";
import { Container, Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";

import api from "@/services/api";
import url from "@/router/urls";

import { appActions } from "@/store/actions/app.actions";
import { useShowModal } from "@Elements/Modal/ModalHooks";
import { CheckViewModePermission } from "@/permissions/ViewModes";

import Button from "@Elements/Button/Button";
import Breadcrumbs from "@Elements/Breadcrumbs/Breadcrumbs";
import FormInput from "@Elements/Form/FormInput";

import TranscriptionBackLink from "@Pages/Transcription/parts/TranscriptionBackLink";

import SearchTranscriptions from "@Elements/Search/SearchTranscriptions";
import SearchTypeEnum from "@/utils/SearchTypeEnum";

import styles from "@Pages/Transcription/TranscriptionLayers/TranscriptionLayers.module.sass";

/** 
 * Page with information about layers in transcription
 * 
 * @component
 */
const TranscriptionLayers = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);
	const isLoggingEnd = useSelector(state => state.auth.credentials.isLoggingEnd);
	const { transcriptionId } = useParams();
	const history = useHistory();
	const [layers, setLayers] = useState([]);
	const [ useMetadata, setUseMetadata ] = useState(SearchTypeEnum.ALL.toString());
	const [viewMode, setViewMode] = useState(null);

	const [title, setTitle] = useState("");
	const [dataLoaded, setDataLoaded] = useState(false);
	const [summarizeData, setSummarizeData] = useState([]);
	const [searchString, setSearchString ] = useState("");
	const [layerToUse, setLayerToUse] = useState();
	const [layerToDelete, setLayerToDelete] = useState(null);
	const [layerToEdit, setLayerToEdit] = useState(null);

	const { register, handleSubmit, errors, formState, setError, watch, reset, clearError, setValue, formState: { isValid } } = useForm({
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues: {
			name: layerToUse ? layerToUse.name : "",
			description: layerToUse ? layerToUse.description : "",
		},
	});


	const modalDeleteLayer = useShowModal({
		title:t('modals.deleteTranscriptionLayer.title'),
		name: "deleteTranscriptionLayer",
		
		componentPath: "./parts/LayersActions/ModalDeleteLayer",
		componentProps: { transcriptionId: parseInt(transcriptionId), id: layerToDelete ? layerToDelete.id : 0, onComplete: () => getTranscriptionLayersData()}
	});

	const onDeleteLayer = (layer) => {
		setLayerToDelete(layer)
	}

	useEffect(() => {
		if(layerToDelete)
			modalDeleteLayer();
	}, [layerToDelete]);


	const modalEditLayer = useShowModal({
		title:t('modals.editTranscriptionLayer.title'),
		name: "editTranscriptionLayer",
		
		componentPath: "./parts/LayersActions/ModalEditLayer",
		componentProps: { transcriptionId: parseInt(transcriptionId), layer: layerToEdit, onComplete: () => getTranscriptionLayersData()}
	});


	const onEditLayer = (layer) => {
		setLayerToEdit(layer)
	}

	useEffect(() => {
		if(layerToEdit)
		{
			modalEditLayer();
			setLayerToEdit(null)
		}
	}, [layerToEdit]);


	const linkToTranscriptionDetails = reverse(url.transcription.details, { transcriptionId: transcriptionId });

	useEffect(() => {
		//if (isLoggedIn && isLoggingEnd) {
			reloadData();
		// } else if (!isLoggedIn && isLoggingEnd) {
		// 	history.push(linkToTranscriptionDetails);
		// }
	}, [isLoggedIn, isLoggingEnd]);

	const reloadData = () => {
		dispatch(appActions.setLoading(true));
		getTranscriptionLayersData();
		getTranscriptionSummarizeData();
	};

	const getTranscriptionLayersData = () => {
		api.get(`/transcriptions/${transcriptionId}/layer`)
			.then(({ data }) => {
				setLayers(data);
			})
			.catch(() => {
				history.push(linkToTranscriptionDetails);
			});
	};

	const getTranscriptionSummarizeData = () => {
		api.get(`/transcriptions/${transcriptionId}`).then(({ data }) => {
			setViewMode(data.viewMode);
			
			setSummarizeData(data);
			setTitle(data.titleLabel);
			dispatch(appActions.setLoading(false));
			setDataLoaded(true);
		});
	};

	const breadcrumbsList = [
		{ id: 1, label: "home", link: url.home },
		{ id: 2, label:t('breadcrumbs.transcriptions'), link: url.transcriptions },
		{ id: 3, label: title, link: linkToTranscriptionDetails },
		{ id: 4, label:t('breadcrumbs.layers') }
	];
	

	const onSubmit = data => {

		dispatch(appActions.setLoading(true));

		const dataToSend = {
			transcriptionId: transcriptionId,
			name: data.name,
			description: data.description,
		};

	
		api.post(`/transcriptions/${transcriptionId}/layer`, dataToSend)
			.then(res => {
				dispatch(appActions.setLoading(false));
				getTranscriptionLayersData();
				reset();
			})
			.catch(err => {
				dispatch(appActions.setLoading(false));
			});
	}

	
	return (
		dataLoaded && (
			<main>
				<SearchTranscriptions
					setSearchString={ setSearchString }
					searchString={ searchString }
					setUseMetadata={ setUseMetadata }
				/>
				<Container className={styles["layers"]}>
					<Breadcrumbs breadcrumbsList={breadcrumbsList} />
					<TranscriptionBackLink transcriptionId={transcriptionId} />
					<div className={styles["layers__nav-wrapper"]}>
						<div className="block__header block__header--space-between">
							<h1 className="text--title">{t('breadcrumbs.layers')}</h1>
						</div>
					</div>
					<div className={styles["layers__status"]}>
						<div>
						{t('transcriptions.description.name')} <b>{summarizeData.titleLabel}</b>
						</div>
						<div>
						{t('transcriptions.description.projectAuthor')} <b>{summarizeData.creator.email}</b>
						</div>
					</div>
					<div className={styles["layers__status"]}>
					{t('transcriptions.description.layersDesc')}
					</div>

					<div className={styles["layers__table--caption"]}>
						{t('breadcrumbs.layers')} ({layers.length})
					</div>
					<div className={`${styles["layers__table--header"]} ${styles["layers__table--row"]}`}>
						<div>{t('transcriptions.layers.no')}</div>
						<div>{t('transcriptions.layers.name')}</div>
						<div>{t('transcriptions.layers.desc')}</div>
						<div/>
					</div>
					
					{ layers.map((layer, index) => (
						<div key={index} className={`${styles["layers__table--item"]} ${CheckViewModePermission("canOperateOnTranscriptionLayers", viewMode) ? styles["layers__table--row"] : styles["layers__table--row__readOnly"]} ${layers?.length <= 1 ? styles["layers__table--row-one"] : ""}`}>
							<div>{index+1}</div>
							<div className={styles["layers__table--item-desc"]}>{t('transcriptions.layers.name')}</div>
							<div className={styles["layers__table--item-name"]}>{layer.name}</div>
							<div className={styles["layers__table--item-desc"]}>{t('transcriptions.layers.desc')}</div>
							<div>{layer.description}</div>
							{ CheckViewModePermission("canOperateOnTranscriptionLayers", viewMode) &&
							<div className={`${styles["buttons"]} ${styles["layers__table--col-button"]} ${layers?.length <= 1 ? styles["buttonOne"] : ""}`}>
								{ layers?.length > 1 &&
								<Button
									variant='secondary'
									onClick={() => onDeleteLayer(layer) }
								>
									{t('transcriptions.layers.delete')}
								</Button>}
								<Button
									variant='primary'
									onClick={() => onEditLayer(layer) }
								>
									{t('transcriptions.layers.edit')}
								</Button>
							</div>}
						</div>
					))}

					{ CheckViewModePermission("canOperateOnTranscriptionLayers", viewMode) &&
						<>
							<div className={styles["layers__form"]}>
								<h4>{t('transcriptions.layers.addNew')}</h4>
								{layers.length < 3 &&
									<form 
										onSubmit={handleSubmit(onSubmit)}
									>
										<FormInput
											name='name'
											register={register}
											isRequired
											label={t('transcriptions.layers.layerName')}
											placeholder={t('transcriptions.layers.layerNamePlaceholder')}
											validateData={{
												validate: value => value !== "",
											}}
											errorMessage={errors.name && errors.name.message}
										/>
										<FormInput
											name='description'
											register={register}
											isRequired
											label={t('transcriptions.layers.layerDescription')}
											sublabel={t('transcriptions.layers.layerDescriptionSub')}
											//errorMessage={ errors.transcriptionInfo && errors.transcriptionInfo.documentsTimeRangeStart && errors.transcriptionInfo.documentsTimeRangeStart.message ? errors.transcriptionInfo.documentsTimeRangeStart.message : '' }
										/>

										<p><span className='asterisk'>*</span>{t('transcriptions.layers.info')}</p>	
										<Button
											variant='primary'
											type='submit'
											disabled={!isValid} 
										>
											{t('transcriptions.layers.addNew')}
										</Button>
									</form>}
							</div>									
							{ layers.length >= 3 &&
								<p>{t('transcriptions.layers.toManyWarning')}</p>}
						</>}
				</Container>
			</main>
		)
	);
};

export default TranscriptionLayers;
