import React, { memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router';
import PropTypes from "prop-types";

import { viewerActions } from "@/store/actions/viewer.actions";

import VerseInactive from "@Pages/Viewer/parts/Verse/VerseInactive";
import VerseActive from "@Pages/Viewer/parts/Verse/VerseActive";
import VerseReadOnly from "@Pages/Viewer/parts/Verse/VerseReadOnly";
import VerseInfo from "@Pages/Viewer/parts/Verse/VerseInfo";
import VerseStatus from "@Pages/Viewer/parts/Verse/VerseStatus";
import VerseIndex from "@Pages/Viewer/parts/Verse/VerseIndex";

import { CheckViewModePermission } from "@/permissions/ViewModes";

/** 
 * Verse used in ViewerEditor with multiple states
 * 
 * @component
 */
const Verse = ({ verse }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const isMobile = useSelector((state) => state.viewer.isMobile);
	const verseEdit = useSelector(state => state.viewer.editor.verseEdit);
	const viewMode = useSelector(state => state.viewer.data.viewMode);
	const mobilePanels = useSelector(state => state.app.viewer.mobilePanels);
	const selectedLayer = useSelector(state => state.viewer.selectedLayer);

	const removeVerseFormatting = useCallback(
		id => {
			dispatch(viewerActions.removeVerseFormatting(id));
		},
		[dispatch]
	);

	const toggleVerseActive = useCallback(
		value => {
			dispatch(viewerActions.setVerseActive(value ? value : null));
			let currentUrlParams = new URLSearchParams(window.location.search);
			currentUrlParams.set('verse', value? verse.id : "");
			history.push(window.location.pathname + "?" + currentUrlParams.toString());
		},
		[dispatch]
	);

	const toggleVerseEdit = useCallback(
		value => {
			dispatch(viewerActions.setVerseActive(value ? value : null));
			dispatch(viewerActions.setVerseEdit(value ? value : null));
			let currentUrlParams = new URLSearchParams(window.location.search);
			if(value){ currentUrlParams.set('verse', verse.id); } else { currentUrlParams.delete('verse'); }
			history.push(window.location.pathname + "?" + currentUrlParams.toString());
		},
		[dispatch]
	);

	return (
		<li
			className={`viewer__verses--item verse ${
				viewMode === "student" ? `verse__student-status--${verse.learningStatus}` : ``
			}`}
		>
			{selectedLayer === verse.pageLayer.layerId ? (
				verseEdit === verse.id ? (
					<VerseActive verse={verse} toggleVerseEdit={toggleVerseEdit} toggleVerseActive={toggleVerseActive} />
				) : (
					<VerseInactive verse={verse} toggleVerseEdit={toggleVerseEdit} toggleVerseActive={toggleVerseActive} />
				)
			) : (
				<VerseReadOnly verse={verse} />
			)}

			{!isMobile && <VerseInfo verse={verse} removeVerseFormatting={removeVerseFormatting} /> }

			{!isMobile && <VerseIndex verse={verse} removeVerseFormatting={removeVerseFormatting} /> }

			{!isMobile && CheckViewModePermission("canSeeVersesStatus", viewMode) && mobilePanels !== "student" && (
				<>
					<VerseStatus verse={verse} />
				</>
			)}
		</li>
	);
};

Verse.propTypes = {
	verse: PropTypes.object
};

export default memo(Verse);
