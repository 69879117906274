import React, { memo } from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from "@Elements/Button/Button";

const ViewerOSDMenu = ( { onToggleDrawMode, removeSelectedArea, osdMethods, drawMode, zoom, homeZoom } ) => {
	const { t } = useTranslation();

	const isEditingOverlay = useSelector((state) => state.viewer.ocd.selection);

	const ViewerOSDMenuItems = [
		{
			id: "toggle-draw-mode",
			tooltip:t('viewer.tooltip.osdMenu.addArea'),
			icon: "add-area",
			hideOnViewMode: true,
			disableOnEditing: true
		},
		{
			id: "remove-area",
			tooltip:t('viewer.tooltip.osdMenu.removeArea'),
			icon: "remove-area",
			hideOnViewMode: true,
			disableOnEditing: false,
			disableWhenAreaNotSelected: true,
			disableOnVerseTextEditing: true
		},
		{
			id: "zoom-in",
			tooltip:t('viewer.tooltip.osdMenu.zoomIn'),
			icon: "zoom-in",
			hideOnViewMode: false,
			disableOnEditing: false,
			clickFunction: osdMethods.zoomInFunction,
		},
		{
			id: "zoom-out",
			tooltip:t('viewer.tooltip.osdMenu.zoomOut'),
			icon: "zoom-out",
			hideOnViewMode: false,
			disableOnEditing: false,
			clickFunction: osdMethods.zoomOutFunction,
		},
		{
			id: "full-page",
			tooltip:t('viewer.tooltip.osdMenu.fullScreen'),
			icon: "fix-to-screen",
			hideOnViewMode: false,
			disableOnEditing: false,
			clickFunction: osdMethods.fullScreen,
		},
		{
			id: "fit-to-page",
			tooltip:t('viewer.tooltip.osdMenu.fitToPage'),
			icon: "fit-to-window",
			hideOnViewMode: false,
			disableOnEditing: false,
			clickFunction: osdMethods.fitToPage,
		},
	];

	const viewMode = useSelector((state) => state.viewer.data.viewMode);
	const readOnly = viewMode === 'read-only' || viewMode === 'student';
	const verseActive = useSelector((state) => state.viewer.editor.verseActive);
	const verseEdit = useSelector(state => state.viewer.editor.verseEdit);

	return (
		<ul className='ocd-toolbar viewer__menu--list'>
			{ViewerOSDMenuItems.map(item => {
				if (readOnly && item.hideOnViewMode){
					return null;
				}else{
					return (
						<li
							key={ item.id }
							className='viewer__menu--item'
							data-tip={ item.tooltip }
							data-place='right'
						>
							<Button
								id={ item.id }
								className={ item.id === 'toggle-draw-mode' && drawMode ? 'viewer__button--menu viewer__button--active' : 'viewer__button--menu' }
								disabled={ isEditingOverlay && item.disableOnEditing || !verseActive && item.disableWhenAreaNotSelected || verseEdit && item.disableOnVerseTextEditing }
								{ ...(item.id === 'toggle-draw-mode' ? 
									{ 
										onClick: () => onToggleDrawMode(),
										variant: (drawMode) ? 'viewer-button--active' : 'viewer-button',
									} :
									{ 
										...(item.id === 'remove-area' ?
										{
											onClick: () => removeSelectedArea(),
											variant: 'viewer-button',
										} :
										{
											onClick: item.clickFunction,
											variant: 'viewer-button',
										})
									})
								}
								{ ...(item.id === 'fit-to-page') ?
									{ disabled: zoom === homeZoom} : {}
								}
							>
								{item.icon && <>
									<span className='d-none'>${item.tooltip}</span>
									<i className={ `icon-${item.icon} viewer__menu--icon` } />
								</>}
								{item.iconImg && <>
									<span><img src={item.iconImg} /></span> 
								</>}
							</Button>
						</li>
					);
				}
				
			})}
		</ul>
	);
};
ViewerOSDMenu.propTypes = {
	onToggleDrawMode: PropTypes.func,
	removeSelectedArea: PropTypes.func,
	osdMethods: PropTypes.object,
	drawMode: PropTypes.bool,
	zoom: PropTypes.number,
	homeZoom: PropTypes.number,
};

export default memo(ViewerOSDMenu);