import React from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import url from "@/router/urls";

import "@Styles/_static-page.sass";

import howToStart1 from '@Assets/images/transcriptionHelpPage/howToStart/howToStart1.png';

import howToStart2 from '@Assets/images/transcriptionHelpPage/howToStart/howToStart2.png';

import howToStart3a from '@Assets/images/transcriptionHelpPage/howToStart/howToStart3a.png';
import howToStart3b from '@Assets/images/transcriptionHelpPage/howToStart/howToStart3b.png';

import howToStart4a from '@Assets/images/transcriptionHelpPage/howToStart/howToStart4a.png';
import howToStart4b from '@Assets/images/transcriptionHelpPage/howToStart/howToStart4b.png';

import howToStart5a from '@Assets/images/transcriptionHelpPage/howToStart/howToStart5a.png';
import howToStart5b from '@Assets/images/transcriptionHelpPage/howToStart/howToStart5b.png';

import howToStart6a from '@Assets/images/transcriptionHelpPage/howToStart/howToStart6a.png';
import howToStart6b from '@Assets/images/transcriptionHelpPage/howToStart/howToStart6b.png';

import howToStart7a from '@Assets/images/transcriptionHelpPage/howToStart/howToStart7a.png';
import howToStart7b from '@Assets/images/transcriptionHelpPage/howToStart/howToStart7b.png';

const HowToStart = () => {

	const currentLang = useSelector((state) => state.app.currentLang);
	const { t } = useTranslation();

	const imagesStart = {
		howToStart1: { pl: howToStart1, en: howToStart1 , de: howToStart1 },
		howToStart2: { pl: howToStart2, en: howToStart2 , de: howToStart2 },
		howToStart3a: { pl: howToStart3a, en: howToStart3a , de: howToStart3a },
		howToStart3b: { pl: howToStart3b, en: howToStart3b , de: howToStart3b },
		howToStart4a: { pl: howToStart4a, en: howToStart4a , de: howToStart4a },
		howToStart4b: { pl: howToStart4b, en: howToStart4b , de: howToStart4b },
		howToStart5a: { pl: howToStart5a, en: howToStart5a , de: howToStart5a },
		howToStart5b: { pl: howToStart5b, en: howToStart5b , de: howToStart5b },
		howToStart6a: { pl: howToStart6a, en: howToStart6a , de: howToStart6a },
		howToStart6b: { pl: howToStart6b, en: howToStart6b , de: howToStart6b },
		howToStart7a: { pl: howToStart7a, en: howToStart7a , de: howToStart7a },
		howToStart7b: { pl: howToStart7b, en: howToStart7b , de: howToStart7b },
	};
	
	return (
		<div className='static-page__content--instruction'>
			<ol>
				<li>
					<h3>	
						<Link to={ url.auth.register }>
							{t('transcriptionHelpPage.howToStart.step.step1link')}
						</Link>
						{t('transcriptionHelpPage.howToStart.step.step1title')}
					
					</h3>
					<p>{t('transcriptionHelpPage.howToStart.step.step1desc')}</p>
					<img
						src={ imagesStart.howToStart1[currentLang] }
					/>
				</li>
				<li>
					<h3>	
						<Link to={ url.auth.login }>
							{t('transcriptionHelpPage.howToStart.step.step2link')}
						</Link>
					</h3>
					<p>{t('transcriptionHelpPage.howToStart.step.step2desc')}</p>
					<img
						src={ imagesStart.howToStart2[currentLang] }
					/>
				</li>
				<li>
					<h3>	
						<Link to={ url.transcription.new }>
							{t('transcriptionHelpPage.howToStart.step.step3link')}
						</Link>
					</h3>
					<p>{t('transcriptionHelpPage.howToStart.step.step3desc')}</p>
					<img
						src={ imagesStart.howToStart3a[currentLang] }
					/>
					<img
						src={ imagesStart.howToStart3b[currentLang] }
					/>
				</li>
				<li>
					<h3>	
						{t('transcriptionHelpPage.howToStart.step.step4title')}
					</h3>
					<p>{t('transcriptionHelpPage.howToStart.step.step4desc')}</p>
					<img
						src={ imagesStart.howToStart4a[currentLang] }
					/>
					<img
						src={ imagesStart.howToStart4b[currentLang] }
					/>
				</li>
				<li>
					<h3>	
						{t('transcriptionHelpPage.howToStart.step.step5title')}
					</h3>
					<p>{t('transcriptionHelpPage.howToStart.step.step5desc')}</p>
					<img
						src={ imagesStart.howToStart5a[currentLang] }
					/>
					<img
						src={ imagesStart.howToStart5b[currentLang] }
					/>
				</li>
				<li>
					<h3>	
						{t('transcriptionHelpPage.howToStart.step.step6title')}
					</h3>
					<p style={{marginBottom: 0}}>{t('transcriptionHelpPage.howToStart.step.step6desc')}</p>
					<ul style={{paddingLeft: "15px"}}>
						<li><b>{t('transcriptionHelpPage.howToStart.step.preEndDesc1')}</b>{t('transcriptionHelpPage.howToStart.step.endDesc1')}</li>
						<li><b>{t('transcriptionHelpPage.howToStart.step.preEndDesc2')}</b>{t('transcriptionHelpPage.howToStart.step.endDesc2')}</li>
						<li><b>{t('transcriptionHelpPage.howToStart.step.preEndDesc3')}</b>{t('transcriptionHelpPage.howToStart.step.endDesc3')}</li>
					</ul>
					<img src={ imagesStart.howToStart6a[currentLang] } />
					<img src={ imagesStart.howToStart6b[currentLang] } />
				</li>
				<li>
					<h3>	
						{t('transcriptionHelpPage.howToStart.step.step7title')}
					</h3>
					<p>{t('transcriptionHelpPage.howToStart.step.step7desc')}</p>
					<img
						src={ imagesStart.howToStart7a[currentLang] }
					/>
					<img
						src={ imagesStart.howToStart7b[currentLang] }
					/>
				</li>
			</ol>
			
		</div>
	);
};

export default HowToStart;
  