import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Button from '@Elements/Button/Button';

import store from '@/store/store';
import url from "@/router/urls";
import api from "@/services/api";

import { appActions } from "@/store/actions/app.actions";

import styles from "@Templates/footer/AppFooter/AppFooter.module.sass";

/** 
 * Footer with links
 * 
 * @component
 */
const AppFooter = () => {
	const { t } = useTranslation();

	const url_ariadnaWww = useSelector((state) => state.app.config.ariadnaWwwUrl);
	const url_ariadnaEdu = useSelector((state) => state.app.config.ariadnaEduUrl);
	const url_ariadnaWol = useSelector((state) => state.app.config.ariadnaWolUrl);
	const [ uiBuild, setUiBuild ] = useState({});
	const [ blBuild, setBlBuild ] = useState({});

	const dictionaryUrl = useSelector(state => state.app.config.dictionary);
	const contactUrl = useSelector(state => state.app.config.contact);
	const privacyPolicyUrl = useSelector(state => state.app.config.privacyPolicy);
	const accessibilityStatementUrl = useSelector(state => state.app.config.accessibilityStatement);
	const regulationsUrl = useSelector(state => state.app.config.regulations);

	const ariadnaWwwParticipantsUrl = useSelector(state => state.app.config.ariadnaWwwParticipants);
    const ariadnaWwwProjectInfoUrl = useSelector(state => state.app.config.ariadnaWwwProjectInfo);
    const ariadnaWwwFinancialInfoUrl = useSelector(state => state.app.config.ariadnaWwwFinancialInfo);

	const fetchUiBuild = async () => {
		const value = await fetch('/buildinfo.json').then(response => response.json()).then(response => setUiBuild({...response, buildDate: response.buildDate*1000})); 
	}

	useEffect(()=>{
		if(process.env.REACT_APP_DEV_MODE){
			fetchUiBuild()
			api.get('/healthcheck/buildinfo')
				.then(response => setBlBuild({...response.data, buildDate: response.data.buildDate*1000}))
				.catch(() => {});
		}
	},[true]);

	const resetErrorFlags = () => {
		store.dispatch(appActions.setNotFound(false));
		store.dispatch(appActions.setError(false));
	};

	return (
		<footer className={ styles["footer"] } style={process.env.REACT_APP_DEV_MODE ? {position: "relative"} : null}  >
			<Container className={styles["footer__inner"]}>
				<div className={styles["footer__logo"]} ></div>

				<div className={styles["footer__links"]}>
					<div>
						<div className={styles["footer__block--header"]}>
							<span>{t('headers.menu.transcriptions')}</span>
						</div>
						<div className={styles["footer__block"]}>
							<Link 
								to={{pathname: url.transcriptionsUser, state: { isProtected: true } }}
								onClick={resetErrorFlags}
							>{t('headers.menu.myProjects') }</Link>
						</div>
						<div className={styles["footer__block"]}>
							<Link 
								to={{pathname: url.transcriptions, state: { isProtected: false } }}
								onClick={resetErrorFlags}
							>{t('headers.menu.allProjects') }</Link>
						</div>
					</div>
					<div>
						<div className={styles["footer__block--header"]}>
							<span>{t('headers.informations')}</span>
						</div>
						<div className={styles["footer__block"]}>
							<a href={contactUrl} target="_blank" rel="noopener noreferrer">{t('footer.links.contact')}</a>
						</div>
						<div className={styles["footer__block--light-header"]}>
							<span>{t('headers.informationsMenu.helpersInfo')}</span>
						</div>
						<div className={styles["footer__block"]}>
							<Link to={url.howToTranscribe} onClick={resetErrorFlags}>{t('headers.informationsMenu.howTo')}</Link>
						</div>
						<div className={styles["footer__block"]}>
							<a href={dictionaryUrl} target="_blank" rel="noopener noreferrer">{t('headers.informationsMenu.dictionary')}</a>
						</div>
						<div className={styles["footer__block"]}>
							<Link to={url.map} onClick={resetErrorFlags}>{t('headers.informationsMenu.pageMap')}</Link>
						</div>
						<div className={styles["footer__block--light-header"]}>
							<span>{t('headers.informationsMenu.privacyInfo')}</span>
						</div>
						<div className={styles["footer__block"]}>
							<a href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">{t('footer.links.privacyPolicy')}</a>
						</div>
						<div className={styles["footer__block"]}>
							<a href={accessibilityStatementUrl} target="_blank" rel="noopener noreferrer">{t('headers.informationsMenu.availability')}</a>
						</div>
						<div className={styles["footer__block"]}>
							<a href={regulationsUrl} target="_blank" rel="noopener noreferrer">{t('headers.informationsMenu.terms')}</a>
						</div>
					</div>
					<div>
						<div className={styles["footer__block--header"]}>
							<span>{t('headers.about')}</span>
						</div>
						<div className={styles["footer__block"]}>
							<a href={ariadnaWwwFinancialInfoUrl} target="_blank" rel="noopener noreferrer">{t('headers.aboutMenu.financed')}</a>
						</div>
						<div className={styles["footer__block"]}>
							<a href={ariadnaWwwProjectInfoUrl} target="_blank" rel="noopener noreferrer">{t('headers.aboutMenu.project')}</a>
						</div>
						<div className={styles["footer__block"]}>
							<a href={ariadnaWwwParticipantsUrl} target="_blank" rel="noopener noreferrer">{t('headers.aboutMenu.contributors')}</a>
						</div>
					</div>
					<div>
						<div className={styles["footer__block--header"]}>
							<span>{t('headers.modules')}</span>
						</div>
						<div className={styles["footer__block"]}>
							<a href={url_ariadnaWww} target='_blank' rel='noreferrer noopener'>{t('modules.title1')}</a>
						</div>
						<div className={styles["footer__block"]}>
							<a href={url_ariadnaEdu} target='_blank' rel='noreferrer noopener'>{t('modules.title2')}</a>
						</div>
						<div className={styles["footer__block"]}>
							<a href={url_ariadnaWol} target='_blank' rel='noreferrer noopener'>{t('modules.title3')}</a>
						</div>
					</div>


					{process.env.REACT_APP_DEV_MODE && 
						<div 
							className={styles["footer__build"]}
						>
							<div>
								<p>UI BUILD</p>
								<p>ID: {uiBuild.commitId}</p>
								<p>DATE: {new Date(parseInt(uiBuild.buildDate)).toLocaleString()}</p>
							</div>

							<div>
								<p>BL BUILD</p>
								<p>ID: {blBuild.commitId}</p>
								<p>DATE: {new Date(parseInt(blBuild.buildDate)).toLocaleString()}</p>								
							</div>
							
						</div>
					}
				</div>
				
			</Container>	
			<div className={styles["footer__rights"]}>
				<span>
					{t('footer.rights.copyright')} &copy; 2023 <a href="https://bs.katowice.pl/" target="_blank">{t('footer.rights.client')}</a> {t('footer.rights.createdBy')}
					<a
						href={t('footer.rights.pcss.url')}
						target='_blank'
					>
						{t('footer.rights.pcss.title')}
					</a>
				</span>
			</div>		
		</footer>
	);
};

export default AppFooter;