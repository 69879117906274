const viewerInitialState = {
	isLoaded: false,
	isSaving: false,
	isMobile: false,
	isProcessing: true,
	noAccess: false,
	workingPageLayer: null,
	data: {
		id: null,
		path: "",
		pageNo: null,
		boundingBox: {},
		pageLayers: [],
		verses: [],
		studentVerses: null,
		titleLabel: "",
		prevPageId: null,
		nextPageId: null,
		viewMode: "",
	},
	ocd: {
		tileSources: {},
		selection: false,
	},
	editor: {
		verseActive: null,
		verseEdit: null,
		styleActive: '',
		styleCurrent: {},
		addChar: '',
	},
	charMap: [],
	searchResults: {
		searchedPhrase: "",
		totalHits: [],
		pages: new Map()
	},
	transcriptionData: null,
	transcriptionPagesData: {
		idToPage: new Map(), 
		pageToId: new Map(), 
		pageNum: 0
	},
	transcriptionLayersData: {
		idToNumber: new Map(),
		numberToId: new Map(),
		layersNum: 0,
		layers: []	
	},
	selectedLayer: null
};

export default viewerInitialState;