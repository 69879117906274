import axios from 'axios';

let wordpressApiAddress = "";

const wordpressApi = axios.create({
	baseURL: `${wordpressApiAddress}/wp-json`,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	crossdomain: true,
});

export const setWordpressApiAddress = (value) => {
	wordpressApiAddress = value;
	wordpressApi.interceptors.request.use( config => {
		config.baseURL = `${wordpressApiAddress}/wp-json`;
		return config;
	}, function (error) {
		return Promise.reject(error);
	},
	);
};

export default wordpressApi;