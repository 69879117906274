import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import en from "@/locales/en/default.json";
import pl from "@/locales/pl/default.json";
import de from "@/locales/de/default.json";

const options = {
	order: ['querystring', 'localStorage', 'navigator'],
	lookupQuerystring: 'lang',
};

const resources = {
	en: {
		default: en,
	},
	pl: {
		default: pl,
	},
	de: {
		default: de,
	},
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		supportedLngs: ['pl', 'en', 'de'],
		fallbackLng: 'pl',
		detection: options,
		debug: true,
		defaultNS: 'default',
		ns: [ 
			'default',
		],
		load: 'languageOnly',
		resources: resources,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;