import React, { useLayoutEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import Breadcrumbs from "@Elements/Breadcrumbs/Breadcrumbs";
import styles from "@Pages/Map/Map.module.css";

import url from "@/router/urls";
import api from "@/services/api";
import { CheckSecurityGroupPermission } from "@/permissions/SecurityGroups";

/** 
 * Map of website
 * 
 * @component
 */
const Map = () => {
	const { t } = useTranslation();

	const isLoggedIn = useSelector((state) => state.auth.credentials.isLoggedIn);
	const [ UserType, setUserType ] = useState("USER");

	const ariadnaWwwUrl= useSelector(state => state.app.config.ariadnaWwwUrl);
	const ariadnaWwwMyCollectionsUrl = ariadnaWwwUrl + '/myCollections';
	const ariadnaWwwMySubjectsUrl = ariadnaWwwUrl + '/myTopics';

	const ariadnaServiceManagement = useSelector(state => state.app.config.ariadnaServiceManagement);
	const ariadnaUserManagement = useSelector(state => state.app.config.ariadnaUserManagement);
	const keycloak = new URL(ariadnaUserManagement).origin;
	const userProfile = keycloak + "/auth/realms/BSA/account";

	const ariadnaEduUrl	= useSelector(state => state.app.config.ariadnaEduUrl);
	const ariadnaEduMyProjectsUrl = ariadnaEduUrl+"/sciezki";

	const ariadnaWolUrl = useSelector(state => state.app.config.ariadnaWolUrl);
	const ariadnaWolMyProjectsUrl = ariadnaWolUrl+"/my-projects";


	const dictionaryUrl = useSelector(state => state.app.config.dictionary);
	const contactUrl = useSelector(state => state.app.config.contact);
	const privacyPolicyUrl = useSelector(state => state.app.config.privacyPolicy);
	const accessibilityStatementUrl = useSelector(state => state.app.config.accessibilityStatement);
	const regulationsUrl = useSelector(state => state.app.config.regulations);

	const ariadnaWwwParticipantsUrl = useSelector(state => state.app.config.ariadnaWwwParticipants);
    const ariadnaWwwProjectInfoUrl = useSelector(state => state.app.config.ariadnaWwwProjectInfo);
    const ariadnaWwwFinancialInfoUrl = useSelector(state => state.app.config.ariadnaWwwFinancialInfo);

	useLayoutEffect(() => {
		if (isLoggedIn === true) {
			api
				.get("/users/my-profile")
				.then(response => {
					const { user } = response.data;
					setUserType(user.securityGroup);
				});
		}
	}, [ isLoggedIn ]);

	const breadcrumbsList = [
		{ label: "home", id: 1 },
		{ label:t('headers.informationsMenu.pageMap'), id: 2 },
	];

	return (
		<main className={styles["map"]}>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
				<section className={styles["tree"]}>
					<ul>{t('breadcrumbs.home')}
						<li>
							<ul>{t('headers.menu.transcriptions')}
								<li><Link to={{pathname: url.transcriptionsUser, state: { isProtected: true } }}>{t('headers.menu.myProjects') }</Link></li>
								<li><Link to={{pathname: url.transcriptions, state: { isProtected: false } }}>{t('headers.menu.allProjects') }</Link></li>
							</ul>
						</li>
						<li>
							<ul>{t('headers.informations')}
								<li><a href={contactUrl} target="_blank" rel='noreferrer noopener'>{t('footer.links.contact')}</a></li>
								<li>
									<ul>{t('headers.informationsMenu.helpersInfo')}
										<li><Link to={url.howToTranscribe}>{t('headers.informationsMenu.howTo')}</Link></li>
										<li><a href={dictionaryUrl} target="_blank" rel='noreferrer noopener'>{t('headers.informationsMenu.dictionary')}</a></li>
										<li><span className={styles["selected-page"]}>{t('headers.informationsMenu.pageMap')}</span></li>
									</ul>
								</li>
								<li>
									<ul>{t('headers.informationsMenu.privacyInfo')}
										<li><a href={privacyPolicyUrl} target="_blank" rel='noreferrer noopener'>{t('footer.links.privacyPolicy')}</a></li>
										<li><a href={accessibilityStatementUrl} target="_blank" rel='noreferrer noopener'>{t('headers.informationsMenu.availability')}</a></li>
										<li><a href={regulationsUrl} target="_blank" rel='noreferrer noopener'>{t('headers.informationsMenu.terms')}</a></li>
									</ul>
								</li>

							</ul>
						</li>
						<li>
							<ul>{t('headers.about')}
								<li><a href={ariadnaWwwFinancialInfoUrl} target='_blank' rel='noreferrer noopener'>{t('headers.aboutMenu.financed')}</a></li>
								<li><a href={ariadnaWwwProjectInfoUrl} target='_blank' rel='noreferrer noopener'>{t('headers.aboutMenu.project')}</a></li>
								<li><a href={ariadnaWwwParticipantsUrl} target='_blank' rel='noreferrer noopener'>{t('headers.aboutMenu.contributors')}</a></li>
							</ul>
						</li>
						<li>
							<ul>{t('headers.modules')}
								<li><a href={ariadnaWwwUrl} target='_blank' rel='noreferrer noopener'>{t('modules.title1')}</a></li>
								<li><a href={ariadnaEduUrl} target='_blank' rel='noreferrer noopener'>{t('modules.title2')}</a></li>
								<li><a href={ariadnaWolUrl} target='_blank' rel='noreferrer noopener'>{t('modules.title3')}</a></li>
							</ul>
						</li>
						<li>
							<ul>{t('headers.menu.myAccountMenu.myAccount')}
								<li>
									<ul>{t("default:headers.menu.myAccountMenu.general")}
										<li>
											<a href={userProfile} target='_blank'>
												{t('headers.menu.myAccountMenu.myProfile')}
											</a>
										</li>
									</ul>
								</li>
								<li>
									<ul>{t('headers.menu.myAccountMenu.ariadna')}
										<li>
											<a href={ariadnaWwwMyCollectionsUrl} target='_blank'>
												{t('headers.menu.myAccountMenu.myCollections')}
											</a>
										</li>
										<li>
											<a href={ariadnaWwwMySubjectsUrl} target='_blank'>
												{t('headers.menu.myAccountMenu.myTopics')}
											</a>
										</li>
										{isLoggedIn && CheckSecurityGroupPermission("canAccessAriadnaServiceManagement", UserType) && <li>
											<a href={ariadnaServiceManagement} target='_blank'>
												{t('headers.menu.myAccountMenu.ariadnaServiceManagement')}
											</a>
										</li>}
										{isLoggedIn && CheckSecurityGroupPermission("canAccessAriadnaUserManagement", UserType) && <li>
											<a href={ariadnaUserManagement} target='_blank'>
												{t('headers.menu.myAccountMenu.ariadnaUserManagement')}
											</a>
										</li>}
									</ul>
								</li>
								<li>
									<ul>{t('headers.menu.myAccountMenu.historyTexts')}
										<li>
											<Link to={url.transcriptionsUser}>
												{t('headers.menu.myAccountMenu.historyTextsMyProjects')}
											</Link>
										</li>
										{isLoggedIn && CheckSecurityGroupPermission("canAccessProjectsDashboard", UserType) && <li>
											<Link to={url.auth.admin.projects}>
												{t('headers.menu.myAccountMenu.serviceManagement')}
											</Link>
										</li>}
										{isLoggedIn && CheckSecurityGroupPermission("canAccessUsersDashboard", UserType) && <li>
											<Link to={url.auth.admin.users}>
												{t('headers.menu.myAccountMenu.usersManagement')}
											</Link>
										</li>}
										{isLoggedIn && CheckSecurityGroupPermission("canManageTranscriptionExportToDLibra", UserType) && <li>
											<Link to={url.dLibraExport}>
												{t('headers.menu.myAccountMenu.projectsForDLibra')}
											</Link>
										</li>}
									</ul>
								</li>
								<li>
									<ul>{t('headers.menu.myAccountMenu.edu')}
										<li>
											<a href={ariadnaEduMyProjectsUrl} target='_blank'>
												{t('headers.menu.myAccountMenu.eduMyProjects')}
											</a>
										</li>
									</ul>
								</li>
								<li>
									<ul>{t('headers.menu.myAccountMenu.historyPlaces')}
										<li>
											<a href={ariadnaWolMyProjectsUrl} target='_blank'>
												{t('default:headers.menu.myProjects')}
											</a>
										</li>
									</ul>
								</li>
							</ul>
						</li>
					</ul>
				</section>
			</Container>
		</main>
	);
};

export default Map;
