import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { Dropdown } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { reverse } from 'named-urls';

import { useShowModal } from '@Elements/Modal/ModalHooks';
import url from "@/router/urls";
import Alert from "@Elements/Alert/Alert";
import Button from '@Elements/Button/Button';
import { CheckViewModePermission } from "@/permissions/ViewModes";

import styles from "@Pages/Transcription/TranscriptionDetails/TranscriptionDetails.module.sass";

const TranscriptionDetailsNav = ({ viewMode, creationMode, creationDate, transcriptionId, continuation, receiverId, creatorEmail, title, hidden }) => {
	const { t } = useTranslation();

	const history = useHistory();

	const modalDeleteTranscription = useShowModal({
		title:t('modals.deleteTranscription.title'),
		name: 'deleteTranscription',
		componentPath: './parts/ModalDeleteTranscription',
		componentProps: { transcriptionId: parseInt(transcriptionId) },
	});

	const onEditTranscription = (publish) => {
		const location = reverse(url.transcription.edit, { transcriptionId: parseInt(transcriptionId) });
		let query = "";
		if (publish) {
			query = "?publish=true";
		}
		history.push(location+query);
	};

	const modalDepublishTranscription = useShowModal({
		title:t('modals.depublishTranscription.title'),
		name: 'depublishTranscription',
		componentPath: './parts/ModalDepublishTranscription',
		componentProps: { transcriptionId: parseInt(transcriptionId) },
	});

	const linkToTranscriptionPermissions = reverse(url.transcription.permissions, { transcriptionId: transcriptionId });

	return (
		<div className={ styles["transcription__header"] }>			
			<div>
				<div className='block__header block__header--space-between'>
					<h1 className={ styles["transcription__title"] }>
						<span>{title}</span>
					</h1>
				</div>
				<Alert alertBoxName='titleBox' />
				<div className={ styles["transcription__mobileNav"] }>
					{(viewMode === 'admin') &&
						<Dropdown className={ styles["transcription__mobileNav--dropdown"] }>
							<Dropdown.Toggle
								variant='dropdown-secondary'
								id='details'
							>
								<i className='icon-hamburger' />
								{t('transcription.details.details')}
								<i className='icon-arrow-down-full' />
							</Dropdown.Toggle>
							<Dropdown.Menu className={ styles["transcription__mobileNav--dropdown-menu"] }>
								{CheckViewModePermission("canRemoveTranscription", viewMode) && <Dropdown.Item
									onClick={ () => modalDeleteTranscription() }
								>
									{t('transcription.details.remove')}
								</Dropdown.Item>}
								{CheckViewModePermission("canEditTranscription", viewMode) && <Dropdown.Item
									onClick={ () => onEditTranscription(false) }
								>
									{t('transcription.details.edit')}
								</Dropdown.Item>}
								{CheckViewModePermission("canPublishTranscription", viewMode) && hidden && <Dropdown.Item
									onClick={ () => onEditTranscription(true) }
								>
									{t('transcription.details.public')}
								</Dropdown.Item>}
								{CheckViewModePermission("canUnpublishTranscription", viewMode) && !hidden && <Dropdown.Item
									onClick={ () => modalDepublishTranscription }
								>
									{t('transcription.details.reversePublic')}
								</Dropdown.Item>}
							</Dropdown.Menu>
						</Dropdown>}
					
				</div>
			</div>
		

	
			<div className='block__header block__header--space-between block__underlined'>
				<h2 className={ styles["transcription__metadata"] }>
					{t('transcription.details.metadata')}
				</h2>
				<div className={ styles["transcription__desktopNav"] }>
					<div
						className='btn-group'
						role='group'
					>
						{CheckViewModePermission("canRemoveTranscription", viewMode) && <Button
							variant='secondary'
							className={ styles["transcription__link"] }
							onClick={ () => modalDeleteTranscription() }
						>
							{t('transcription.details.remove')}
						</Button>}
						
						{CheckViewModePermission("canEditTranscription", viewMode) && <Button
							variant='primary'
							onClick={ () => onEditTranscription(false) }
						>
							{t('transcription.details.edit')}
						</Button>}

						{CheckViewModePermission("canPublishTranscription", viewMode) && hidden && <Button
							variant='primary'
							onClick={ () => onEditTranscription(true) }
						>
							{t('transcription.details.public')}
						</Button>}

						{CheckViewModePermission("canUnpublishTranscription", viewMode) && !hidden && <Button
							variant='primary'
							onClick={ () => modalDepublishTranscription() }
						>
							{t('transcription.details.reversePublic')}
						</Button>}
					</div>
				</div>
			</div>
		</div>
	);
};

TranscriptionDetailsNav.propTypes = {
	viewMode: PropTypes.string,
	creationMode: PropTypes.string,
	creationDate: PropTypes.string,
	creatorEmail: PropTypes.string,
	transcriptionId: PropTypes.number,
	receiverId: PropTypes.number,
	continuation: PropTypes.object,
};

export default TranscriptionDetailsNav;
