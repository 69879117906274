import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import PropTypes from "prop-types";

import OpenSeadragon from "openseadragon";

import api from "@/services/api";
import { viewerActions } from "@/store/actions/viewer.actions";
import { appActions } from "@/store/actions/app.actions";
import { CheckViewModePermission } from "@/permissions/ViewModes";

/** 
 * Part of viewer showing an image of transcribed page with possibility of adding new verses and interacting with them
 * 
 * @component
 */
const ViewerOSD = ({ onToggleDrawMode, osdDrawMode, setOsdMethods, setHomeZoom, setZoom }) => {
	const isMobile = useSelector((state) => state.viewer.isMobile);
	const viewer = useSelector(state => state.viewer);
	const verses = useSelector(state => state.viewer.data.verses);
	const selectionEdit = useSelector(state => state.viewer.ocd.selection);
	const { transcriptionId, pageId } = useParams();
	const dispatch = useDispatch();
	const [wltOCD, setWltOCD] = useState(null);
	const [imageBounds, setImageBounds] = useState({ x: 0, y: 0 });
	const [drawing, setDrawing] = useState(false);
	const [versesToUse, setVersesToUse] = useState(viewer.data.verses)
	const [viewerInitialised, setViewerInitialised] = useState(false);
	const layersData = useSelector((state) => state.viewer.transcriptionLayersData);
	const selectedLayer = useSelector(state => state.viewer.selectedLayer);
	const history = useHistory();

	const viewMode = useSelector((state) => state.viewer.data.viewMode);

	// adding new layer
	let newOverlay = null;
	let newOverlayStartPoint = { x: 0, y: 0 };
	let dragged = false;

	// (save recent added to api to select it after set drawing mode to false):
	const [overlaysListToGetNewAddedToApi, setOverlaysListToGetNewAddedToApi] = useState(null);
	const [lastDrawnOverlayId, setLastDrawnOverlayId] = useState(null);

	// edit purpose:
	const [editOverlayId, setEditOverlayId] = useState(null);

	const openSeadragonConfig = {
		id: `ocd${viewer.data.id}`,
		showNavigationControl: false,
		tileSources: []
	};

	useEffect(() => {
		resetOverlays();
		overlaysAddOnMouseDown();
	}, [viewer.editor.verseActive]);

	useEffect(() => {
		if (wltOCD) {
			drawOverlaysFromApi(wltOCD,versesToUse);
			// if before reloading verses editor was in edit overlay mode - restore that feature
			if (editOverlayId) { setVerseEdited(editOverlayId); }
		}
		if (osdDrawMode) {
			const filtered = versesToUse.find(
				({ id }) => !overlaysListToGetNewAddedToApi.includes(parseInt(id))
			);
			if (filtered && filtered.id) {
				setLastDrawnOverlayId(filtered.id);
				setOverlaysListToGetNewAddedToApi(state => [...state, filtered.id]);
			}
		}
		if (!selectionEdit) { overlaysAddOnMouseDown(); }

		resetOverlays();
	}, [versesToUse]);
 
	const canvasPressHandler = (e) => {
		if(!e.originalEvent.doNotUnset){
			setVerseEdited(null);
			dispatch(viewerActions.setVerseActive(null));
		}
	}

	useEffect(() => {
		const OCD = OpenSeadragon(openSeadragonConfig);
		if (viewer.isLoaded === false) {
			OCD.destroy();
		} else {
			setWltOCD(OCD);
			OCD.open({ ...viewer.ocd.tileSources });
			OCD.addHandler("open", function () {
				const tiledImage = OCD.world.getItemAt(0);
				if (tiledImage.getFullyLoaded()) {
					initializeViewer(OCD);
				} else {
					tiledImage.addHandler("fully-loaded-change", initializeViewer(OCD));
				}
			});
			OCD.addHandler("zoom", () => zoomingButtons(OCD));
			OCD.addHandler("canvas-press", canvasPressHandler);
		}
	}, [viewer.isLoaded]);

	useEffect(() => {
		if (wltOCD) {
			setDrawOverlayMode(osdDrawMode);
		}
	}, [osdDrawMode]);

	const getVerseById = id => {
		for(let singleVerse of verses){
			if(+singleVerse.id === +id) {
				return singleVerse;
			}
		}
		return {};
	}

	const initializeViewer = ocd => {
		let imBounds = ocd.viewport.imageToViewportCoordinates(
			new OpenSeadragon.Point(viewer.data.boundingBox.lowerRightX, viewer.data.boundingBox.lowerRightY)
		);
		setImageBounds({ x: imBounds.x, y: imBounds.y });
		drawOverlaysFromApi(ocd, versesToUse);
		ocd.gestureSettingsMouse.clickToZoom = false;
		ocd.innerTracker.keyDownHandler = null;
		ocd.innerTracker.keyPressHandler = null;
		ocd.innerTracker.keyHandler = null;
		ocd.innerTracker.keyUpHandler = null;

		setViewerInitialised(true);
	};


	useEffect(() => {
		const shownLayers = []

		layersData.layers.map(layerToWork => {
			if(layerToWork.layer.id === selectedLayer) {
				shownLayers.push(parseInt(layerToWork.layer.id));
			}
		});

		setVersesToUse(viewer.data.verses.filter(verse => {
			return verse.pageLayer && shownLayers.includes(parseInt(verse.pageLayer.layerId || '-1'));
		}))

	}, [verses, layersData]);

	const drawOverlaysFromApi = (ocd, verses) => {
		ocd.clearOverlays();
		for (let i = 0; i < verses.length; i++) {
			let overlay = document.createElement("div");
			let bBox = verses[i].boundingBox;
			if(!bBox) { bBox = {upperLeftX: 0, upperLeftY: 0, lowerRightX: 0, lowerRightY: 0} } 

			overlay.id = "ovrly" + verses[i].id;

			overlay.className = "osd-overlay";
			if (viewer.editor.verseActive && verses[i].id === viewer.editor.verseActive) {
				overlay.className = "osd-overlay--highlight";
			}

			let rc = ocd.viewport.imageToViewportRectangle(
				bBox.upperLeftX,
				bBox.upperLeftY,
				bBox.lowerRightX,
				bBox.lowerRightY
			);
			let rct = new OpenSeadragon.Rect(rc.x, rc.y, rc.width - rc.x, rc.height - rc.y);

			osdAddOverlay(overlay, rct, ocd);
		}
	};
	const onOverlayClick = id => {
		const verseNum = parseInt(id);
		dispatch(viewerActions.setVerseActive(verseNum));
		let currentUrlParams = new URLSearchParams(window.location.search);
		currentUrlParams.set('verse', id);
		history.push(window.location.pathname + "?" + currentUrlParams.toString());
	};

	const zoomingButtons = ocd => {
		setZoom(ocd.viewport.getZoom());
		setHomeZoom(ocd.viewport.getHomeZoom());
	};

	const overlayPointerDownHandler = (eventInfo, _element) => {
		if (eventInfo.eventType === "pointerdown") {
			if(viewer.editor.verseActive === +_element.id.replace("ovrly", "")){
				if(!isMobile && CheckViewModePermission("canEditVerses", viewMode)){
					dispatch(viewerActions.setSelectionEdit(true));
					eventInfo.stopPropagation = true;
				}
			}else{
				onOverlayClick(_element.id.replace("ovrly", ""));
			}
			eventInfo.originalEvent.doNotUnset = true;
		}
	};

	const osdAddOverlay = (_element, _location, ocd) => {
		ocd.addOverlay({
			element: _element,
			location: _location
		});
		_element.elementMouseTracker = new OpenSeadragon.MouseTracker({
			element: _element,
			preProcessEventHandler: eventInfo => overlayPointerDownHandler(eventInfo, _element),
		});
	};

	// data necessary for transform overlay:
	const resizingData = {
		startTransformOverlayBounds: {
			x: 0,
			y: 0,
			width: 0,
			height: 0
		},
		drag: {
			startX: 0,
			startY: 0
		},
		maxMove: {
			left: 0,
			top: 0,
			right: 0,
			bottom: 0
		}
	};
	let currOverlay = null;

	useEffect(() => {
		if (wltOCD) {
			setEditOverlayMode(selectionEdit);
		}
	}, [selectionEdit]);

	// get into edit position and size mode:
	const setEditOverlayMode = editMode => {
		wltOCD.panVertical = !editMode;
		wltOCD.panHorizontal = !editMode;
		wltOCD.setControlsEnabled(!editMode);
		//wltOCD.setMouseNavEnabled(!editMode); // mouseScroll disabled

		if (editMode) {
			setEditOverlayId(viewer.editor.verseActive);
		} else {
			setEditOverlayId(null);
		}

		resetOverlays();
	};


	//VERSES STATUSES
	const unsetVerse = (verseId) => {
		let ovr = wltOCD && wltOCD.getOverlayById("ovrly" + verseId);
		if(ovr){
			let ovrHtml = document.getElementById("ovrly" + verseId);
			ovrHtml.innerHTML = '';
			ovrHtml.style.zIndex = "1";
		}
	};
	const setVerseHighlighted = (verseId) => {
		let ovr = wltOCD && wltOCD.getOverlayById("ovrly" + verseId);
		if(ovr){
			let ovrHtml = document.getElementById("ovrly" + verseId);
			ovrHtml.innerHTML = '<div class="versenumber"></div>';
			ovrHtml.style.zIndex = "10";
			let currentVerse = getVerseById(verseId);
			ovrHtml.querySelector(".versenumber").innerHTML = '<span>' + currentVerse.verseNo + '<sub>[' + layersData.idToNumber.get(currentVerse.pageLayer.layerId) + ']</sub></span>';

			wltOCD.getOverlayById("ovrly" + verseId).element.className = osdDrawMode
				? "osd-overlay--highlight h-disabled"
				: "osd-overlay--highlight";
		}
	};
	const setVerseEdited = (verseId) => {
		let ovr = wltOCD && wltOCD.getOverlayById("ovrly" + verseId);
		if(ovr){
			let ovrHtml = document.getElementById("ovrly" + verseId);
			currOverlay = ovrHtml;
			if (!ovrHtml) return;
			overlaysDisableMouseDown();
			ovrHtml.innerHTML =
				'<div class="versenumber"></div><div class="leftbottom"></div><div class="righttop"></div><div class="inner-handler"></div>';
			
			let currentVerse = getVerseById(verseId);
			document.querySelector(".versenumber").innerHTML = '<span>' + currentVerse.verseNo + '<sub>[' + layersData.idToNumber.get(currentVerse.pageLayer.layerId) + ']</sub></span>';
			document.querySelector(".leftbottom").onmousedown = leftCornerStartDrag;
			document.querySelector(".righttop").onmousedown = rightCornerStartDrag;
			document.querySelector(".inner-handler").onmousedown = dragOverlayStart;
			ovrHtml.style.zIndex = "100";
			ovrHtml.className = "osd-overlay--highlight";
		}
	};


	useEffect(() => {
		if (editOverlayId) {
			setVerseEdited(editOverlayId);
		} else {
			overlaysAddOnMouseDown();
		}
		resetOverlays();
	}, [editOverlayId]);

	const overlaysDisableMouseDown = () => {
		viewer.data.verses.forEach(overlay => {
			// turn off possibility to select another overlay
			const overlayToDisable = document.getElementById(`ovrly${overlay.id}`);
			if (overlayToDisable) {
				overlayToDisable.onmousedown = null;
			}
		});
	};
	const overlaysAddOnMouseDown = () => {
		// turn back possibility to select overlays
		viewer.data.verses.forEach(overlay => {
			// turn off possibility to select another overlay
			const overlayToAddEvListener = document.getElementById(`ovrly${overlay.id}`);
			if (overlayToAddEvListener) {
				overlayToAddEvListener.elementMouseTracker.preProcessEventHandler = null;
				overlayToAddEvListener.elementMouseTracker.preProcessEventHandler = eventInfo => overlayPointerDownHandler(eventInfo, overlayToAddEvListener);
				overlayToAddEvListener.className = "osd-overlay";
				if (viewer.editor.verseActive && viewer.editor.verseActive === overlay.id) {
					overlayToAddEvListener.className = "osd-overlay--highlight";
				}
			}
		});
	};

	const setStartTransformOverlayData = () => {
		// before transform set all needed data
		const { width, height } = currOverlay.getBoundingClientRect();
		const { offsetLeft, offsetTop } = currOverlay;
		resizingData.startTransformOverlayBounds = {
			x: offsetLeft,
			y: offsetTop,
			width: width,
			height: height
		};
		currOverlay.style.left = resizingData.startTransformOverlayBounds.x + "px";
		currOverlay.style.top = resizingData.startTransformOverlayBounds.y + "px";
		currOverlay.style.width = width;
		currOverlay.style.height = height;

		let imBounds = wltOCD.viewport.imageToViewportCoordinates(
			new OpenSeadragon.Point(viewer.data.boundingBox.lowerRightX, viewer.data.boundingBox.lowerRightY)
		);
		const ovBounds = wltOCD.getOverlayById(currOverlay.id).getBounds(wltOCD.viewport);

		const viewTopLeft = wltOCD.viewport.viewportToWindowCoordinates(new OpenSeadragon.Point(0, 0)); /// point 0 of viewport
		const viewBottomRight = wltOCD.viewport.viewportToWindowCoordinates(
			new OpenSeadragon.Point(imBounds.x, imBounds.y)
		); /// point max of viewport
		const elementTopLeft = wltOCD.viewport.viewportToWindowCoordinates(
			new OpenSeadragon.Point(ovBounds.x, ovBounds.y)
		); // point 0 of element
		const elementBottomRight = wltOCD.viewport.viewportToWindowCoordinates(
			new OpenSeadragon.Point(ovBounds.x + ovBounds.width, ovBounds.y + ovBounds.height)
		); // point max of element

		resizingData.maxMove = {
			left: elementTopLeft.x - viewTopLeft.x,
			top: elementTopLeft.y - viewTopLeft.y,
			right: viewBottomRight.x - elementBottomRight.x,
			bottom: viewBottomRight.y - elementBottomRight.y
		};
	};

	// dragging overlay in edit mode : always start -> move -> stop
	const dragOverlayStart = e => {
		const ev = e || window.event;
		ev.preventDefault();
		setStartTransformOverlayData();
		resizingData.drag.startX = ev.clientX;
		resizingData.drag.startY = ev.clientY;

		document.onmousemove = dragOverlayMove;
		document.onmouseup = dragOverlayStop;
	};
	const dragOverlayMove = e => {
		// const elem = document.getElementById("ovrly" + viewer.editor.verseActive);
		const ev = e || window.event;
		ev.preventDefault();
		// check that element hasn't exceeded the limits
		let xDif = ev.clientX - resizingData.drag.startX;
		if (xDif < -resizingData.maxMove.left) {
			xDif = -resizingData.maxMove.left;
		}
		if (xDif > resizingData.maxMove.right) {
			xDif = resizingData.maxMove.right;
		}
		let yDif = ev.clientY - resizingData.drag.startY;
		if (yDif < -resizingData.maxMove.top) {
			yDif = -resizingData.maxMove.top;
		}
		if (yDif > resizingData.maxMove.bottom) {
			yDif = resizingData.maxMove.bottom;
		}
		currOverlay.style.left = resizingData.startTransformOverlayBounds.x + xDif + "px";
		currOverlay.style.top = resizingData.startTransformOverlayBounds.y + yDif + "px";
	};
	const dragOverlayStop = ev => {
		let xDif = ev.clientX - resizingData.drag.startX;
		if (xDif < -resizingData.maxMove.left) {
			xDif = -resizingData.maxMove.left;
		}
		if (xDif > resizingData.maxMove.right) {
			xDif = resizingData.maxMove.right;
		}
		let yDif = ev.clientY - resizingData.drag.startY;
		if (yDif < -resizingData.maxMove.top) {
			yDif = -resizingData.maxMove.top;
		}
		if (yDif > resizingData.maxMove.bottom) {
			yDif = resizingData.maxMove.bottom;
		}
		currOverlay.style.left = resizingData.startTransformOverlayBounds.x + xDif + "px";
		currOverlay.style.top = resizingData.startTransformOverlayBounds.y + yDif + "px";
		recalculateEditedOverlayToOSD(xDif, yDif);
		putCurrentOverlayIntoApi("ovrly" + viewer.editor.verseActive);
		document.onmouseup = null;
		document.onmousemove = null;
	};

	const recalculateEditedOverlayToOSD = (xDif, yDif, wDif = 0, hDif = 0) => {
		const rect = wltOCD.viewport.viewportToViewerElementRectangle(
			wltOCD.getOverlayById("ovrly" + viewer.editor.verseActive).getBounds(wltOCD.viewport)
		);
		const newRect = new OpenSeadragon.Rect(rect.x + xDif, rect.y + yDif, rect.width + wDif, rect.height + hDif);
		const convertedRect = wltOCD.viewport.viewerElementToViewportRectangle(newRect);
		wltOCD.updateOverlay(currOverlay, convertedRect);
	};

	// resizing by dragging corners
	const leftCornerStartDrag = ev => {
		// ev.stopPropagation();
		setStartTransformOverlayData();
		resizingData.drag.startX = ev.clientX;
		resizingData.drag.startY = ev.clientY;
		document.onmousemove = leftCornerDragging;
		document.onmouseup = cornerDraggingStop;
	};
	const leftCornerDragging = ev => {
		const difX =
			ev.clientX - resizingData.drag.startX < -resizingData.maxMove.left
				? -resizingData.maxMove.left
				: ev.clientX - resizingData.drag.startX;
		const difY =
			ev.clientY - resizingData.drag.startY > resizingData.maxMove.bottom
				? resizingData.maxMove.bottom
				: ev.clientY - resizingData.drag.startY;
		

		// don't let the currentOverlay to be smaller than 10x10px
		if (resizingData.startTransformOverlayBounds.width - difX > 10) {
			currOverlay.style.left = resizingData.startTransformOverlayBounds.x + difX + "px";
			currOverlay.style.width = resizingData.startTransformOverlayBounds.width - difX + "px";
		}
		if (resizingData.startTransformOverlayBounds.height + difY > 10) {
			currOverlay.style.height = resizingData.startTransformOverlayBounds.height + difY + "px";
		}
	};
	const rightCornerStartDrag = ev => {
		// ev.stopPropagation();
		setStartTransformOverlayData();
		resizingData.drag.startX = ev.clientX;
		resizingData.drag.startY = ev.clientY;
		document.onmousemove = rightCornerDragging;
		document.onmouseup = cornerDraggingStop;
	};
	const rightCornerDragging = ev => {
		const difX =
			ev.clientX - resizingData.drag.startX > resizingData.maxMove.right
				? resizingData.maxMove.right
				: ev.clientX - resizingData.drag.startX;
		const difY =
			ev.clientY - resizingData.drag.startY < -resizingData.maxMove.top
				? -resizingData.maxMove.top
				: ev.clientY - resizingData.drag.startY;
		

		// don't let the currentOverlay to be smaller than 10x10px
		if (resizingData.startTransformOverlayBounds.width + difX > 10) {
			currOverlay.style.width = resizingData.startTransformOverlayBounds.width + difX + "px";
		}
		if (resizingData.startTransformOverlayBounds.height - difY > 10) {
			currOverlay.style.top = resizingData.startTransformOverlayBounds.y + difY + "px";
			currOverlay.style.height = resizingData.startTransformOverlayBounds.height - difY + "px";
		}
	};
	const cornerDraggingStop = () => {
		const { width, height } = currOverlay.getBoundingClientRect();
		const { offsetLeft, offsetTop } = currOverlay;
		recalculateEditedOverlayToOSD(
			offsetLeft - resizingData.startTransformOverlayBounds.x,
			offsetTop - resizingData.startTransformOverlayBounds.y,
			width - resizingData.startTransformOverlayBounds.width,
			height - resizingData.startTransformOverlayBounds.height
		);
		putCurrentOverlayIntoApi("ovrly" + viewer.editor.verseActive);
		document.onmousemove = null;
		document.onmouseup = null;
	};
	// finally, wwhen move and resize is done send data to api:
	const putCurrentOverlayIntoApi = overlayId => {
		if (!wltOCD.getOverlayById(overlayId)) {
			// console.log('There is no overlay to put');
			return;
		}
		const ovBounds = wltOCD.getOverlayById(overlayId).getBounds(wltOCD.viewport);

		const upLeft = wltOCD.viewport.viewportToImageCoordinates(ovBounds.x, ovBounds.y);
		const botRight = wltOCD.viewport.viewportToImageCoordinates(
			ovBounds.x + ovBounds.width,
			ovBounds.y + ovBounds.height
		);
		const newBounds = {
			upperLeftX: Math.round(upLeft.x),
			upperLeftY: Math.round(upLeft.y),
			lowerRightX: Math.round(botRight.x),
			lowerRightY: Math.round(botRight.y)
		};
		const activeVerseId = viewer.editor.verseActive;
		dispatch(appActions.setLoading(true));
		api.put("/verses/" + activeVerseId, {
			...newBounds
		})
			.then(() => {
				setDrawing(false);
				
				const newVerses = viewer.data.verses.map(item => {
					if (item.id !== viewer.editor.verseActive) return item;
					return { ...item, boundingBox: newBounds };
				});
				dispatch(viewerActions.reloadVerses(newVerses, transcriptionId));
				dispatch(appActions.setLoading(false));
				setEditOverlayMode(true);
			})
			.catch(response => {
				dispatch(appActions.setLoading(false));
			});
	};

	//////////////////////////
	// Draw new overlay
	////////////////////////////
	const setDrawOverlayMode = drawMode => {
		// get into drawing new overlayMode mode
		wltOCD.panVertical = !drawMode;
		wltOCD.panHorizontal = !drawMode;
		if (drawMode) {
			setOverlaysListToGetNewAddedToApi(viewer.data.verses.map(({ id }) => parseInt(id)));
			dispatch(viewerActions.setVerseActive());
			wltOCD.removeAllHandlers("canvas-press");
			wltOCD.addHandler("canvas-press", onCanvasPress);
			wltOCD.addHandler("canvas-drag", onCanvasDrag);
			wltOCD.addHandler("canvas-drag-end", onCanvasDragEnd);
			wltOCD.addHandler("canvas-release", onCanvasRelease);
		} else {
			setOverlaysListToGetNewAddedToApi(null);
			wltOCD.removeAllHandlers("canvas-press");
			wltOCD.addHandler("canvas-press", canvasPressHandler);
			wltOCD.removeAllHandlers("canvas-drag");
			wltOCD.removeAllHandlers("canvas-drag-end");
			wltOCD.removeAllHandlers("canvas-release");

			if (setLastDrawnOverlayId) {
				dispatch(viewerActions.setVerseActive(lastDrawnOverlayId));
				setLastDrawnOverlayId(null);
			} else {
				dispatch(viewerActions.setVerseActive(viewer.data.verses[0].id));
			}
			overlaysAddOnMouseDown();
		}
	};
	// as alway press-> drag -> release
	const onCanvasPress = ev => {
		let pt = wltOCD.viewport.pointFromPixel(ev.position);
		pt.x = Math.max(pt.x, 0);
		pt.y = Math.max(pt.y, 0);
		pt.x = Math.min(pt.x, imageBounds.x);
		pt.y = Math.min(pt.y, imageBounds.y);
		setDrawing(true);
		createNewOverlay(pt);
	};
	const onCanvasDrag = ev => {
		//dispatch(viewerActions.setVerseActive(null));
		dragged = true;
		let point = wltOCD.viewport.pointFromPixel(ev.position);
		let tX = Math.max(Math.min(point.x, newOverlayStartPoint.x), 0);
		let tY = Math.max(Math.min(point.y, newOverlayStartPoint.y), 0);

		let bX =
			point.x < newOverlayStartPoint.x
				? newOverlayStartPoint.x - Math.max(point.x, 0)
				: Math.min(point.x, imageBounds.x) - newOverlayStartPoint.x;

		let bY =
			point.y < newOverlayStartPoint.y
				? newOverlayStartPoint.y - Math.max(point.y, 0)
				: Math.min(point.y, imageBounds.y) - newOverlayStartPoint.y;
		let rect = new OpenSeadragon.Rect(tX, tY, bX, bY);
		wltOCD.updateOverlay(newOverlay, rect);
	};
	const onCanvasRelease = () => {
		if (dragged) {
			dragged = false;
		} else {
			wltOCD.removeOverlay(newOverlay);
			newOverlay = null;
		}
		setDrawing(false);
	};

	const onCanvasDragEnd = () => {
		if (newOverlay) {
			const ovBounds = wltOCD.getOverlayById(newOverlay.id).getBounds(wltOCD.viewport);

			const upLeft = wltOCD.viewport.viewportToImageCoordinates(ovBounds.x, ovBounds.y);
			const botRight = wltOCD.viewport.viewportToImageCoordinates(
				ovBounds.x + ovBounds.width,
				ovBounds.y + ovBounds.height
			);
			const newBounds = {
				upperLeftX: Math.round(upLeft.x),
				upperLeftY: Math.round(upLeft.y),
				lowerRightX: Math.round(botRight.x),
				lowerRightY: Math.round(botRight.y)
			};
			dispatch(appActions.setLoading(true));
			//const query = new URLSearchParams(location.search);
			const pageLayer = selectedLayer;//query.get("pageLayer");
			const url = `/verses/${viewer.data.id}?layerId=${pageLayer}`
			// api.post(`/verses/${viewer.data.id}?layerId=${workingPageLayer}`, {
			api.post(url, {
				...newBounds
			}).then(res => {
				wltOCD.removeOverlay(newOverlay);
				setDrawing(false);

				dispatch(viewerActions.reloadVerses(res.data, transcriptionId));
				dispatch(appActions.setLoading(false));
				newOverlay = null;
				onToggleDrawMode();
			});
		}
	};
	const createNewOverlay = point => {
		if (!newOverlay) {
			newOverlayStartPoint = { x: point.x, y: point.y };
			let tNewOverlay = document.createElement("div");
			tNewOverlay.id = "ovrly" + (Math.round(Math.random() * 1000) + 1000);
			tNewOverlay.className = "osd-overlay--highlight";

			osdAddOverlay(tNewOverlay, new OpenSeadragon.Rect(point.x, point.y, 0.001, 0.001), wltOCD);
			newOverlay = tNewOverlay;
		}
	};
	const resetOverlays = () => {
		if (wltOCD && viewer.data.verses.length) {
			const classString = osdDrawMode ? "osd-overlay osd-overlay--disabled" : "osd-overlay";
			for (let i = 0; i < viewer.data.verses.length; i++) {
				const elem = wltOCD.getOverlayById("ovrly" + viewer.data.verses[i].id);
				if (elem) {
					elem.element.className = classString;
					if(editOverlayId === viewer.data.verses[i].id){
						setVerseEdited(editOverlayId);
					}else if(viewer.editor.verseActive === viewer.data.verses[i].id){
						setVerseHighlighted(viewer.editor.verseActive);
					}else{
						unsetVerse(viewer.data.verses[i].id);
					}
					//elem.element.innerHTML = '';
					// na razie zostawiam - w razie złego wyświetlania się przywróci...
					// elem.element.className = ((viewer.data.viewMode !== 'read-only') ? classString : "");
				}
			}
		}
	};

	useEffect(() => {
		if (wltOCD) {
			setOsdMethods({
				zoomInFunction: () => wltOCD.viewport.zoomBy(1.1),
				zoomOutFunction: () => wltOCD.viewport.zoomBy(0.9),
				fullScreen: () => wltOCD.setFullScreen(!wltOCD.isFullPage()),
				fitToPage: () => wltOCD.viewport.goHome()
			});
			resetOverlays();
		}
	}, [wltOCD]);

	useEffect(() => {
		if (viewerInitialised) {
			resetOverlays();
		}
	}, [viewerInitialised]);

	return <div className={`ocd-div viewer__ocd${drawing ? " osd-drawing" : ""}`} id={`ocd${viewer.data.id}`} />;
};

ViewerOSD.propTypes = {
	osdDrawMode: PropTypes.bool,
	setOsdMethods: PropTypes.func,
	setZoom: PropTypes.func,
	setHomeZoom: PropTypes.func
	// onOverlayAdded: PropTypes.func,
};

export default memo(ViewerOSD);
