import React from "react";

import styles from "@Pages/Transcription/TranscriptionIndexes/TranscriptionIndexes.module.sass";

function TranscriptionIndexesTag({tagText, tag, transcriptionId, onClick, chosenTag, count}) {
    return (
        <>
            {tag === chosenTag ?
                <div className={`${styles["tag-type"]} ${styles["tag-type-active"]}`} onClick={onClick}> {tagText}  <span className={`${styles["tag-type--count"]}`}>({count})</span></div>
            : 
                <div className={styles["tag-type"]} onClick={onClick}> {tagText} <span className={`${styles["tag-type--count"]}`}>({count})</span></div>
            }
        </>
    )
}

export default TranscriptionIndexesTag
