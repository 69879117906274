import React, { useContext, useLayoutEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Row, useAccordionToggle } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

import { authActions } from "@/store/actions/auth.actions";
import { appActions } from "@/store/actions/app.actions";
import { CheckSecurityGroupPermission } from "@/permissions/SecurityGroups";

import { useShowModal } from '@Elements/Modal/ModalHooks';

import store from '@/store/store';
import url from "@/router/urls";

import api from "@/services/api";

import styles from "@Templates/header/AppHeader/HeaderTopBar.module.sass";
import styles2 from '@Templates/header/AppHeader/HeaderMyAccount.module.sass';

const CustomToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
        <Button className={styles["card-header-toggle"]} variant="link" onClick={decoratedOnClick}>
            { children }
            { isCurrentEventKey ? <i className='icon-arrow-up-full' /> : <i className='icon-arrow-down-full' /> }
        </Button>
    );
  }


const TopBarMenuRowMobile = () => {
    const { t } = useTranslation();
    const isLoggedIn = useSelector((state) => state.auth.credentials.isLoggedIn);
	const isProtected = useSelector((state) => state.auth.path.isProtected);
	const formsAutorization = useSelector((state) => state.app.config.authorization == "FORMS");
	const authorizationLoginUrl = useSelector((state) => state.app.config.reactAppApi + state.app.config.authorizationLoginUrl);
	const reactAppApi = useSelector((state) => state.app.config.reactAppApi);
	const authorizationRegisterUrl = useSelector((state) => state.app.config.reactAppApi + state.app.config.authorizationRegisterUrl);
	const [ UserType, setUserType ] = useState("USER");
    const dispatch = useDispatch();

    const ariadnaUserManagement = useSelector(state => state.app.config.ariadnaUserManagement);
	const keycloak = new URL(ariadnaUserManagement).origin;
	const userProfile = keycloak + "/auth/realms/BSA/account";


    const dictionaryUrl = useSelector(state => state.app.config.dictionary);
	const contactUrl = useSelector(state => state.app.config.contact);
	const privacyPolicyUrl = useSelector(state => state.app.config.privacyPolicy);
	const accessibilityStatementUrl = useSelector(state => state.app.config.accessibilityStatement);
	const regulationsUrl = useSelector(state => state.app.config.regulations);

	const ariadnaWwwParticipantsUrl = useSelector(state => state.app.config.ariadnaWwwParticipants);
    const ariadnaWwwProjectInfoUrl = useSelector(state => state.app.config.ariadnaWwwProjectInfo);
    const ariadnaWwwFinancialInfoUrl = useSelector(state => state.app.config.ariadnaWwwFinancialInfo);

    const signOut = () => {
		let authorizationSignOutUrl = '/auth/sign-out?post_logout_redirect_uri=';
		
		authorizationSignOutUrl = reactAppApi + authorizationSignOutUrl;

		const url = formsAutorization ? '/auth/sign-out' : authorizationSignOutUrl + window.location.origin;

		if(formsAutorization)
			dispatch(authActions.signOut(isProtected, url));
		else	
			window.location = url;
	};

	useLayoutEffect(() => {
		if (isLoggedIn === true) {
			api
				.get("/users/my-profile")
				.then(response => {
					const { user } = response.data;
					setUserType(user.securityGroup);
				});
		}
	}, [ isLoggedIn ]);

    const showPublicAccountInfo = useShowModal({
		title:t('modals.publicAccountInfo.title'),
		name: 'publicAccountInfo',
		componentPath: './parts/ModalPublicAccountInfo',
        componentProps: {securityGroup: UserType},
        withClose: true,
	});

    const resetErrorFlags = () => {
		store.dispatch(appActions.setNotFound(false));
		store.dispatch(appActions.setError(false));
	};

    return (<>
        <Row className={styles["top-bar__row--menu"] + " " + styles["mobile-top-bar"]}>
            <Accordion defaultActiveKey={null}>
                <Card className={styles["custom-card"]}>
                    <Card.Header className={styles["custom-card-header"]}>
                        <CustomToggle eventKey="0">{t('headers.menu.projects')}</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Link
                                className='dropdown-item'
                                to={{pathname: url.transcriptionsUser, state: { isProtected: true } }}
                                onClick={resetErrorFlags}
                            >
                                {t('headers.menu.myProjects') }
                            </Link>
                            <Link
                                className='dropdown-item'
                                to={{pathname: url.transcriptions, state: { isProtected: false } }}
                                onClick={resetErrorFlags}
                            >
                                {t('headers.menu.allProjects') }
                            </Link>
                            <hr className={styles["mobile-top-bar--divider"]}/>
                            { CheckSecurityGroupPermission("canCreateTranscription", UserType) ? <>
                                <Link
                                    className='dropdown-item'
                                    to={{pathname: url.transcription.new, state: { isProtected: true } }}
                                    onClick={resetErrorFlags}
                                >
                                    {t('headers.menu.newProject') }
                                </Link>
                            </> : <>
                                <Dropdown.Item 
                                    className='dropdown-item'
                                    onClick={ () => showPublicAccountInfo() }
                                >{t('headers.menu.newProject') }
                                </Dropdown.Item> 
                            </>}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className={styles["custom-card"]}>
                    <Card.Header className={styles["custom-card-header"]}>
                        <CustomToggle eventKey="1">{t('headers.informations')}</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <div className={styles["dropdown__wrapper"]}>
                                <p className={'dropdown-item ' + styles["mobile-top-bar--label"]}>
                                    {t('headers.informationsMenu.contactInfo')}
                                </p>
                                <a
                                    className='dropdown-item'
                                    href={contactUrl}
                                    target="_blank"
                                >
                                    {t('headers.informationsMenu.contact')}
                                </a>
                                <p className={'dropdown-item ' + styles["mobile-top-bar--label"]}>
                                    {t('headers.informationsMenu.helpersInfo')}
                                </p>
                                <Link
                                    className='dropdown-item'
                                    to={{pathname: url.howToTranscribe, state: { isProtected: false } }}
                                    onClick={resetErrorFlags}
                                >
                                    {t('headers.informationsMenu.howTo')}
                                </Link>
                                <a
                                    className='dropdown-item'
                                    href={dictionaryUrl}
                                    target="_blank"
                                >
                                    {t('headers.informationsMenu.dictionary')}
                                </a>
                                <Link
                                    className='dropdown-item'
                                    to={{pathname: url.map, state: { isProtected: false } }}
                                    onClick={resetErrorFlags}
                                >
                                    {t('headers.informationsMenu.pageMap')}
                                </Link>
                                <p className={'dropdown-item ' + styles["mobile-top-bar--label"]}>
                                    {t('headers.informationsMenu.privacyInfo')}
                                </p>
                                <a
                                    className='dropdown-item'
                                    href={privacyPolicyUrl}
                                    target="_blank"
                                >
                                    {t('headers.informationsMenu.privacy')}
                                </a>
                                <a
                                    className='dropdown-item'
                                    href={accessibilityStatementUrl}
                                    target="_blank"
                                >
                                    {t('headers.informationsMenu.availability')}
                                </a>
                                <a
                                    className='dropdown-item'
                                    href={regulationsUrl}
                                    target="_blank"
                                >
                                    {t('headers.informationsMenu.terms')}
                                </a>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className={styles["custom-card"]}>
                    <Card.Header className={styles["custom-card-header"]}>
                        <CustomToggle eventKey="2">{t('headers.about')}</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <a
                                className='dropdown-item'
                                href={ariadnaWwwFinancialInfoUrl}
                            >
                                {t('headers.aboutMenu.financed')}
                            </a>
                            <a
                                className='dropdown-item'
                                href={ariadnaWwwProjectInfoUrl}
                            >
                                {t('headers.aboutMenu.project')}
                            </a>
                            <a
                                className='dropdown-item'
                                href={ariadnaWwwParticipantsUrl}
                            >
                                {t('headers.aboutMenu.contributors')}
                            </a>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className={styles["custom-card"]}>
                    <Card.Header className={styles["custom-card-header"]}>
                        <CustomToggle eventKey="3">{t('headers.modules')}</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <div className={styles["dropdown__modules"]}>
                                <div>
                                    <p className={styles["dropdown__modules--head"]}>{t('headers.modulesMenu.ariadna')}</p>
                                    <p className={styles["dropdown__modules--desc"]}>{t('headers.modulesMenu.ariadnaText')}</p>
                                    <a target='_blank' href={store.getState().app.config.ariadnaWwwUrl}>{t('headers.modulesMenu.goTo')}</a>
                                </div>
                                <div>
                                    <p className={styles["dropdown__modules--head"]}>{t('headers.modulesMenu.edu')}</p>
                                    <p className={styles["dropdown__modules--desc"]}>{t('headers.modulesMenu.eduText')}</p>
                                    <a target='_blank' href={store.getState().app.config.ariadnaEduUrl}>{t('headers.modulesMenu.goTo')}</a>
                                </div>
                                <div>
                                    <p className={styles["dropdown__modules--head"]}>{t('headers.modulesMenu.history')}</p>
                                    <p className={styles["dropdown__modules--desc"]}>{t('headers.modulesMenu.historyText')}</p>
                                    <a target='_blank' href={store.getState().app.config.ariadnaWolUrl}>{t('headers.modulesMenu.goTo')}</a>
                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className={styles["custom-card"]}>
                    <Card.Header className={styles["custom-card-header"]}>
                        <CustomToggle eventKey="4">{t('headers.menu.myAccount')}</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                        <Card.Body>
                        {isLoggedIn ? (
                            <>
                                <div className={styles["dropdown__wrapper"]}>
                                    <p className={'dropdown-item ' + styles["mobile-top-bar--label"]}>
                                        {t('headers.menu.myAccountMenu.general')}
                                    </p>
                                    <Link
                                        className={`dropdown-item`}
                                        to={{pathname: url.auth.account, state: { isProtected: true } }}
                                        onClick={resetErrorFlags}
                                    >
                                        { t('headers.menu.myAccountMenu.myAccount') }
                                    </Link>
                                    <a
                                        className={`dropdown-item`}
                                        href={userProfile}
                                        target='_blank'
                                    >
                                        { t('headers.menu.myAccountMenu.myProfile') }
                                    </a>
                                </div>
                                <div className={styles2["dropdown__wrapper"]}>
                                    <p className={'dropdown-item ' + styles["mobile-top-bar--label"]}>
                                        {t('headers.menu.myAccountMenu.ariadna')}
                                    </p>
                                    <a
                                        className={`dropdown-item`}
                                        href={store.getState().app.config.ariadnaWwwMyCollectionsUrl}
                                        target='_blank'
                                    >
                                        {t('headers.menu.myAccountMenu.myCollections')}
                                    </a>
                                    <a
                                        className={`dropdown-item`}
                                        href={store.getState().app.config.ariadnaWwwMySubjectsUrl}
                                        target='_blank'
                                    >
                                        {t('headers.menu.myAccountMenu.myTopics')}
                                    </a>
                                    {CheckSecurityGroupPermission("canAccessAriadnaServiceManagement", UserType) && <a
                                        className={`dropdown-item`}
                                        href={store.getState().app.config.ariadnaServiceManagement}
                                        target='_blank'
                                    >
                                        {t('headers.menu.myAccountMenu.ariadnaServiceManagement')}
                                    </a>}
                                    {CheckSecurityGroupPermission("canAccessAriadnaUserManagement", UserType) && <a
                                        className={`dropdown-item`}
                                        href={store.getState().app.config.ariadnaUserManagement}
                                        target='_blank'
                                    >
                                        {t('headers.menu.myAccountMenu.ariadnaUserManagement')}
                                    </a>}
                                </div>

                                <div className={styles["dropdown__wrapper"]}>
                                    <p className={'dropdown-item ' + styles["mobile-top-bar--label"]}>
                                        {t('headers.menu.myAccountMenu.historyTexts')}
                                    </p>
                                    <Link
                                        className={`dropdown-item`}
                                        to={{pathname: url.transcriptionsUser}}
                                        onClick={resetErrorFlags}
                                    >
                                        {t('headers.menu.myAccountMenu.historyTextsMyProjects')}
                                    </Link>
                                    {CheckSecurityGroupPermission("canAccessProjectsDashboard", UserType) &&
                                    <Link
                                        className={`dropdown-item`}
                                        to={{pathname: url.auth.admin.projects}}
                                        onClick={resetErrorFlags}
                                    >
                                        {t('headers.menu.myAccountMenu.serviceManagement')}
                                    </Link>}
                                    {CheckSecurityGroupPermission("canAccessUsersDashboard", UserType) &&
                                    <Link
                                        className={`dropdown-item`}
                                        to={{pathname: url.auth.admin.users}}
                                        onClick={resetErrorFlags}
                                    >
                                        {t('headers.menu.myAccountMenu.usersManagement')}
                                    </Link>}
                                    {CheckSecurityGroupPermission("canManageTranscriptionExportToDLibra", UserType) &&
                                    <Link
                                        className={`dropdown-item`}
                                        to={{pathname: url.dLibraExport}}
                                        onClick={resetErrorFlags}
                                    >
                                        {t('headers.menu.myAccountMenu.projectsForDLibra')}
                                    </Link>}
                                </div>

                                <div className={styles["dropdown__wrapper"]}>
                                    <p className={'dropdown-item ' + styles["mobile-top-bar--label"]}>
                                        {t('headers.menu.myAccountMenu.edu')}
                                    </p>
                                    <a
                                        className={`dropdown-item`}
                                        href={store.getState().app.config.ariadnaEduMyProjectsUrl}
                                        target='_blank'
                                    >
                                        {t('headers.menu.myAccountMenu.eduMyProjects')}
                                    </a>
                                </div>

                                <div className={styles["dropdown__wrapper"]}>
                                    <p className={'dropdown-item ' + styles["mobile-top-bar--label"]}>
                                        {t('headers.menu.myAccountMenu.historyPlaces')}
                                    </p>
                                    <a
                                        className={`dropdown-item`}
                                        href={store.getState().app.config.ariadnaWolMyProjectsUrl}
                                        target='_blank'
                                    >
                                        {t('headers.menu.myAccountMenu.historyPlacesMyProjects')}
                                    </a>
                                </div>
                                <div className={`${styles2["myacount-dropdown__item--logout"]}`}>
                                    <Button variant="secondary" onClick={() => signOut()}>
                                        {t('headers.menu.logOut')}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                <a
                                    className={`dropdown-item`}
                                    href={formsAutorization ? url.auth.login : authorizationLoginUrl + "?returnUrl=" + encodeURIComponent(window.location)}
                                >
                                    {t('headers.menu.logIn')}
                                </a>
                                <a
                                    className={`dropdown-item`}
                                    href={formsAutorization ? url.auth.register : authorizationRegisterUrl + "?returnUrl=" + encodeURIComponent(window.location)}
                                >
                                    {t('headers.menu.createAccount')}
                                </a>
                            </>
                        )}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Row>
    </>);
}

export default TopBarMenuRowMobile;