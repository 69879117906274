const authInitialState = {
	credentials: {
		isLoggedIn: false,
		isLoggingEnd: false,
		securityGroup: "USER",
		token: null,
		csrf: null,
		email: '',
	},

	login: {
		isLoading: false,
		returnUrl: null,
	},

	path: {
		isProtected: false,
	},
};

export default authInitialState;
