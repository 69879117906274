import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router";
import { Dropdown } from 'react-bootstrap';
import PropTypes from "prop-types";

import { useShowModal } from '@Elements/Modal/ModalHooks';
import { viewerActions } from '@/store/actions/viewer.actions';

const FormattingDropdownButton = ({ icon, iconImg, disabled, func, label, indexType }) => {
	const dispatch = useDispatch();
	const verseActive = useSelector((state) => state.viewer.editor.verseActive);
	const { t } = useTranslation();

	const { transcriptionId, pageId } = useParams();
	const dictToUse = indexType === 'person' ?t('modals.setAsPerson',{ returnObjects: true }) : (indexType === 'place' ?t('modals.setAsPlace',{ returnObjects: true }) :t('modals.setAsTerm',{ returnObjects: true }));

	const modalDeleteVerse = useShowModal({
		title:t('modals.deleteVerse.title'),
		type: 'viewer',
		name: 'deleteVerse',
		componentPath: './parts/ModalDeleteVerse',
		componentProps: { transcriptionId: parseInt(transcriptionId), pageId: parseInt(pageId) },
	});

	const modalDeleteVerses = useShowModal({
		title:t('modals.deleteVerses.title'),
		type: 'viewer',
		name: 'deleteVerses',
		componentPath: './parts/ModalDeleteVerses',
		componentProps: { transcriptionId: parseInt(transcriptionId), pageId: parseInt(pageId) },
	});

	const modalSetVerseAsIndex = useShowModal({
		title: dictToUse.titleAdd,
		type: "viewer modal-index",
		name: "PersonVerse",
		componentPath: "./parts/ModalSetVerseAsIndex",
		componentProps: { transcriptionId: parseInt(transcriptionId), verseId: parseInt(verseActive), addBox: true, indexType },
	});

	return (
		<Dropdown.Item
			onClick={ () => {
				switch (func) {
					case 'verse-delete':
						modalDeleteVerse();
						break;
					case 'verses-delete':
						modalDeleteVerses();
						break;
					case 'verse-set-as-index':
						modalSetVerseAsIndex();
						break;
					default:
						dispatch(viewerActions.submitVerseFormatting(verseActive, func, parseInt(transcriptionId)));
				}
			} }
			disabled={ disabled }
		>
		{icon && <>
			<span className='d-none'>{icon}</span>
			<span><i className={ `icon-${icon}` } /></span> {label}
		</>}
		{iconImg && <>
			<span><img src={iconImg} /></span> {label}
		</>}
		</Dropdown.Item>
	);
};

FormattingDropdownButton.propTypes = {
	label: PropTypes.string,
	func: PropTypes.string,
	icon: PropTypes.string,
	indexType: PropTypes.string,
};

export default memo(FormattingDropdownButton);