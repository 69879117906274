import Cookies from 'universal-cookie';
const cookies = new Cookies();

/**
 * Creates cookie with authorization token
 * @param {string} token
 * @return {void}
 */
const setToken = token => {
	return cookies.set('token', token, { path: '/', expires: new Date(Date.now() + (7 * 24 * 60 * 60 * 1000)) });
};

/**
 * Returns cookie with authorization token
 * @return {string}
 */
const getToken = () => {
	return cookies.get('token');
};

/**
 * Deletes cookie with authorization token
 * @return {string}
 */
const removeToken = () => {	
	cookies.set('token', '', { path: '/', expires: new Date(1990,1,1) });
	cookies.remove('token');
};

export const cookieService = {
	setToken,
	getToken,
	removeToken,
};
