import api from "services/api";

const receivePrivacyPolicyPage = (value) => ({
	type: "RECEIVE_PRIVACY_POLICY_PAGE",
	value,
});

const getPrivacyPolicyPage = () => {
	return dispatch => {
		api.get('/static-files/privacy-policy')
			.then(response => {
				dispatch(receivePrivacyPolicyPage(response.data));
			});
	};
};

const receiveContactPage = (value) => ({
	type: "RECEIVE_CONTACT_PAGE",
	value,
});

const getContactPage = () => {
	return dispatch => {
		api.get('static-files/contact')
			.then(response => {
				dispatch(receiveContactPage(response.data));
			});
	};
};

export const staticPageActions = {
	getPrivacyPolicyPage,
	getContactPage,
};