import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { reverse } from "named-urls";
import { Container } from "react-bootstrap";
import { useForm } from "react-hook-form";

import api from "@/services/api";
import url from "@/router/urls";

import { alertActions } from "@/store/actions/alert.actions";

import Breadcrumbs from "@Elements/Breadcrumbs/Breadcrumbs";
import Button from "@Elements/Button/Button";
import SearchTranscriptions from "@Elements/Search/SearchTranscriptions";
import SearchTypeEnum from "@/utils/SearchTypeEnum";

import { CheckSecurityGroupPermission } from "@/permissions/SecurityGroups";

import styles from "@Pages/Auth/Account/Account.module.sass";

import iconMenu1 from "@Assets/images/account/AccountMenu-1.svg";
import iconMenu2 from "@Assets/images/account/AccountMenu-2.svg";
import iconMenu3 from "@Assets/images/account/AccountMenu-3.svg";
import iconMenu4 from "@Assets/images/account/AccountMenu-4.svg";
import iconMenu5 from "@Assets/images/account/AccountMenu-5.svg";
import iconMenu6 from "@Assets/images/account/AccountMenu-6.svg";

const MyGroupItem = (item, index, dict) => {
	const { t } = useTranslation();
	return (
		<div
			key={item.name + index}
			className={styles["account__adminlist--item"]}
		>
			<div className={styles["account__adminlist--item-lp"]}>{index + 1}.</div>
			<div className={styles["account__adminlist--item-name"]}>{item.name}</div>
			<div className={styles["account__adminlist--item-button"]}>
				<Button
					variant='link'
					type='button'
					className={styles["account__button"]}
					href={reverse(url.auth.group.edit, { id: item.id })}
				>
					{t('auth.account.groupEdit')}
				</Button>
			</div>
		</div>
	);
};
const OtherGroupItem = (item, index, dict) => {
	const { t } = useTranslation();
	return (
		<li
			key={item.name + index}
			className={styles["account__list--item"]}
		>
			<div className={styles["account__list--item-lp"]}>{index + 1}.</div>
			<div className={styles["account__list--item-name"]}>{item.name}</div>
			<div className='text--gray'>
				({t('auth.account.groupCreator')}: {item.creator.email})
			</div>
		</li>
	);
};

/** 
 * Links to various pages, internal and external related to user account
 * 
 * @component
 */
const Account = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);

	const ariadnaWwwUrl= useSelector(state => state.app.config.ariadnaWwwUrl);
	const ariadnaWwwMyCollectionsUrl = ariadnaWwwUrl + '/myCollections';
	const ariadnaWwwMySubjectsUrl = ariadnaWwwUrl + '/myTopics';

	const ariadnaServiceManagement = useSelector(state => state.app.config.ariadnaServiceManagement);
	const ariadnaUserManagement = useSelector(state => state.app.config.ariadnaUserManagement);
	const keycloak = new URL(ariadnaUserManagement).origin;
	const userProfile = keycloak + "/auth/realms/BSA/account";

	const ariadnaEduUrl	= useSelector(state => state.app.config.ariadnaEduUrl);
	const ariadnaEduMyProjectsUrl = ariadnaEduUrl+"/sciezki";

	const ariadnaWolUrl = useSelector(state => state.app.config.ariadnaWolUrl);
	const ariadnaWolMyProjectsUrl = ariadnaWolUrl+"/my-projects";

	const [ UserEmail, setUserEmail ] = useState("");
	const [ UserType, setUserType ] = useState("USER");
	const [ UserMyGroups, setUserMyGroups ] = useState([]);
	const [ UserHasPassword, setUserHasPassword ] = useState(false);
	const [ UserOtherGroups, setUserOtherGroups ] = useState([]);
	const [ UserQuota, setUserQuota ] = useState(0);
	const [ UserUsedSpaceQuota, setUserUsedSpaceQuota ] = useState(0);
	const [ UserPasswordForm, setUserPasswordForm ] = useState(false);
	const [ UserPasswordFormSubmit, setUserPasswordFormSubmit ] = useState(false);
	const [ searchString, setSearchString ] = useState("");
	const [ useMetadata, setUseMetadata ] = useState(SearchTypeEnum.ALL.toString());

	const defaultValues = {
		currentPassword: "",
		newPassword: "",
		repeatedNewPassword: "",
	};

	const { register, handleSubmit, formState, errors, setError, getValues } = useForm({
		defaultValues: defaultValues,
		mode: "onChange",
	});

	const onSubmit = data => {
		setUserPasswordFormSubmit(true);
		api
			.put("/users/my-password", {
				...data,
			})
			.then(() => {
				setUserPasswordFormSubmit(false);
				setUserPasswordForm(false);

				dispatch(
					alertActions.setAlert({
						type: "success",
						icon: "icon-accept",
						text: "auth.account.passwordChangeAlert",
						close: true,
					}),
				);
			})
			.catch(error => {
				setUserPasswordFormSubmit(false);
				for (const errorField in error.data) {
					const errorCode = error.data[errorField].split(".")[2];
					setError(errorField, errorCode, t('form.messages.'+errorCode));
				}
			});
	};

	useEffect(() => {
		if (isLoggedIn === true) {
			api
				.get("/users/my-profile")
				.then(response => {
					const { user, myGroups, otherGroups } = response.data;

					setUserEmail(user.email);
					setUserType(user.securityGroup);
					setUserHasPassword(user.hasPassword);
					setUserMyGroups(myGroups);
					setUserOtherGroups(otherGroups);
					setUserQuota(user.quota);
					setUserUsedSpaceQuota(user.usedSpaceQuota);
				});
		}
	}, [ isLoggedIn ]);

	const breadcrumbsList = [
		{ id: 1, label: "home" },
		{ id: 2, label:t('breadcrumbs.accountMyProfile') },
	];

	return (
		<main className={ styles["account"] }>
			<SearchTranscriptions
				setSearchString={ setSearchString }
				searchString={ searchString }
				setUseMetadata={ setUseMetadata }
			/>
			<Container className={ styles["container"] }>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />

				<h1 className={ styles["account__header--title"] }>
					{t('breadcrumbs.accountMyProfile')} 
				</h1>

				<div className={ styles["account__grid"]}>
					<div className={ styles["account__grid--item"]}>
						<div>
							<img src={iconMenu1}/>
						</div>
						<div>
							<h4>{t('headers.menu.myAccountMenu.general')}</h4>
							<div>
								<a href={userProfile} target='_blank'>
									{t('headers.menu.myAccountMenu.profile')}
								</a>
							</div>
						</div>
					</div>
					<div className={ styles["account__grid--item"]}>
						<div>
							<img src={iconMenu2}/>
						</div>
						<div>
							<h4>{t('headers.menu.myAccountMenu.ariadna')}</h4>
							<div>
								<a href={ariadnaWwwMyCollectionsUrl} target='_blank'>
									{t('headers.menu.myAccountMenu.myCollections')}
								</a>
							</div>
							<div>
								<a href={ariadnaWwwMySubjectsUrl} target='_blank'>
									{t('headers.menu.myAccountMenu.myTopics')}
								</a>
							</div>
						</div>
					</div>
					<div className={ styles["account__grid--item"]}>
						<div>
							<img src={iconMenu3}/>
						</div>
						<div>
							<h4>{t('headers.menu.myAccountMenu.historyTexts')}</h4>
							<div>
								<Link to={url.transcriptionsUser}>
									{t('headers.menu.myAccountMenu.historyTextsMyProjects')}
								</Link>
							</div>
							{isLoggedIn && CheckSecurityGroupPermission("canAccessProjectsDashboard", UserType) && <div>
								<Link to={url.auth.admin.projects}>
									{t('headers.menu.myAccountMenu.serviceManagement')}
								</Link>
							</div>}
							{isLoggedIn && CheckSecurityGroupPermission("canAccessUsersDashboard", UserType) && <div>
								<Link to={url.auth.admin.users}>
									{t('headers.menu.myAccountMenu.usersManagement')}
								</Link>
							</div>}
							{isLoggedIn && CheckSecurityGroupPermission("canManageTranscriptionExportToDLibra", UserType) && <div>
								<Link to={url.dLibraExport}>
									{t('headers.menu.myAccountMenu.projectsForDLibra')}
								</Link>
							</div>}
						</div>
					</div>
					<div className={ styles["account__grid--item"]}>
						<div>
							<img src={iconMenu4}/>
						</div>
						<div>
							<h4>{t('headers.menu.myAccountMenu.edu')}</h4>
							<div>
								<a href={ariadnaEduMyProjectsUrl} target='_blank'>
									{t('headers.menu.myAccountMenu.eduMyProjects')}
								</a>
							</div>
						</div>
					</div>
					<div className={ styles["account__grid--item"]}>
						<div>
							<img src={iconMenu5}/>
						</div>
						<div>
							<h4>{t('headers.menu.myAccountMenu.historyPlaces')}</h4>
							<div>
								<a href={ariadnaWolMyProjectsUrl} target='_blank'>
									{t('headers.menu.myAccountMenu.historyPlacesMyProjects')}
								</a>
							</div>
						</div>
					</div>
					<div className={ styles["account__grid--item"]}>
						<div>
							<img src={iconMenu6}/>
						</div>
						<div>
							<h4>{t('headers.menu.myAccountMenu.management')}</h4>
							<div>
								{isLoggedIn && CheckSecurityGroupPermission("canAccessAriadnaUserManagement", UserType) && <div>
									<a href={ariadnaUserManagement} target='_blank'>
										{t('headers.menu.myAccountMenu.ariadnaUserManagement')}
									</a>
								</div>}
								{isLoggedIn && CheckSecurityGroupPermission("canAccessAriadnaServiceManagement", UserType) && <div>
									<a href={ariadnaServiceManagement} target='_blank'>
										{t('headers.menu.myAccountMenu.ariadnaServiceManagement')}
									</a>
								</div>}
							</div>
						</div>
					</div>
				</div>
			</Container>
		</main>
	);
};

export default Account;
