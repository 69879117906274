import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Dropdown, Button } from 'react-bootstrap';

import store from '@/store/store';
import { authActions } from "@/store/actions/auth.actions";
import { appActions } from "@/store/actions/app.actions";
import { CheckSecurityGroupPermission } from "@/permissions/SecurityGroups";

import styles from '@Templates/header/AppHeader/HeaderMyAccount.module.sass';
import styles2 from '@Templates/header/AppHeader/HeaderTopBar.module.sass';
import url from "@/router/urls";

const HeaderMyAccount = ({isLoggedIn, UserType}) => {
	const { t } = useTranslation();
	const isProtected = useSelector((state) => state.auth.path.isProtected);
	const formsAutorization = useSelector((state) => state.app.config.authorization == "FORMS");
	const authorizationLoginUrl = useSelector((state) => state.app.config.reactAppApi + state.app.config.authorizationLoginUrl);
	const reactAppApi = useSelector((state) => state.app.config.reactAppApi);
	const authorizationRegisterUrl = useSelector((state) => state.app.config.reactAppApi + state.app.config.authorizationRegisterUrl);
	const dispatch = useDispatch();

	const ariadnaUserManagement = useSelector(state => state.app.config.ariadnaUserManagement);
	const keycloak = new URL(ariadnaUserManagement).origin;
	const userProfile = keycloak + "/auth/realms/BSA/account";

	const signOut = () => {
		let authorizationSignOutUrl = '/auth/sign-out?post_logout_redirect_uri=';
		
		authorizationSignOutUrl = reactAppApi + authorizationSignOutUrl;

		const url = formsAutorization ? '/auth/sign-out' : authorizationSignOutUrl + window.location.origin;

		if(formsAutorization)
			dispatch(authActions.signOut(isProtected, url));
		else	
			window.location = url;
	};

	const resetErrorFlags = () => {
		store.dispatch(appActions.setNotFound(false));
		store.dispatch(appActions.setError(false));
	};

	return (
		<Dropdown className={ styles["myacount-dropdown"] }  alignRight="true">

			<Dropdown.Toggle
				id='dropdown-custom-1'
				className={styles["myacount-dropdown__button"] }
				variant={styles["myacount-dropdown__button"] }
			>{isLoggedIn ? t('headers.menu.myAccount') : t('headers.menu.logIn')}
			</Dropdown.Toggle>

			<Dropdown.Menu className={isLoggedIn ? styles["myacount-dropdown__menu"] : styles["myacount-dropdown__menu"] + " " + styles["myacount-dropdown__menu-login"]}>
				{isLoggedIn ? (
					<>
						<div className={styles2["dropdown__wrapper"]}>
							<Dropdown.Item
								disabled
								className={styles2["dropdown__button--disabled-grey"]}
							>
								{t('headers.menu.myAccountMenu.general')}
							</Dropdown.Item>
							<Link
								className={`dropdown-item ${styles["myacount-dropdown__item"]}`}
								to={{pathname: url.auth.account, state: { isProtected: true } }}
								onClick={resetErrorFlags}
							>
								{ t('headers.menu.myAccountMenu.myAccount') }
							</Link>
							<Dropdown.Item
								href={userProfile} 
								target='_blank'
								className={`${styles["myacount-dropdown__item"]}`}
							>
								{t('headers.menu.myAccountMenu.myProfile')}
							</Dropdown.Item>
						
						</div>
						<div className={styles2["dropdown__wrapper"]}>
							<Dropdown.Item
								disabled
								className={styles2["dropdown__button--disabled-grey"]}
							>
								{t('headers.menu.myAccountMenu.ariadna')}
							</Dropdown.Item>
							<Dropdown.Item
								href={store.getState().app.config.ariadnaWwwMyCollectionsUrl}
								target='_blank'
								className={`${styles["myacount-dropdown__item"]}`}
							>
								{t('headers.menu.myAccountMenu.myCollections')}
							</Dropdown.Item>
							<Dropdown.Item
								href={store.getState().app.config.ariadnaWwwMySubjectsUrl}
								target='_blank'
								className={`${styles["myacount-dropdown__item"]}`}
							>
								{t('headers.menu.myAccountMenu.myTopics')}
							</Dropdown.Item>
							{CheckSecurityGroupPermission("canAccessAriadnaServiceManagement", UserType) && <Dropdown.Item
								href={store.getState().app.config.ariadnaServiceManagement}
								target='_blank'
								className={`${styles["myacount-dropdown__item"]}`}
							>
								{t('headers.menu.myAccountMenu.ariadnaServiceManagement')}
							</Dropdown.Item>}
							{CheckSecurityGroupPermission("canAccessAriadnaUserManagement", UserType) && <Dropdown.Item
								href={store.getState().app.config.ariadnaUserManagement}
								target='_blank'
								className={`${styles["myacount-dropdown__item"]}`}
							>
								{t('headers.menu.myAccountMenu.ariadnaUserManagement')}
							</Dropdown.Item>}
						</div>

						<div className={styles2["dropdown__wrapper"]}>
							<Dropdown.Item
								disabled
								className={styles2["dropdown__button--disabled-grey"]}
							>
								{t('headers.menu.myAccountMenu.historyTexts')}
							</Dropdown.Item>
							<Dropdown.Item
								href={url.transcriptionsUser}
								className={`${styles["myacount-dropdown__item"]}`}
							>
								{t('headers.menu.myAccountMenu.historyTextsMyProjects')}
							</Dropdown.Item>
							{CheckSecurityGroupPermission("canAccessProjectsDashboard", UserType) &&
							<Dropdown.Item
								href={url.auth.admin.projects}
								className={`${styles["myacount-dropdown__item"]}`}
							>
								{t('headers.menu.myAccountMenu.serviceManagement')}
							</Dropdown.Item>}
							{CheckSecurityGroupPermission("canAccessUsersDashboard", UserType) &&
							<Dropdown.Item
								href={url.auth.admin.users}
								className={`${styles["myacount-dropdown__item"]}`}
							>
								{t('headers.menu.myAccountMenu.usersManagement')}
							</Dropdown.Item>}
							{CheckSecurityGroupPermission("canManageTranscriptionExportToDLibra", UserType) &&
							<Dropdown.Item
								href={url.dLibraExport}
								className={`${styles["myacount-dropdown__item"]}`}
							>
								{t('headers.menu.myAccountMenu.projectsForDLibra')}
							</Dropdown.Item>}
						</div>

						<div className={styles2["dropdown__wrapper"]}>
							<Dropdown.Item
								disabled
								className={styles2["dropdown__button--disabled-grey"]}
							>
								{t('headers.menu.myAccountMenu.edu')}
							</Dropdown.Item>
							<Dropdown.Item
								href={store.getState().app.config.ariadnaEduMyProjectsUrl}
								className={`${styles["myacount-dropdown__item"]}`}
								target='_blank'
							>
								{t('headers.menu.myAccountMenu.eduMyProjects')}
							</Dropdown.Item>
						</div>

						<div className={styles2["dropdown__wrapper"]}>
							<Dropdown.Item
								disabled
								className={styles2["dropdown__button--disabled-grey"]}
							>
								{t('headers.menu.myAccountMenu.historyPlaces')}
							</Dropdown.Item>
							<Dropdown.Item
								href={store.getState().app.config.ariadnaWolMyProjectsUrl}
								className={`${styles["myacount-dropdown__item"]}`}
								target='_blank'
							>
								{t('headers.menu.myAccountMenu.historyPlacesMyProjects')}
							</Dropdown.Item>
						</div>
						<div className={`${styles["myacount-dropdown__item--logout"]}`}>
							<Button variant="secondary" onClick={() => signOut()}>
								{t('headers.menu.logOut')}
							</Button>
						</div>
					</>
				) : (
					<>
						<p className={styles["myacount-dropdown__menu--text-header"]}>{t('headers.menu.logInTitle')}</p>
						<p className={styles["myacount-dropdown__menu--text"]}>{t('headers.menu.logInDesc1')}</p>
						<p className={styles["myacount-dropdown__menu--text"]}>{t('headers.menu.logInDesc2')}</p>
						<div>
							<a
								className={styles["myacount-dropdown__menu--register-button"]}
								href={formsAutorization ? url.auth.register : authorizationRegisterUrl + "?returnUrl=" + encodeURIComponent(window.location)}
							>
								{t("headers.menu.createAccount")}
							</a>
							<Button
								variant='primary'
								className={styles["myacount-dropdown__menu--login-button"]}
								href={formsAutorization ? url.auth.login : authorizationLoginUrl + "?returnUrl=" + encodeURIComponent(window.location)}
							>
								{t("headers.menu.logIn")}
							</Button>
						</div>
					</>
				)}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default HeaderMyAccount;

//	langs.map(lang =>
//		<Dropdown.Item
//			key={lang.id}
//			onClick={() => setLanguage(lang)}
//			className={` 
//							${styles["lang-dropdown__item"]}
//							${lang.id !== appState.currentLang ? styles["lang-dropdown__item--not-active"] : ''} 
//						` }
//		>
//			{lang.id === appState.currentLang ? <i className='icon-accept' /> : null}
//			{lang.name}
//		</Dropdown.Item>,
//	)
//}
