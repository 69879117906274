import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useHistory } from 'react-router';
import { Container } from "react-bootstrap";
import { reverse } from 'named-urls';

import { appActions } from "@/store/actions/app.actions";

import api from "@/services/api";
import url from "@/router/urls";

import Breadcrumbs from "@Elements/Breadcrumbs/Breadcrumbs";
import Pagination from '@Components/Pagination/Pagination';
import { useShowModal } from '@Elements/Modal/ModalHooks';

import DLibraExportTranscriptionItem from '@Pages/DLibraExport/parts/DLibraExportTranscriptionItem';
import SearchTranscriptions from "@Elements/Search/SearchTranscriptions";
import SearchTypeEnum from "@/utils/SearchTypeEnum";

import styles from "@Pages/DLibraExport/DLibraExport.module.sass";

/** 
 * Screen with list of projects flagged for sending to DLibra with options to accept or reject them
 * 
 * @component
 */
const DLibraExport = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const { id: userId } = useParams();

	const [searchTranscriptionsString, setSearchTranscriptionsString] = useState("");

	const [ apiData, setApiData ] = useState(null);
	const [ currentPageNum, setCurrentPageNum ] = useState(null);
	const [ searchString, setSearchString ] = useState(null);
	const [ currentSearchedString, setCurrentSearchedString ] = useState("");
	const [ initialLoaded, setInitialLoaded ] = useState(false);
	const [ sortDirection, setSortDirection ] = useState('ASC');

	const [useMetadata, setUseMetadata] = useState(SearchTypeEnum.ALL.toString());

	const modalConfirmInfo = useSelector((state) => state.app.modalConfirmInfo);

	const loadData = useCallback((endpoint, params) => {
		dispatch(appActions.setLoading(true));
		api.get(endpoint, { params })
			.then(({ data }) => {
				if ((parseInt(params.page) > parseInt(data.pages)) && (data.pages !== 0)) {
					history.replace(`?${params.q ? `&q=${params.q}&` : ''}page=${data.pages}`);
				}
				else {
					dispatch(appActions.setLoading(false));
					setInitialLoaded(true);
					setApiData(data);
				}
			})
			.catch(() => {
				dispatch(appActions.setLoading(false));
			});
	}, [ dispatch, history ]);

	useEffect(() => {
		const usp = new URLSearchParams(location.search);
		let pageNum = parseInt(usp.get("page"));
		const query = usp.get("q");
		setCurrentSearchedString(query ? encodeURIComponent(query) : "");
		if (!pageNum || pageNum < 1) {
			if (query && query.length > 1) {
				history.replace(`?q=${query}&page=1`);
			} else {
				history.replace(`?page=1`);
			}
		} else {
			setSearchString(query);
			setCurrentPageNum(pageNum);
			loadData('/transcriptions/export', {
				ownerId: userId,
				page: pageNum,
				q: ((query && query.length > 1) ? query : null),
				perpage: 8,
				order: "title",
				"order-dir": sortDirection
			});
		}
	}, [ location, history, loadData, userId, sortDirection ]);

	useEffect(() => {
		if (initialLoaded) {
			history.push(`?${searchString ? `&q=${searchString}&` : ''}page=${currentPageNum}`);
		}
	}, [ currentPageNum, searchString, history ]);

	const openTranscription = (transcriptionId) => {
		history.push(reverse(url.transcription.details, { transcriptionId: transcriptionId }));
	};

	const [ transcriptionToDeleteId, setTranscriptionToDeleteId ] = useState(null);
	const modalDeleteTranscription = useCallback(useShowModal({
		title:t('modals.deleteTranscription.title'),
		name: 'deleteGroup',
		componentPath: './parts/ModalDeleteTranscriptionCallback',
	}), [ useShowModal ]);

	useEffect(() => {
		switch (modalConfirmInfo) {
			case "TRANSCRIPTION_DELETE_CONFIRMED":
				api.delete(`/transcriptions/${transcriptionToDeleteId}`)
					.then(() => {
						loadData('/user-transcriptions/list', {
							page: currentPageNum,
							q: ((searchString && searchString.length > 1) ? searchString : null),
							perpage: 8,
							"order-dir": sortDirection
						});
					});
				break;
			default:
				break;
		}
		dispatch(appActions.setModalConfirmInfo(null));
		setTranscriptionToDeleteId(null);
	}, [ modalConfirmInfo, currentPageNum, dispatch, loadData, searchString, userId ]);

	const getColumnIcon = () => {
		let icon = sortDirection == 'ASC' ? 'icon-sort-up' : (sortDirection == 'DESC' ? 'icon-sort-down' : 'icon-sort');
		return (
			<i
				className={icon}
				onClick={ () => { if(sortDirection == 'ASC'){ setSortDirection('DESC') } else { setSortDirection('ASC') } } }
			/>
		);
	};

	useEffect(() => {
		if (transcriptionToDeleteId) {
			modalDeleteTranscription();
		}
	}, [ transcriptionToDeleteId, modalDeleteTranscription ]);

	const breadcrumbsList = [
		{ id: 1, label: "home", link: url.home },
		{ id: 2, label:t('breadcrumbs.account'), link: url.auth.account },
		{ id: 4, label:t('breadcrumbs.dLibraExport') },
	];

	return (
		<main>
			<SearchTranscriptions
				setSearchString={setSearchTranscriptionsString}
				searchString={searchTranscriptionsString}
				setUseMetadata={setUseMetadata}
			/>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
				<div className='block__header'>
					<h1 className='text--title'>
						{t('dLibraExports.projectList')}
					</h1>
				</div>
				<div className={ styles["transcriptions__header"] }>
					<p>{t('dLibraExports.projectListDescription')}</p>
				</div>

				<section className={ (apiData && apiData.pages > 1) ? styles["transcription-list__pagination-wrapper"] : styles["transcription-list__no-pagination-wrapper"] }>
					{(apiData) && (
						<Pagination
							currentPage={ apiData.page }
							pagesNum={ apiData.pages }
							onGoToPage={ setCurrentPageNum }
						>
							<div className={ styles["transcription-list__wrapper"] }>
								<div className={styles["transcription-list__item-header"]}>
									<div className={styles["transcription-list__item--order-header"]}>
										{t('dLibraExports.transcriptionsList.number')}
									</div>
									<div className={styles["transcription-list__item--last-modified-header"]}>
										{t('dLibraExports.transcriptionsList.lastModification')}
										{getColumnIcon()}
									</div>
									<div className={styles["transcription-list__item--project-header"]}>
										{t('dLibraExports.transcriptionsList.projectName')}
									</div>
									<div className={styles["transcription-list__item--creator-header"]}>
										{t('dLibraExports.transcriptionsList.projectCreator')}
									</div>
									<div className={styles["transcription-list__item--accept-button"]}>
					
									</div>
									<div className={styles["transcription-list__item--reject-button"]}>
					
									</div>
								</div>
								{apiData.transcriptions &&
									apiData.transcriptions.map((item, index) => (
										<DLibraExportTranscriptionItem
											orderNum={index}
											creator={item.creator}
											id={item.id}
											title={item.title}
											lastModificationDate={item.lastModificationDate}
											openTranscription={openTranscription}
										/>
									))}
							</div>
						</Pagination>
					)}
				</section>
			</Container>
		</main>
	);
};

export default DLibraExport;