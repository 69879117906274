import React from "react";
import { useTranslation } from 'react-i18next';

import Button from "@Elements/Button/Button";
import { useShowModal } from "@Elements/Modal/ModalHooks";

import styles from "@Pages/DLibraExport/DLibraExport.module.sass";

const DLibraExportTranscriptionItem = ({ creator, orderNum, id, title, lastModificationDate, openTranscription }) => {
	const { t } = useTranslation();

    const formatDate = (date) => ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth()+1)).slice(-2) + "." + date.getFullYear();

	const modalDLibraExportAccept = useShowModal({
		title:t('modals.dLibraExportAccept.title'),
		name: "dLibraExportAccept",
		componentPath: "./parts/ModalDLibraExportAccept",
		componentProps: {transcriptionId: parseInt(id)}
	});

    const modalDLibraExportReject = useShowModal({
		title:t('modals.dLibraExportReject.title'),
		name: "dLibraExportReject",
		componentPath: "./parts/ModalDLibraExportReject",
		componentProps: {transcriptionId: parseInt(id)}
	});

	return (
		<div className={ styles["transcription-list__item"] }>
			<div className={ styles["transcription-list__item--order"] }>
				{orderNum + 1}.
			</div>
            <div className={ styles["transcription-list__item--last-modified"] }>
                {formatDate(new Date(lastModificationDate))}
            </div>
			<div className={ styles["transcription-list__item--project"] }>
				<Button
					variant='link'
					type='button'
					onClick={ () => openTranscription(id) }
					className={ styles["transcription-list__item--btn-left"] + " " + styles["transcription-list__item--link"] }
				>
					{title}
				</Button>
			</div>
			<div className={ styles["transcription-list__item--creator"] }>
				{creator}
			</div>

            <div className={ styles["transcription-list__item--reject-button"] }>
				<Button
					variant='tetriary'
					type='button'
					className={ styles["transcription-list__item--btn"] }
					onClick={ () => modalDLibraExportReject() }
				>
                    <i className='icon-close'/>
					<span className={ styles["transcription-list__item--reject-button-text"] }>{t('dLibraExports.transcriptionsList.reject')}</span>
                </Button>
			</div>
			<div className={ styles["transcription-list__item--accept-button"] }>
				<Button
					variant='tetriary'
					type='button'
					className={ styles["transcription-list__item--btn"] }
					onClick={ () => modalDLibraExportAccept() }
				>
                    <i className='icon-accept'/>
					<span className={ styles["transcription-list__item--accept-button-text"] }>{t('dLibraExports.transcriptionsList.accept')}</span>
                </Button>
			</div>
		</div>
	);
};

export default DLibraExportTranscriptionItem;