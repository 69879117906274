import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";

/** 
 * Customized react-bootstrap dropdown with text values instead of indexes
 * 
 * @component
 */
const CommonDropdownValue = ({ options, selectedValue, setSelectedValue, placeholder, isDisabled, label, className }) => {
	const [ showPlaceholder, setShowPlaceholder ] = useState("");
	const [ selectedIndex, setSelectedIndex ] = useState(0);

	useEffect( () => {
		if(placeholder && placeholder.length > 0){
			setShowPlaceholder(placeholder);
		}
	}, [ placeholder ]);
    
	useEffect( () => {
		for(let i = 0; i < options.length; ++i){
			if(options[i].value == selectedValue){
				setSelectedIndex(i);
				return;
			}
		}
	}, [ selectedValue ]);

	return (
		<>
			{ (label && label.length > 1) && (
				<label>{label}</label>
			)}
			<Dropdown
				bsPrefix={ `dropdown-common ${ showPlaceholder && 'dropdown-common__placeholder'} ${className}` }
				drop='down'
			>
				<Dropdown.Toggle
					variant='dropdown'
					disabled={ isDisabled }
					id='common'
				>
					{ showPlaceholder ? showPlaceholder : options[selectedIndex].label }
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{ options.map((item, index) => (
						<Dropdown.Item
							onClick={ () => { 
								setShowPlaceholder('');
								setSelectedValue(item.value);
							} }
							className={ `${index === selectedIndex ? "dropdown-item__active" : ""}` }
							key={ `${item.value}-${index+1}` }
							value={ item.value }
						>
							{ (index === selectedIndex) && <i className='icon-accept' /> }
							{ item.label }
						</Dropdown.Item>
					))}
					
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
};

CommonDropdownValue.propTypes = {
	options: PropTypes.array,
	selectedValue: PropTypes.any,
	setSelectedValue: PropTypes.func,
	placeholder: PropTypes.string, 
	isDisabled: PropTypes.bool,
	label: PropTypes.string,
	className: PropTypes.string
};

export default CommonDropdownValue;