import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";

import rootReducer from "@/store/reducers";
import loadingBarMiddleware from "@/store/middleware/loadingBarMiddleware";
import cookieMiddleware from "@/store/middleware/cookieMiddleware";

const store = createStore(
	rootReducer,
	compose(
		applyMiddleware(
			thunkMiddleware,
			cookieMiddleware,
			loadingBarMiddleware),
		//window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
	),
);
export default store;