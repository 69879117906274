import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

import { viewerActions } from "@/store/actions/viewer.actions";

import DropdownBox from "@Pages/Viewer/parts/ViewerEditor/parts/DropdownBox";
import FormattingDropdownButton from "@Pages/Viewer/parts/ViewerEditor/parts/FormattingDropdownButton";
import FormattingButton from "@Pages/Viewer/parts/ViewerEditor/parts/FormattingButton";

import Button from "@Elements/Button/Button";

const INLINE_STYLES = [
	{ label: "B", style: "BOLD", icon: "bold", tooltip: "viewer.tooltip.menu.style.BOLD" },
	{ label: "I", style: "ITALIC", icon: "italic", tooltip: "viewer.tooltip.menu.style.ITALIC" },
	{ label: "S", style: "STRIKETHROUGH", icon: "strike", tooltip: "viewer.tooltip.menu.style.STRIKETHROUGH" },
	{ label: "U", style: "UNDERLINE", icon: "underline", tooltip: "viewer.tooltip.menu.style.UNDERLINE" },
];

const StyleButton = ({ style, active, icon, tooltip = "" }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const verseEdit = useSelector(state => state.viewer.editor.verseEdit);

	const setStyle = (event, value) => {
		event.preventDefault();
		dispatch(viewerActions.setStyleActive(value));
	};

	return (
		<div
			data-tip={t('viewer.tooltip.menu.style.'+style) }
			data-place='right'
		>
			<Button
				variant='viewer-button'
				className={ `viewer__button--menu ${active ? "viewer__button--active" : ""}` }
				onMouseDown={ event => setStyle(event, style) }
				disabled={ !verseEdit }
				data-tip={ tooltip }
			>
				<span className='d-none'>{icon}</span>
				<i className={ `icon-${icon}` } />
			</Button>
		</div>
	);
};

StyleButton.propTypes = {
	style: PropTypes.string,
	active: PropTypes.bool,
	icon: PropTypes.string,
	tooltip: PropTypes.string,
};

const ViewerEditorMenu = ({ anyVerseActive }) => {
	const dispatch = useDispatch();

	const currentStyle = useSelector(state => state.viewer.editor.styleCurrent);
	const { t } = useTranslation();

	const verseEdit = useSelector(state => state.viewer.editor.verseEdit);
	const verseActive = useSelector(state => state.viewer.editor.verseActive);
	const charMap = useSelector(state => state.viewer.charMap);
	const viewMode = useSelector(state => state.viewer.data.viewMode);
	const verses = useSelector(state => state.viewer.data.verses);
	const verse = verses.find(x => x.id === verseActive);

	React.useEffect(() => {
		ReactTooltip.rebuild();
	}, []);

	return (
		<ul className='viewer__menu--list'>
			{INLINE_STYLES.map(type => (
				<li key={ type.style }>
					<StyleButton
						key={ type.label }
						active={ currentStyle.size ? currentStyle.has(type.style) : false }
						label={ type.label }
						style={ type.style }
						icon={ type.icon }
						tooltip={ t('type.tooltip') }
					/>
				</li>
			))}
			{(
				<>
					<li>
						<DropdownBox
							disabled={
								!anyVerseActive ||
								verseEdit ||
								(viewMode === "transcribe" && verse && verse.status === "verified")
							}
							tooltip={t('viewer.tooltip.menu.titleH') }
							icon='header'
						>
							<FormattingDropdownButton
								icon='header-1-end'
								func='formatting/heading/end-of-heading-1'
								label={t('viewer.tooltip.menu.titleH1') }
							/>
							<FormattingDropdownButton
								icon='header-2-end'
								func='formatting/heading/end-of-heading-2'
								label={t('viewer.tooltip.menu.titleH2') }
							/>
							<FormattingDropdownButton
								icon='header-3-end'
								func='formatting/heading/end-of-heading-3'
								label={t('viewer.tooltip.menu.titleH3') }
							/>
						</DropdownBox>
					</li>


					<li>
						<FormattingButton
							icon='quote'
							func='formatting/end-of-paragraph'
							tooltip={t('viewer.tooltip.menu.endOfParagraph') }
							data-tip={t('viewer.tooltip.menu.endOfParagraph') }
						/>
					</li>

					<li>
						<DropdownBox
							disabled={
								!anyVerseActive ||
								verseEdit ||
								(viewMode === "transcribe" && verse && verse.status === "verified")
							}
							tooltip={t('viewer.tooltip.menu.list') }
							icon='list'
						>
							<FormattingDropdownButton
								icon='list-item-end'
								func='formatting/list-form/end-of-item'
								label={t('viewer.tooltip.menu.endOfListItem') }
							/>
							<FormattingDropdownButton
								icon='list-end'
								func='formatting/list-form/end-of-list'
								label={t('viewer.tooltip.menu.endOfList') }
							/>
						</DropdownBox>
					</li>

					<li>
						<DropdownBox
							disabled={
								!anyVerseActive ||
								verseEdit ||
								(viewMode === "transcribe" && verse && verse.status === "verified")
							}
							tooltip={t('viewer.tooltip.menu.table') }
							icon='table'
						>
							<FormattingDropdownButton
								icon='table-cell-end'
								func='formatting/tabular-form/end-of-cell'
								label={t('viewer.tooltip.menu.endOfCell') }
							/>
							<FormattingDropdownButton
								icon='table-verse-end'
								func='formatting/tabular-form/end-of-row'
								label={t('viewer.tooltip.menu.endOfRow') }
							/>
							<FormattingDropdownButton
								icon='table-end'
								func='formatting/tabular-form/end-of-table'
								label={t('viewer.tooltip.menu.endOfTable') }
							/>
						</DropdownBox>
					</li>

					<li>
						<FormattingButton
							icon='subscript'
							func='formatting/annotation'
							tooltip={t('viewer.tooltip.menu.subscript') }
							data-tip={t('viewer.tooltip.menu.subscript') }
						/>
					</li>

					<li>
						<DropdownBox
							disabled={
								!anyVerseActive ||
								verseEdit ||
								(viewMode === "transcribe" && verse && verse.status === "verified")
							}
							tooltip={t('viewer.tooltip.menu.pageDescription') }
							icon='verses-desc'
						>
							<FormattingDropdownButton
								icon='verse-header'
								func='formatting/forme-work/header'
								label={t('viewer.tooltip.menu.verseHeader') }
							/>
							<FormattingDropdownButton
								icon='verse-footer'
								func='formatting/forme-work/footer'
								label={t('viewer.tooltip.menu.verseFooter') }
							/>
							<FormattingDropdownButton
								icon='page-number'
								func='formatting/forme-work/page-no'
								label={t('viewer.tooltip.menu.pageNumber') }
							/>
						</DropdownBox>
					</li>

					<li>
						<DropdownBox
							visible={false}
							disabled={
								!anyVerseActive ||
								verseEdit ||
								(viewMode === "transcribe" && verse && verse.status === "verified")
							}
							tooltip={t('viewer.tooltip.menu.image') }
							icon='image-description'
						>
							<FormattingDropdownButton
								icon='image'
								func='image'
								label={t('viewer.tooltip.menu.image') }
							/>
							<FormattingDropdownButton
								icon='image-description'
								func='formatting/image-caption'
								label={t('viewer.tooltip.menu.imageCaption') }
							/>
						</DropdownBox>
					</li>

					<li>
						<FormattingButton
							icon='metadata'
							func='formatting/marginalia'
							tooltip={t('viewer.tooltip.menu.marginals') }
							data-tip={t('viewer.tooltip.menu.marginals') }
						/>
					</li>

					<li>
						<FormattingButton
							icon='music'
							func='formatting/notated-music'
							tooltip={t('viewer.tooltip.menu.notes') }
							data-tip={t('viewer.tooltip.menu.notes') }
						/>
					</li>

					<li>
						<DropdownBox
							disabled={
								!anyVerseActive ||
								verseEdit ||
								(viewMode === "transcribe" && verse && verse.status === "verified")
							}
							tooltip={t('viewer.tooltip.menu.index') }
							icon='instapaper'
						>
							<FormattingDropdownButton
								icon='index-term'
								func='verse-set-as-index'
								indexType='term'
								label={t('viewer.tooltip.menu.setAsTerm') }
							/>
							<FormattingDropdownButton
								icon="index-place"
								func='verse-set-as-index'
								indexType='place'
								label={t('viewer.tooltip.menu.setAsPlace') }
							/>
							<FormattingDropdownButton
								icon="index-person"
								func='verse-set-as-index'
								indexType='person'
								label={t('viewer.tooltip.menu.setAsPerson') }
							/>
						</DropdownBox>
					</li>

					<hr className='viewer__divider' />

					<li>
						<FormattingButton
							visible={false}
							icon='verse-auto-transcription'
							func='recognize-content'
							tooltip={t('viewer.tooltip.menu.verseAutoTranscription') }
						/>
					</li>

					<li>
						<FormattingButton
							visible={false}
							icon='verse-comment'
							func='verse-comment'
							tooltip={t('viewer.tooltip.menu.addComment') }
						/>
					</li>

					<li>
						<DropdownBox
							tooltip={t('viewer.tooltip.menu.deleting') }
							disabled={ !verses.length }
							icon='verse-delete'
						>
							<FormattingDropdownButton
								icon='verse-delete'
								func='verse-delete'
								disabled={ !anyVerseActive || verseEdit }
								label={t('viewer.tooltip.menu.verseDelete') }
							/>
							<FormattingDropdownButton
								icon='verse-delete'
								func='verses-delete'
								label={t('viewer.tooltip.menu.versesDelete') }
							/>
						</DropdownBox>
					</li>

					<li>
						<FormattingButton
							icon='verse-clear'
							func='modify-content'
							tooltip={t('viewer.tooltip.menu.verseClear') }
						/>
					</li>

					<hr className='viewer__divider' />

					<li>
						<FormattingButton
							icon='verse-up'
							func='move-up'
							tooltip={t('viewer.tooltip.menu.verseUp') }
						/>
					</li>

					<li>
						<FormattingButton
							icon='verse-down'
							func='move-down'
							tooltip={t('viewer.tooltip.menu.verseDown') }
						/>
					</li>



				</>
			)}
		</ul>
	);
};

ViewerEditorMenu.propTypes = {
	anyVerseActive: PropTypes.bool,
};

export default memo(ViewerEditorMenu);
