import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router";
import PropTypes from "prop-types";

import { viewerActions } from '@/store/actions/viewer.actions';
import { appActions } from '@/store/actions/app.actions';

import ViewerOSD from "@Pages/Viewer/parts/ViewerOSD/ViewerOSD";
import ViewerOSDMenu from "@Pages/Viewer/parts/ViewerOSD/ViewerOSDMenu";

import ViewerEditor from "@Pages/Viewer/parts/ViewerEditor/ViewerEditor";
import ViewerEditorMenu from "@Pages/Viewer/parts/ViewerEditor/ViewerEditorMenu";

import Button from "@Elements/Button/Button";
import Error403 from "@Pages/Error/Error403";

import getTranscriptionProcessingStatus from '@/utils/transcriptionProcessingStatus';

import { useShowModal, useModal, useHideModal } from '@Elements/Modal/ModalHooks';
import { CheckViewModePermission } from "@/permissions/ViewModes";

const ViewerTogglePanel = ({ type }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const togglePanels = useSelector((state) => state.app.viewer.togglePanels);

	const togglePanel = (value) => {
		return value === 'osd' ?
			dispatch(appActions.setTogglePanelsOSD(!togglePanels.osd)) :
			dispatch(appActions.setTogglePanelsEditor(!togglePanels.editor));
	};

	return (
		<Button
			variant='viewer-full'
			className='viewer__button--fold'
			onClick={ () => togglePanel(type) }
		>
			<i className='viewer__icon--fold' />
			{!togglePanels[type] ? (
				<span>{t('viewer.panels.fold')}</span>
			) : (
				<span>{t('viewer.panels.expand')}</span>
			)}
		</Button>
	);
};

ViewerTogglePanel.propTypes = {
	type: PropTypes.string,
};

const ViewerContentHeader = ({ panel, osdDrawMode, isMobile = false }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();


	const viewMode = useSelector((state) => state.viewer.data.viewMode);
	const student = viewMode === 'student';

	const mobilePanels = useSelector((state) => state.app.viewer.mobilePanels);
	const verseActive = useSelector((state) => state.viewer.editor.verseActive);
	const verseEdit = useSelector((state) => state.viewer.editor.verseEdit);
	const selectionEdit = useSelector((state) => state.viewer.ocd.selection);

	const layersData = useSelector((state) => state.viewer.transcriptionLayersData);
	const selectedLayer = useSelector(state => state.viewer.selectedLayer);

	const mobilePanel = (mode) => {
		dispatch(appActions.setMobilePanels(mode));
	};

	useEffect(() => {
		dispatch(viewerActions.clearTranscriptionData());
	}, [ dispatch ]);

	useEffect(() => {
		mobilePanel(student ? 'student' : 'osd');
	}, [ viewMode ]);

	useEffect(() => {
		if (selectionEdit) {
			// when change active verse leave edit mode
			dispatch(viewerActions.setSelectionEdit(false));
		}
	}, [ verseActive ]);

	return (
		<>
			<Button
				variant='viewer-title'
				className={ `viewer__button--title viewer__button--title-${panel} ${mobilePanels === "osd" ? 'viewer__button--title-active' : ''}` }
				onClick={ () => mobilePanel('osd') }
			>
				{t('viewer.panels.title.osd')}
			</Button>
			<Button
				variant='viewer-title'
				className={ `viewer__button--title viewer__button--title-${panel} ${mobilePanels === "student" ? 'viewer__button--title-active' : ''} ${student ? 'viewer__button--student-mode' : 'd-none'}` }
				onClick={ () => mobilePanel('student') }
				disabled={ verseEdit }
			>
				{t('viewer.panels.title.student')}
			</Button>
			<Button
				variant='viewer-title'
				className={ `viewer__button--title viewer__button--title-${panel} ${mobilePanels === "editor" ? 'viewer__button--title-active' : ''} ${student ? 'viewer__button--student-mode' : ''}` }
				onClick={ () => mobilePanel('editor') }
				disabled={ verseEdit }
			>
				{layersData.layers.map((value) => {
					if(value.layer.id == selectedLayer) {
						return "[" + layersData.idToNumber.get(value.layer.id) + "] " + value.layer.name;
					}else{
						return "";
					}
				})}
			</Button>
		</>
	);
};

ViewerContentHeader.propTypes = {
	panel: PropTypes.string,
	osdDrawMode: PropTypes.bool,
	isMobile: PropTypes.bool,
};

/** 
 * Page visible during reading or transcribing a page
 * 
 * @component
 */
const Viewer = () => {
	const [ osdDrawMode, setDrawMode ] = useState(false);
	const [ osdMethods, setOsdMethods ] = useState({});
	const [ zoom, setZoom ] = useState(0);
	const [ homeZoom, setHomeZoom ] = useState(0);
	const [ dataLoaded, setDataLoaded ] = useState(false);
	const [ anyVerseActive, setAnyVerseActive ] = useState(false);

	const [ transcriptionProcessingStatus, setTranscriptionProcessingStatus ] = useState(false);
	const [ transcriptionProcessingStatusPrevious, setTranscriptionProcessingStatusPrevious ] = useState('');
	const [ transcriptionProcessingStatusRequest, setTranscriptionProcessingStatusRequest ] = useState(false);

	const dispatch = useDispatch();

	const { transcriptionId, pageId } = useParams();

	const { t } = useTranslation();

	const isLoggedIn = useSelector((state) => state.auth.credentials.isLoggedIn);
	const authToken = useSelector((state) => state.auth.credentials.token);
	const charMap = useSelector((state) => state.viewer.charMap);
	const noAccess = useSelector((state) => state.viewer.noAccess);
	
	const togglePanels = useSelector((state) => state.app.viewer.togglePanels);
	const swapPanels = useSelector((state) => state.app.viewer.swapPanels);
	const mobilePanels = useSelector((state) => state.app.viewer.mobilePanels);

	const isMobile = useSelector((state) => state.viewer.isMobile);
	const verseActive = useSelector((state) => state.viewer.editor.verseActive);
	const verseEdit = useSelector((state) => state.viewer.editor.verseEdit);
	const modalActive = useSelector((state) => state.modal.show);

	const viewMode = useSelector((state) => state.viewer.data.viewMode);
	const student = viewMode === 'student';

	const { modal } = useModal();
	const modalResize = useShowModal({
		title:t('modals.viewerResize.title'),
		bodyHTML:t('modals.viewerResize.body'),
		type: 'viewer',
		name: 'viewerResize',
		withFooter: true,
		actionButton:t('modals.viewerResize.button'),
	});

	useEffect(() => {
		setAnyVerseActive(!!verseActive);
		
	}, [ verseActive ]);

	const { handleOnClose } = useHideModal();

	const handleResize = () => {
		const mobile = window.innerWidth < 1200;

		if (mobile) {
			dispatch(viewerActions.setIsMobile(true));
		} else {
			dispatch(viewerActions.setIsMobile(false));
		}
	};

	useEffect(() => {
		if (isMobile) {
			modalResize();
		}
		if (!isMobile && modal['name'] === 'viewerResize') {
			handleOnClose();
		}
	}, [ isMobile ]);

	useEffect(() => {
		dispatch(viewerActions.getTranscriptionPagesData(parseInt(transcriptionId)));
	}, [true]);

	useEffect(() => {
		if (!dataLoaded && authToken) {
			dispatch(viewerActions.loadData(parseInt(transcriptionId), parseInt(pageId)));
			setDataLoaded(true);
		}
	}, [ isLoggedIn, pageId, transcriptionId, dispatch ]);

	useEffect(() => {
		if (!authToken && !dataLoaded) {
			dispatch(viewerActions.loadData(parseInt(transcriptionId), parseInt(pageId)));
			setDataLoaded(true);
		}
	}, [ authToken, dispatch ]);

	useEffect(() => {

		let mounted = true;

		if (transcriptionProcessingStatusRequest) {
			if (transcriptionProcessingStatus) {
				dispatch(viewerActions.isProcessing(true));

				setTimeout(() => {
					if (mounted) getTranscriptionProcessingStatus(transcriptionId, setTranscriptionProcessingStatus, setTranscriptionProcessingStatusRequest);
				}, 5 * 1000);
			} else {
				dispatch(viewerActions.isProcessing(false));
			}

		}

		return () => {
			mounted = false;
		};

	}, [ transcriptionProcessingStatusRequest ]);

	useEffect(() => {
		if (transcriptionProcessingStatusPrevious?.status === 'PROCESSING' && transcriptionProcessingStatus?.status !== 'PROCESSING') {
			dispatch(viewerActions.loadData(parseInt(transcriptionId), parseInt(pageId)));
			dispatch(viewerActions.isProcessing(false));
		}

		setTranscriptionProcessingStatusPrevious(transcriptionProcessingStatus);

	}, [ transcriptionProcessingStatus ]);

	useEffect(() => {
		if (charMap.length < 1) {
			dispatch(viewerActions.loadCharMap());
		}
		handleResize();
		window.addEventListener('resize', handleResize);

		getTranscriptionProcessingStatus(transcriptionId, setTranscriptionProcessingStatus, setTranscriptionProcessingStatusRequest);

		return () => {
			// NIE USUWAĆ - to jeszcze wymaga dopracowania
			// dispatch(viewerActions.setVerseActive(null));
			dispatch(viewerActions.isLoaded(false));
			// dispatch(viewerActions.clearTranscriptionData());
			window.removeEventListener('resize', handleResize);
		};

	}, []);

	useEffect(() => {
		let currentUrlParams = new URLSearchParams(window.location.search);
		const activeVerseId = currentUrlParams.get("verse");
		if (activeVerseId) {
			dispatch(viewerActions.setVerseActive(+activeVerseId));
		}
	}, []);

	const onToggleDrawMode = useCallback(() => {
		if (osdDrawMode) {
			setDrawMode(false);
		} else {
			setDrawMode(true);
		}
	}, [ dispatch, osdDrawMode ]);

	const removeSelectedArea = () => {
		if (verseActive && !verseEdit && !modalActive) {
			modalDeleteVerse();
		}
	};

	const modalDeleteVerse = useShowModal({
		title:t('modals.deleteVerse.title'),
		type: 'viewer',
		name: 'deleteVerse',
		componentPath: './parts/ModalDeleteVerse',
		componentProps: { transcriptionId: parseInt(transcriptionId), pageId: parseInt(pageId) },
	});
	
	return (!isLoggedIn || !noAccess) ? (
		<main className={ `viewer ${swapPanels ? 'viewer--toggle' : ''}` }>
			{transcriptionProcessingStatus?.status === 'PROCESSING' && (
				<div className='viewer__alert'>
					<div className='viewer__alert--box'>
						{ t('transcription.alert.processing' + transcriptionProcessingStatus.details) }
					</div>
				</div>
			)}

			<div className={ `viewer__panel viewer__panel--osd ${(togglePanels.osd) ? 'viewer__panel--fold' : ''} ${(mobilePanels === 'osd') ? 'viewer__panel--mobile' : ''} ` }>
				<aside className='viewer__aside'>
					<div className='viewer__aside--container'>
						<div
							className='viewer__fold'
							data-tip={ (!togglePanels.osd) ?t('viewer.tooltip.header.collapseObject') :t('viewer.tooltip.header.expandObject') }
							data-place={ (swapPanels && togglePanels.osd) ? 'left' : 'right' }
						>
							<ViewerTogglePanel type='osd' />
						</div>
						<div className='viewer__menu'>
							<ViewerOSDMenu
								onToggleDrawMode={ onToggleDrawMode }
								removeSelectedArea={ removeSelectedArea }
								drawMode={ osdDrawMode }
								osdMethods={ osdMethods }
								zoom={ zoom }
								homeZoom={ homeZoom }
							/>
						</div>
					</div>
				</aside>
				<article className='viewer__content'>
					<div className='viewer__content--header'>
						<ViewerContentHeader
							panel='osd'
							osdDrawMode={ osdDrawMode }
							isMobile={ isMobile }
						/>
					</div>
					<div className='viewer__content--inner'>
						<ViewerOSD
							onToggleDrawMode={ onToggleDrawMode }
							osdDrawMode={ osdDrawMode }
							setOsdMethods={ setOsdMethods }
							setZoom={ setZoom }
							setHomeZoom={ setHomeZoom }
						/>
					</div>
				</article>
			</div>
			<div className={ `viewer__panel viewer__panel--editor ${togglePanels.editor ? 'viewer__panel--fold' : ''} ${(mobilePanels === 'editor' || mobilePanels === 'student') ? 'viewer__panel--mobile' : ''} ` }>
				<aside className='viewer__aside'>
					<div className='viewer__aside--container'>
						<div
							className='viewer__fold'
							data-tip={ (!togglePanels.editor) ?t('viewer.tooltip.header.collapseTranscription') :t('viewer.tooltip.header.expandTranscription') }
							data-place={ (!swapPanels && togglePanels.editor) ? 'left' : 'right' }
						>
							<ViewerTogglePanel type='editor' />
						</div>
						<div className='viewer__menu'>
							{(CheckViewModePermission("canSeeEditorMenu", viewMode) || (student && mobilePanels === 'student')) &&
							<ViewerEditorMenu anyVerseActive={ anyVerseActive } />}
						</div>
					</div>
				</aside>
				<article className='viewer__content'>
					<div className='viewer__content--header'>
						<ViewerContentHeader panel='editor' />
					</div>
					<div className='viewer__content--inner'>
						<ViewerEditor />
					</div>
				</article>
			</div>
		</main>
	) : (
		<Error403 />
	);

};

export default Viewer;