import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router";

import { useShowModal } from "@Elements/Modal/ModalHooks";

import Button from "@Elements/Button/Button";

/** 
 * Component containing icons for all index types opening modals with their lists
 * 
 * @component
 */
const VerseIndex = ({ verse }) => {
	const { t } = useTranslation();

	const { transcriptionId } = useParams();

	const showModalListOfIndexTerms = useShowModal({
		title:t('modals.listOfIndex.title.terms') + " (" + verse.indexes.terms + ")",
		type: "viewer modal-index",
		name: "ModalListOfIndex",
		componentPath: "./parts/ModalListOfIndex",
		componentProps: { transcriptionId: transcriptionId, verseId: parseInt(verse.id), type: 'term' },
		withClose: true
	});

	const showModalListOfIndexPlaces = useShowModal({
		title:t('modals.listOfIndex.title.places') + " (" + verse.indexes.places + ")",
		type: "viewer modal-index",
		name: "ModalListOfIndex",
		componentPath: "./parts/ModalListOfIndex",
		componentProps: { transcriptionId: transcriptionId, verseId: parseInt(verse.id), type: 'place' },
		withClose: true
	});

	const showModalListOfIndexPersons = useShowModal({
		title:t('modals.listOfIndex.title.persons') + " (" + verse.indexes.persons + ")",
		type: "viewer modal-index",
		name: "ModalListOfIndex",
		componentPath: "./parts/ModalListOfIndex",
		componentProps: { transcriptionId: transcriptionId, verseId: parseInt(verse.id), type: 'person' },
		withClose: true
	});
	
	return (
		<>
			<div className="verse__comments">
				{parseInt(verse.indexes.terms) > 0 && (
					<Button
						className="verse__comments--button"
						variant="verseinfo"
						onClick={() => showModalListOfIndexTerms()}
					>
						<i className='icon-index-term'></i>
						<span className="verse__comments--count">{verse.indexes.terms}</span>
					</Button>
				)}
			</div>
			<div className="verse__comments">
				{parseInt(verse.indexes.places) > 0 && (
					<Button
						className="verse__comments--button"
						variant="verseinfo"
						onClick={() => showModalListOfIndexPlaces()}
					>
						<i className='icon-index-place'></i>
						<span className="verse__comments--count">{verse.indexes.places}</span>
					</Button>
				)}
			</div>
			<div className="verse__comments">
				{parseInt(verse.indexes.persons) > 0 && (
					<Button
						className="verse__comments--button"
						variant="verseinfo"
						onClick={() => showModalListOfIndexPersons()}
					>
						<i className='icon-index-person'></i>
						<span className="verse__comments--count">{verse.indexes.persons}</span>
					</Button>
				)}
			</div>
		</>
	);
};

VerseIndex.propTypes = {
	verse: PropTypes.object
};

export default VerseIndex;
