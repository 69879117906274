const appInitialState = {
	langs: [
		{
			id: 'pl',
			name: 'Polski',
		},
		{
			id: 'en',
			name: 'English',
		},
		{
			id: 'de',
			name: 'Deutsch',
		},
	],
	currentLang: 'pl',
	isContrast: false,
	isMourning: false,
	isLoading: false,
	isError: false,
	isNotFound: false,
	fontSize: 'font-normal',
	cookiePermission: false,
	dict: {},
	viewer: {
		togglePanels: {
			osd: false,
			editor: false,
		},
		swapPanels: false,
		mobilePanels: 'osd',
	},
	OCRProfiles: [],
	modalConfirmInfo: "",
	modalDeleteTranscriptionPage: null,
	modalCommentDeleteCommentId: null,
	importedTranscription: null,
	questionnaireLink: null,
	selectedIndexId: null,
	firstPageId: null,
	config: {
		reactAppApi: null,
		ariadnaWwwUrl: null,
		ariadnaWwwMySubjectsUrl: null,
		ariadnaWwwMyCollectionsUrl: null,
		ariadnaUserManagement: null,
		ariadnaEduUrl: null,
		ariadnaEduMyProjectsUrl: null,
		ariadnaWolUrl: null,
		ariadnaWolMyProjectsUrl: null,
		ariadnaDictionary: null,
		dictionary: null,
    	contact: null,
		privacyPolicy: null,
		accessibilityStatement: null,
		regulations: null,
		ariadnaWwwParticipants: null,
		ariadnaWwwProjectInfo: null,
		ariadnaWwwFinancialInfo: null,
	}
};

export default appInitialState;