import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import parse from "html-react-parser"

import styles from "@Pages/Transcription/TranscriptionDetails/TranscriptionDetails.module.sass";

import IIIF from '@Assets/icons/svg/IIIF.svg';

const importFormats = ['mets','iiif','tei'];

const TranscriptionDetailsMetadataField = ({metadata, viewMode, hidden}) => {
	const { t } = useTranslation();

	const api = useSelector((state) => state.app.config.reactAppApi);
	const manifest = !hidden && metadata != null ? `${api}/iiif/transcriptions/${metadata.id}/manifest.json`: "";
	let layerManifests = [];
	if(!hidden && metadata != null){
		for(let layer in metadata.layers){
			layerManifests.push({"name": metadata.layers[layer].name, "link": `${api}/iiif/transcriptions/${metadata.id}/manifest.json?layerId=${metadata.layers[layer].id}`});
		}
	}
	const description = metadata.transcriptionInfo.description;
	const documentSrc = metadata.transcriptionInfo.documentSrc;
	const documentOrigin = metadata.transcriptionInfo.imgSrc;
	const guidelines = metadata.transcriptionInfo.guidelines;
	let creationMode = '';
	if (importFormats.includes(metadata.creationMode)){
		creationMode = metadata.creationMode.toString().toUpperCase();
	}
	return (
		<section>
			{ metadata && metadata.analyticInfo.title &&
				<div className={ styles["transcription__meta--details-wrapper"] }>
					<div className={ styles["transcription__meta--details-wrapper--title"] }>
						{t('transcription.metadata.fieldTitle.creator') }:
					</div>
					<div>
						<a href={`/transcriptions?order=last-modification-date&q=${metadata.creator.email}&page=1&type=ALL`}>{metadata.creator.email}</a>
					</div>
				</div>}
			{ metadata && metadata.transcriptionInfo.contactName &&
				<div className={ styles["transcription__meta--details-wrapper"] }>
					<div className={ styles["transcription__meta--details-wrapper--title"] }>
						{t('transcription.metadata.fieldTitle.contactName') }:
					</div>
					<div>
						{metadata.transcriptionInfo.contactName}
					</div>
				</div>}
			{ ( metadata.transcriptionInfo.description) &&
			<div className={ styles["transcription__meta--details-wrapper"] }>
				<div className={ styles["transcription__meta--details-wrapper--title"] }>
					{t('transcription.metadata.fieldTitle.projectDescription') }:
				</div>
				<div className={ styles["transcription__meta--details-wrapper--break"] }></div>
				<div className={ styles["transcription__meta--details-wrapper--rich"] }>
					{parse(description)}
				</div>
			</div>}
			{ metadata.profileDesc.keywords.length > 0 &&
			<div className={ styles["transcription__meta--details-wrapper"] }>
				<div className={ styles["transcription__meta--details-wrapper--title"] }>
					{t('transcription.metadata.fieldTitle.tags') }:
				</div>
				<div>
					{metadata.profileDesc.keywords.map((item, index) => (
						<span key={index}><a href={`/transcriptions?order=last-modification-date&q=${item}&page=1&type=ALL`}>{item}</a>; </span>
					))}
				</div>
			</div>}
			{ metadata && metadata.transcriptionInfo.collectionName &&
				<div className={ styles["transcription__meta--details-wrapper"] }>
					<div className={ styles["transcription__meta--details-wrapper--title"] }>
						{t('transcription.metadata.fieldTitle.collection') }:
					</div>
					<div>
						<a href={`/transcriptions?order=last-modification-date&q=${metadata.transcriptionInfo.collectionName}&page=1&type=ALL`}>{metadata.transcriptionInfo.collectionName}</a>
					</div>
				</div>}
			{manifest &&
			<div className={ styles["transcription__meta--details-wrapper"] }>
				<div className={ styles["transcription__meta--details-wrapper--title"]} >
					<img src={IIIF} alt={"IIIF logo"} className={styles["manifest__image"]} />
					{t('transcription.metadata.fieldTitle.manifest') }
				</div>
				<div>
					<span 
						className={ styles["transcription__meta--details-wrapper--click-to-copy"] }
						onClick={ ()=>{navigator.clipboard.writeText(manifest)} }
					>
						{t('transcription.metadata.fieldTitle.copyLink') }
					</span>
				</div>
			</div>}
			{layerManifests.map((el) => {
				return <div className={ styles["transcription__meta--details-wrapper"] }>
					<div style={{"width":"28px"}}></div>
					<div className={ styles["transcription__meta--details-wrapper--title"]} >
						{ el.name }: 
					</div>
					<div>
						<span 
							className={ styles["transcription__meta--details-wrapper--click-to-copy"] }
							onClick={ ()=>{navigator.clipboard.writeText(el.link)} }
						>
							{t('transcription.metadata.fieldTitle.copyLink') }
						</span>
					</div>
				</div>
			})}
		</section>
	);
};

TranscriptionDetailsMetadataField.propTypes = {
	metadata: PropTypes.object,
	viewMode: PropTypes.string
};

export default TranscriptionDetailsMetadataField;