import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { useShowModal } from '@Elements/Modal/ModalHooks';

import dLibraLogo from '@Assets/images/dLibra.svg';

const TranscriptionDetailsDLibraStatus = ({transcriptionId, viewMode, status, isPublic}) => {
	const { t } = useTranslation();

	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);

    ReactTooltip.rebuild();

    const modalSendToDLibra = useShowModal({
		title:t('modals.sendToDLibra.title'),
		name: "sendToDLibra",
		componentPath: "./parts/ModalSendToDLibra",
		componentProps: {transcriptionId: parseInt(transcriptionId)}
	});

	return (
		<div>
			{(isLoggedIn) && status && status !== "undefined" &&
				<div>
                    <p style={{marginBottom: "10px"}}>
                        <img src={dLibraLogo}></img> 
                        <span style={{marginLeft: "10px"}}>{t("transcription.details.dLibra.importedFromDLibra")}</span>
                    </p>
                    <p> {t("transcription.details.dLibra.status")}:
                        <span style={{marginLeft: "10px", marginRight: "10px"}}>
                            { 
                                status === "new" ? t("transcription.details.dLibra.projentNotSent") :
                                status === "ready" ? t("transcription.details.dLibra.projectBeingVerified") :
                                status === "rejected" ? <span style={{color: "#AC4848"}}>{t("transcription.details.dLibra.projectRejected")}</span> :
                                status === "accepted" ? <span style={{color: "#0A585D"}}><i className='icon-accept'/>{t("transcription.details.dLibra.projectAccepted")}</span> : 
                                status === "sent" ? <span style={{color: "#0A585D"}}><i className='icon-accept'/>{t("transcription.details.dLibra.projectAccepted")}</span> : 
                                status === "error" ? <span style={{color: "#AC4848"}}>{t("transcription.details.dLibra.projectError")}</span> : ""
                            }
                        </span>
                        {viewMode === "admin" && 
                            <>{
                                status === "new" ? (<>
                                    <a href="#" disabled={!isPublic} style={isPublic ? {} : {color: "#606060"}} onClick={modalSendToDLibra}>{t("transcription.details.dLibra.sendProject")}</a>
                                    <i 
                                        className='icon-question-reversed'
                                        data-tip={ t("transcription.details.dLibra.sendProjectTooltip") }
                                        data-place='right'
                                    >
                                        <span className='d-none'>{t("transcription.details.dLibra.sendProjectTooltip")}</span>
                                    </i>
                                </>) :
                                status === "rejected" ? (<>
                                    <i 
                                        className='icon-question-reversed'
                                        data-tip={ t("transcription.details.dLibra.sendProjectRejectedTooltip") }
                                        data-place='right'
                                    >
                                        <span className='d-none'>{t("transcription.details.dLibra.sendProjectRejectedTooltip")}</span>
                                    </i>
                                    <a href="#" disabled={!isPublic} style={isPublic ? {marginLeft: "10px"} : {marginLeft: "10px", color: "#606060"}} onClick={modalSendToDLibra}>{t("transcription.details.dLibra.sendProjectAgain")}</a>
                                </>) :
                                status === "error" ? (<>
                                    <a href="#" disabled={!isPublic} style={isPublic ? {marginLeft: "10px"} : {marginLeft: "10px", color: "#606060"}} onClick={modalSendToDLibra}>{t("transcription.details.dLibra.sendProjectAgain")}</a>
                                </>) : ""
                            }</>
                        }
                    </p>
				</div>}
		</div>
	);
};

export default TranscriptionDetailsDLibraStatus;
