import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from "@Pages/Home/parts/Modules/Modules.module.sass";
import "@Pages/Home/parts/Modules/carousel.sass";

const Modules = () => {
	const { t } = useTranslation();

	const [isMobile, setIsMobile] = useState(window.innerWidth > 991.98);

	const updateMedia = () => {
		setIsMobile(window.innerWidth > 991.98);
	};
  
	useEffect(() => {
	  window.addEventListener("resize", updateMedia);
	  return () => window.removeEventListener("resize", updateMedia);
	});

	const url_1 = useSelector((state) => state.app.config.ariadnaWwwUrl);
	const url_2 = useSelector((state) => state.app.config.ariadnaEduUrl);
	const url_3 = useSelector((state) => state.app.config.ariadnaWolUrl);

	const sliderSettings = {
		arrows: true,
		centerMode: true,
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<div className={ `${styles['modules']} `}>
			<Container>
				<h2 className={ styles['modules__text--header']}>
					{t('modules.title')}
				</h2>
				<p className={styles['modules__text--description']}>{t('modules.description')}</p>
			</Container>
			{ isMobile ? <Slider
				className={ styles['modules__slider'] }
				{...sliderSettings}
			>
				<div className={ styles['modules__tile'] }>
					<div className={`${styles['modules__tile--content']} ${styles['modules__tile--content_1']}`}>
						<h3 className={ styles['modules__text--title']}>{t('modules.title1')}</h3>
						<hr className={ styles['modules__hr']} />
						<p className={ styles['modules__text--content']}>{t('modules.content1')}</p>
						<div className={ styles['modules__more']}>
							<a
								href={url_1}
								target='_blank'
								className={ styles['modules__text--more']}
							>{t('modules.link')}
							</a>
						</div>
					</div>
				</div>
				<div className={ styles['modules__tile'] }>
					<div className={`${styles['modules__tile--content']} ${styles['modules__tile--content_2']}`}>
						<h3 className={ styles['modules__text--title']}>{t('modules.title2')}</h3>
						<hr className={ styles['modules__hr']} />
						<p className={ styles['modules__text--content']}>{t('modules.content2')}</p>
						<div className={ styles['modules__more']}>
							<a
								href={url_2}
								target='_blank'
								className={ styles['modules__text--more']}
							>{t('modules.link')}
							</a>
						</div>
					</div>
				</div>
				<div className={ styles['modules__tile'] }>
					<div className={`${styles['modules__tile--content']} ${styles['modules__tile--content_3']}`}>
						<h3 className={ styles['modules__text--title']}>{t('modules.title3')}</h3>
						<hr className={ styles['modules__hr']} />
						<p className={ styles['modules__text--content']}>{t('modules.content3')}</p>
						<div className={ styles['modules__more']}>
							<a
								href={url_3}
								target='_blank'
								className={ styles['modules__text--more']}
							>{t('modules.link')}
							</a>
						</div>
					</div>
				</div>
			</Slider>
			:
				<div className="container">
					<div className={ styles['modules__tile'] }>
						<div className={`${styles['modules__tile--content']} ${styles['modules__tile--content_1']}`}>
							<h3 className={ styles['modules__text--title']}>{t('modules.title1')}</h3>
							<hr className={ styles['modules__hr']} />
							<p className={ styles['modules__text--content']}>{t('modules.content1')}</p>
							<div className={ styles['modules__more']}>
								<a
									href={url_1}
									target='_blank'
									className={ styles['modules__text--more']}
								>{t('modules.link')}
								</a>
							</div>
						</div>
					</div>
					<div className={ styles['modules__tile'] }>
						<div className={`${styles['modules__tile--content']} ${styles['modules__tile--content_2']}`}>
							<h3 className={ styles['modules__text--title']}>{t('modules.title2')}</h3>
							<hr className={ styles['modules__hr']} />
							<p className={ styles['modules__text--content']}>{t('modules.content2')}</p>
							<div className={ styles['modules__more']}>
								<a
									href={url_2}
									target='_blank'
									className={ styles['modules__text--more']}
								>{t('modules.link')}
								</a>
							</div>
						</div>
					</div>
					<div className={ styles['modules__tile'] }>
						<div className={`${styles['modules__tile--content']} ${styles['modules__tile--content_3']}`}>
							<h3 className={ styles['modules__text--title']}>{t('modules.title3')}</h3>
							<hr className={ styles['modules__hr']} />
							<p className={ styles['modules__text--content']}>{t('modules.content3')}</p>
							<div className={ styles['modules__more']}>
								<a
									href={url_3}
									target='_blank'
									className={ styles['modules__text--more']}
								>{t('modules.link')}
								</a>
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	);
};

export default Modules;