import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useHistory } from "react-router-dom";
import { reverse } from 'named-urls';
import { Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router';

import { appActions } from '@/store/actions/app.actions';
import { viewerActions } from '@/store/actions/viewer.actions';

import { useShowModal } from '@Elements/Modal/ModalHooks';

import ViewerLayersChooser from "@Pages/Viewer/parts/ViewerLayers/ViewerLayersChooser";

import url from "@/router/urls";
import api from "@/services/api";

import updateUrl from "@/utils/viewerHelper";

import LinkButton from "@Elements/Button/LinkButton";
import Spinner from "@Elements/Spinner/Spinner";
import AuthHeader from "@Templates/header/AuthHeader/AuthHeader";

import wlt_logo from '@Assets/images/ariadna_logo.2.svg';

/** 
 * Header used in viewer
 * 
 * @component
 */
const ViewerHeader = () => {
	const viewerData = useSelector((state) => state.viewer.data);
	const { t } = useTranslation();

	const isMobile = useSelector((state) => state.viewer.isMobile);
	const swapPanels = useSelector((state) => state.app.viewer.swapPanels);
	const isProcessing = useSelector((state) => state.viewer.isProcessing);
	const transcriptionPagesData = useSelector((state) => state.viewer.transcriptionPagesData);
	const searchResults = useSelector((state) => state.viewer.searchResults);
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const { transcriptionId, pageId } = useParams();

	const [ layers, setLayers ] = useState();

	const viewMode = viewerData.viewMode;
	const readOnly = viewMode === 'read-only' || viewMode === 'student';
	const noAccess = useSelector( (state) => state.viewer.noAccess );

	const [ activeTranscribed, setActiveTranscribed ] = useState(false);
	const [ activeVerified, setActiveVerified ] = useState(false);
	const [ anyVerseVerified, setAnyVerseVerified ] = useState(false);
	const [ isMenuShown, setMenuShown ] = useState(false);

	const [ pageNumber, setPageNumber ] = useState("");
	const [ currentPageNumber, setCurrentPageNumber ] = useState("");

	const changeSwapPanels = () => {
		dispatch(appActions.setSwapPanels(!swapPanels));
	};

	const downloadFileFormat = (format) => {
		dispatch(viewerActions.getFileByFormat(viewerData.id, format));
	};

	const modalExportTextLayers = useShowModal({
		title:t('modals.viewerExportTextLayers.title'),
		type: 'viewer',
		name: 'viewerExportTextLayers',
		componentPath: './parts/ModalExportTextLayers',
		componentProps: {
			downloadFileFunction: downloadFileFormat,
		},
		withClose: true,
	});

	const modalImportText = useShowModal({
		title:t('modals.importTextToLayer.title'),
		type: 'viewer',
		name: 'modalImportTextToLayer',
		componentPath: './parts/ModalImportTextToLayer',
		componentProps: { transcriptionId: parseInt(transcriptionId), pageId: parseInt(pageId), layers: layers },
	});

	const modalAutoTranscribePage = useShowModal({
		title:t('modals.autoTranscribePage.title'),
		type: 'viewer',
		name: 'autoTranscribePage',
		componentPath: './parts/ModalAutoTranscribePage',
		componentProps: {
			transcriptionId: parseInt(transcriptionId),
			pageId: parseInt(pageId),
			anyVerseVerified: anyVerseVerified,
			viewMode: viewMode,
			layers: layers,
		},
	});

	useEffect(()=>{
		if(!readOnly) {
			api.get(`/transcriptions/${transcriptionId}/layer`)
				.then(response => {
					setLayers(response.data);
				});
		}
	}, [true]);

	useEffect(() => {
		const verses = [ ...viewerData.verses ];
		const versesTranscribed = verses.filter(item => ( item.status === 'transcribed' || item.status === 'verified' ));
		const versesVerified = verses.filter(item => item.status === 'verified');

		setActiveTranscribed(verses.length === versesTranscribed.length);
		setActiveVerified(verses.length === versesVerified.length);
		setAnyVerseVerified(!!versesVerified.length);
	}, [ viewerData.verses ]);

	useEffect(() => {
		if(viewerData.pageNo != currentPageNumber){
			setCurrentPageNumber(viewerData.pageNo);
			setPageNumber(viewerData.pageNo);
		}
	});

	return !noAccess ? (
		<>
			<Spinner
				stateFromStore
				viewer
			/>
			<header className='viewer-header'>
				<div className='viewer-header__main'>
					<div
						className='viewer-header__logo'
						data-tip={t('viewer.tooltip.header.homeBt') }
						data-place='right'
					>
						<Link
							to={ url.home }
							className='viewer-header__logo--href'
						>
							<img
								src={ wlt_logo }
								alt={t('headers.logoAlt') }
								className='viewer-header__logo--image'
							/>
						</Link>
					</div>
					{!isMobile && <div className='viewer-header__title'>
						<p className='viewer-header__text--title'>
							{viewerData.titleLabel + " [" + t('viewer.header.transcriptionPage') + " " + currentPageNumber + "]"}
						</p>
					</div>}
					{isMobile && <div className='viewer-header__preview'>
						<span>{ t('viewer.header.preview') }</span>
					</div>}
					<div className='viewer-header__how-to-start'>
						<Link
							to={url.howToTranscribe}
						>
							<span>{isMobile ? <i className={ `icon-question-reversed` } /> : t('viewer.header.howToStart') }</span>
						</Link>
					</div>
					<div className='viewer-header__info'>
						<LinkButton
							variant='icon'
							className='viewer-header__close'
							onClick={()=>{ dispatch(viewerActions.setSearchResults("", { results: { resources: [], hits: [] } })); }}
							to={ `${reverse(url.transcription.details, { transcriptionId: transcriptionId })}${location.state && location.state.paginationPage ? `?page=${location.state.paginationPage}` : ''}` }
						>
							<span className='d-none'>X</span>
							<i className='icon-close' />
						</LinkButton>
					</div>
				</div>
				{isMobile && <div className='viewer-header__main' style={{ borderTop: "solid 1px #626262" }}>
					<div className='viewer-header__title'>
						<p className='viewer-header__text--title'>
							{viewerData.titleLabel + " [" + t('viewer.header.transcriptionPage') + " " + currentPageNumber + "]"}
						</p>
					</div>
				</div>}
				{isMobile && <div className='viewer-header__nav'>
					<div className='viewer-header__nav--left viewer-header__nav--viewer-layers'>
						<ViewerLayersChooser/>
					</div>
				</div>}
				<nav className='viewer-header__nav'>
					<div className='viewer-header__nav--left'>
						<Dropdown
							className='viewer-header__dropdown'
							onToggle={(isShown) => { setMenuShown(isShown); }}
							show={isMenuShown}
						>
							<div
								className='viewer-btn__wrapper'
								data-place='bottom'
							>
								<Dropdown.Toggle
									className='viewer-header__menu-button'
								>
									{!isMenuShown && <i className='icon-hamburger' />}
									{isMenuShown && <i className='icon-close' />}
									{t('viewer.header.menu')}
								</Dropdown.Toggle>
							</div>

							<Dropdown.Menu>
								{!readOnly && <Dropdown.Item onClick={ () => modalAutoTranscribePage() }>{t('viewer.header.autoTranscribe')}</Dropdown.Item> }
								{!isMobile && <Dropdown.Item onClick={ changeSwapPanels }>{t('viewer.header.swapPanels')}</Dropdown.Item> }
								<Dropdown.Item onClick={ () => modalExportTextLayers() }>{t('viewer.header.download')}</Dropdown.Item>
								{!readOnly && <Dropdown.Item onClick={ () => modalImportText() }>{t('viewer.header.import')}</Dropdown.Item> }
							</Dropdown.Menu>
						</Dropdown>
					</div>
					{!isMobile && <div className='viewer-header__nav--left viewer-header__nav--viewer-layers'>
						<ViewerLayersChooser/>
					</div>}
					<div className='viewer-header__nav--right'>
						<div className='viewer-header__prev-page'>
							<span
								onClick={e => {
									if(viewerData.prevPageId){
										dispatch(viewerActions.loadData(parseInt(transcriptionId), parseInt(viewerData.prevPageId)));
										updateUrl(history, transcriptionId, viewerData.prevPageId, searchResults.searchedPhrase);
									}
								}}
							>
								{t('viewer.header.pages.prev') + " " +t('viewer.header.pages.page') }
							</span>
						</div>
						<div className='viewer-header__page-number'>
							<input
								type='number'
								value={pageNumber || ""}
								onChange={e => { setPageNumber(e.target.value); }}
								onKeyDown={e => {
									if(e.key === 'Enter'){
										if(transcriptionPagesData.pageToId.get(+pageNumber)){
											dispatch(viewerActions.loadData(parseInt(transcriptionId), parseInt(transcriptionPagesData.pageToId.get(+pageNumber))));
											updateUrl(history, transcriptionId, transcriptionPagesData.pageToId.get(+pageNumber), searchResults.searchedPhrase);
										}
									}
								}}
							/>
							<span> {t('viewer.header.pages.fromNumber') + " " + transcriptionPagesData.pageNum }</span>
						</div>
						<div className='viewer-header__next-page'>
							<span
								onClick={e => {
									if(viewerData.nextPageId){
										dispatch(viewerActions.loadData(parseInt(transcriptionId), parseInt(viewerData.nextPageId)));
										updateUrl(history, transcriptionId, viewerData.nextPageId, searchResults.searchedPhrase);
									}
								}}
							>
								{t('viewer.header.pages.next') + " " +t('viewer.header.pages.page') }
							</span>
						</div>
					</div>
				</nav>
			</header>
		</>
	) : (
		<AuthHeader />
	);
};

export default ViewerHeader;