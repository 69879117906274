import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useParams} from "react-router";

import Verse from "@Pages/Viewer/parts/Verse/Verse";

import { CheckViewModePermission } from "@/permissions/ViewModes";
import { useShowModal } from "@Elements/Modal/ModalHooks";

/** 
 * Part of viewer with text data, with possibility of editing verses and adding additional information to them such as indexes, formatting etc.
 * 
 * @component
 */
const ViewerEditor = () => {
	const viewerData = useSelector(state => state.viewer.data);
	const isMobile = useSelector((state) => state.viewer.isMobile);
	const pageLayers = useSelector(state => state.viewer.transcriptionLayersData);
	const viewMode = useSelector((state) => state.viewer.data.viewMode);
	const { t } = useTranslation();

	const selectedLayer = useSelector(state => state.viewer.selectedLayer);
	const { transcriptionId, pageId } = useParams();

	const mobilePanels = useSelector(state => state.app.viewer.mobilePanels);

	let verses = mobilePanels === "student" ? viewerData.studentVerses : viewerData.verses;

	const [ versesToUse, setVersesToUse ] = useState(verses)

	useEffect(() => {
		const shownLayers = []
		const availableLayers = []
		pageLayers.layers.map(layer => {
			availableLayers.push(parseInt(layer.layer.id))
		})

		pageLayers.layers.map(layerToWork => {
			if(layerToWork.isShown) {
				shownLayers.push(parseInt(layerToWork.layer.id));
			}
		});
		setVersesToUse(verses.filter(verse => {
			return verse.pageLayer && shownLayers.includes(parseInt(verse.pageLayer.layerId || '-1'));
		}))

	}, [verses, pageLayers]);

	const modalAcceptAllVerses = useShowModal({
		title:t('modals.acceptAllVerses.title'),
		type: "viewer",
		name: "acceptAllVerses",
		componentPath: "./parts/ModalAcceptAllVerses",
		componentProps: {transcriptionId: parseInt(transcriptionId), pageId: parseInt(pageId), layerId: parseInt(selectedLayer)}
	});

	const modalRejectAllVerses = useShowModal({
		title:t('modals.rejectAllVerses.title'),
		type: "viewer",
		name: "rejectAllVerses",
		componentPath: "./parts/ModalRejectAllVerses",
		componentProps: {transcriptionId: parseInt(transcriptionId), pageId: parseInt(pageId), layerId: parseInt(selectedLayer)}
	});

	return (
		<div className="viewer__verses">
			{ !isMobile && CheckViewModePermission("canCheckAllVerses", viewMode) && <div className="viewer__verses--all-verses-status">
				<button
					className='verse__checkbox verse__checkbox--active'
					onClick={()=>{ modalAcceptAllVerses(); }}
				>
					<div className='verse__checkbox--accept-double'>
						<i className='icon-accept' />
						<i className='icon-accept' />
					</div>
				</button>
				{t('viewer.editor.acceptAllVerses')}
				<button
					className='verse__checkbox verse__checkbox--active'
					onClick={()=>{ modalRejectAllVerses(); }}
				>
					<div className='verse__checkbox--decline'>
						<i className='icon-close' />
					</div>
				</button>
				{t('viewer.editor.rejectAllVerses')}
			</div> }
			<ul
				className={`viewer__verses--list ${versesToUse && versesToUse.length > 5 ? "viewer__verses--list-overflow" : ""}`}
			>
				{versesToUse && versesToUse.map(verse => {
					return (
						<Verse key={verse.id} verse={verse} />
					)
				})}
			</ul>
		</div>
	);
};

export default memo(ViewerEditor);
