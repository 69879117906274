import React from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import "@Styles/_static-page.sass";

import howToTranscribe1a from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe1a.png';
import howToTranscribe1b from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe1b.png';

import howToTranscribe2 from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe2.png';

import howToTranscribe3 from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe3.png';

import howToTranscribe4 from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe4.png';

import howToTranscribe5 from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe5.png';

import howToTranscribe6a from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe6a.png';
import howToTranscribe6b from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe6b.png';
import howToTranscribe6c from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe6c.png';
import howToTranscribe6d from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe6d.png';
import howToTranscribe6e from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe6e.png';
import howToTranscribe6f from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe6f.png';
import howToTranscribe6g from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe6g.png';
import howToTranscribe6h from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe6h.png';
import howToTranscribe6i from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe6i.png';
import howToTranscribe6j from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe6j.png';
import howToTranscribe6k from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe6k.png';
import howToTranscribe6l from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe6l.png';
import howToTranscribe6m from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe6m.png';
import howToTranscribe6n from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe6n.png';
import howToTranscribe6o from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe6o.png';
import howToTranscribe6p from '@Assets/images/transcriptionHelpPage/howToTranscribe/howToTranscribe6p.png';

const HowToTranscribe = () => {

	const currentLang = useSelector((state) => state.app.currentLang);
	const { t } = useTranslation();

	const imagesWorkWithFile = {
		howToTranscribe1a: { pl: howToTranscribe1a, en: howToTranscribe1a, de: howToTranscribe1a },
		howToTranscribe1b: { pl: howToTranscribe1b, en: howToTranscribe1b, de: howToTranscribe1b },
		howToTranscribe2: { pl: howToTranscribe2, en: howToTranscribe2, de: howToTranscribe2 },
		howToTranscribe3: { pl: howToTranscribe3, en: howToTranscribe3, de: howToTranscribe3 },
		howToTranscribe4: { pl: howToTranscribe4, en: howToTranscribe4, de: howToTranscribe4 },
		howToTranscribe5: { pl: howToTranscribe5, en: howToTranscribe5, de: howToTranscribe5 },
		howToTranscribe6a: { pl: howToTranscribe6a, en: howToTranscribe6a, de: howToTranscribe6a },
		howToTranscribe6b: { pl: howToTranscribe6b, en: howToTranscribe6b, de: howToTranscribe6b },
		howToTranscribe6c: { pl: howToTranscribe6c, en: howToTranscribe6c, de: howToTranscribe6c },
		howToTranscribe6d: { pl: howToTranscribe6d, en: howToTranscribe6d, de: howToTranscribe6d },
		howToTranscribe6e: { pl: howToTranscribe6e, en: howToTranscribe6e, de: howToTranscribe6e },
		howToTranscribe6f: { pl: howToTranscribe6f, en: howToTranscribe6f, de: howToTranscribe6f },
		howToTranscribe6g: { pl: howToTranscribe6g, en: howToTranscribe6g, de: howToTranscribe6g },
		howToTranscribe6h: { pl: howToTranscribe6h, en: howToTranscribe6h, de: howToTranscribe6h },
		howToTranscribe6i: { pl: howToTranscribe6i, en: howToTranscribe6i, de: howToTranscribe6i },
		howToTranscribe6j: { pl: howToTranscribe6j, en: howToTranscribe6j, de: howToTranscribe6j },
		howToTranscribe6k: { pl: howToTranscribe6k, en: howToTranscribe6k, de: howToTranscribe6k },
		howToTranscribe6l: { pl: howToTranscribe6l, en: howToTranscribe6l, de: howToTranscribe6l },
		howToTranscribe6m: { pl: howToTranscribe6m, en: howToTranscribe6m, de: howToTranscribe6m },
		howToTranscribe6n: { pl: howToTranscribe6n, en: howToTranscribe6n, de: howToTranscribe6n },
		howToTranscribe6o: { pl: howToTranscribe6o, en: howToTranscribe6o, de: howToTranscribe6o },
		howToTranscribe6p: { pl: howToTranscribe6p, en: howToTranscribe6p, de: howToTranscribe6p },
	};
	
	return (
		<div className='static-page__content--instruction'>
			<p className='static-page__content--instruction-desc'>
				{t('transcriptionHelpPage.howToTranscribe.desc')}
			</p>
			<ul className='descList'>
				<li><a href='#section1'>{t('transcriptionHelpPage.howToTranscribe.descPoint1')}</a></li>
				<li><a href='#section2'>{t('transcriptionHelpPage.howToTranscribe.descPoint2')}</a></li>
				<li><a href='#section3'>{t('transcriptionHelpPage.howToTranscribe.descPoint3')}</a></li>
			</ul>
			<a name='section1'>
				<section id='section1' style={{scrollMarginTop: "120px"}} className='static-page__content--section'>
					<h2>{t('transcriptionHelpPage.howToTranscribe.section.workWithFile')}</h2>
					<ol>
						<li>
							<h3 dangerouslySetInnerHTML={ { __html:t('transcriptionHelpPage.howToTranscribe.steps.step1title') } } />
							<p style={{marginBottom: 0}}>{t('transcriptionHelpPage.howToTranscribe.steps.step1desc')}</p>
							<ul className='descList' style={{paddingLeft: "15px"}}>
								<li><b>{t('transcriptionHelpPage.howToTranscribe.steps.step1extra_1p')}</b>{t('transcriptionHelpPage.howToTranscribe.steps.step1extra_1')}</li>
								<li><b>{t('transcriptionHelpPage.howToTranscribe.steps.step1extra_2p')}</b>{t('transcriptionHelpPage.howToTranscribe.steps.step1extra_2')}</li>
							</ul>
							<img
								src={ imagesWorkWithFile.howToTranscribe1a[currentLang] }
							/>
							<img
								src={ imagesWorkWithFile.howToTranscribe1b[currentLang] }
							/>
						</li>
						<li>
							<h3 dangerouslySetInnerHTML={ { __html:t('transcriptionHelpPage.howToTranscribe.steps.step2title') } } />
							<p>{t('transcriptionHelpPage.howToTranscribe.steps.step2desc')}</p>
							<img
								src={ imagesWorkWithFile.howToTranscribe2[currentLang] }
							/>
						</li>
						<li>
							<h3 dangerouslySetInnerHTML={ { __html:t('transcriptionHelpPage.howToTranscribe.steps.step3title') } } />
							<p>{t('transcriptionHelpPage.howToTranscribe.steps.step3desc')}</p>
							<p><b>{t('transcriptionHelpPage.howToTranscribe.steps.step3extra_1')}</b>{t('transcriptionHelpPage.howToTranscribe.steps.step3extra_2')}</p>
							<img
								src={ imagesWorkWithFile.howToTranscribe3[currentLang] }
							/>
						</li>
						<li>
							<h3 dangerouslySetInnerHTML={ { __html:t('transcriptionHelpPage.howToTranscribe.steps.step4title') } } />
							<p>{t('transcriptionHelpPage.howToTranscribe.steps.step4desc')}</p>
							<img
								src={ imagesWorkWithFile.howToTranscribe4[currentLang] }
							/>
						</li>
						<li>
							<h3 dangerouslySetInnerHTML={ { __html:t('transcriptionHelpPage.howToTranscribe.steps.step5title') } } />
							<p>{t('transcriptionHelpPage.howToTranscribe.steps.step5desc')}</p>
							<img
								src={ imagesWorkWithFile.howToTranscribe5[currentLang] }
							/>
						</li>
						<li>
							<h3 dangerouslySetInnerHTML={ { __html:t('transcriptionHelpPage.howToTranscribe.steps.step6title') } } />
							<p>{t('transcriptionHelpPage.howToTranscribe.steps.step6desc')}</p>
						</li>
					</ol>
				</section>
			</a>

			<a name='section2'>
				<section id='section2' style={{scrollMarginTop: "120px"}} className='static-page__content--section'>
					<h2>{t('transcriptionHelpPage.howToTranscribe.section.workOnWindow')}</h2>
					<img
						src={ imagesWorkWithFile.howToTranscribe6a[currentLang] }
					/>
					<img
						src={ imagesWorkWithFile.howToTranscribe6b[currentLang] }
					/>
					<img
						src={ imagesWorkWithFile.howToTranscribe6c[currentLang] }
					/>
					<img
						src={ imagesWorkWithFile.howToTranscribe6d[currentLang] }
					/>
					<img
						src={ imagesWorkWithFile.howToTranscribe6e[currentLang] }
					/>
				</section>
			</a>

			<a name='section3'>
				<section id='section3' style={{scrollMarginTop: "120px"}} className='static-page__content--section'>
					<h2>{t('transcriptionHelpPage.howToTranscribe.section.workWithWindow')}</h2>
					<p>{t('transcriptionHelpPage.howToTranscribe.section.workWithWindowDescription')}</p>
					<img
						src={ imagesWorkWithFile.howToTranscribe6f[currentLang] }
					/>
					<img
						src={ imagesWorkWithFile.howToTranscribe6g[currentLang] }
					/>
					<img
						src={ imagesWorkWithFile.howToTranscribe6h[currentLang] }
					/>
					<img
						src={ imagesWorkWithFile.howToTranscribe6i[currentLang] }
					/>
					<img
						src={ imagesWorkWithFile.howToTranscribe6j[currentLang] }
					/>
					<img
						src={ imagesWorkWithFile.howToTranscribe6k[currentLang] }
					/>
					<img
						src={ imagesWorkWithFile.howToTranscribe6l[currentLang] }
					/>
					<img
						src={ imagesWorkWithFile.howToTranscribe6m[currentLang] }
					/>
					<img
						src={ imagesWorkWithFile.howToTranscribe6n[currentLang] }
					/>
					<img
						src={ imagesWorkWithFile.howToTranscribe6o[currentLang] }
					/>
					<img
						src={ imagesWorkWithFile.howToTranscribe6p[currentLang] }
					/>
				</section>
			</a>
		</div>
	);
};

export default HowToTranscribe;
  