import React from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container } from "react-bootstrap";

import store from '@/store/store';
import url from "@/router/urls";

import { CheckSecurityGroupPermission } from "@/permissions/SecurityGroups";

import LinkButton from "@Elements/Button/LinkButton";
import { useShowModal } from '@Elements/Modal/ModalHooks';

import styles from "@Pages/Home/parts/MainDescription/MainDescription.module.sass";

const MainDescription = () => {
	const { t } = useTranslation();

	const formsAutorization = useSelector((state) => state.app.config.authorization == "FORMS");
	const authorizationRegisterUrl = useSelector((state) => state.app.config.reactAppApi + state.app.config.authorizationRegisterUrl);

	const securityGroup = useSelector((state) => state.auth.credentials.securityGroup);

	const showPublicAccountInfo = useShowModal({
		title:t('modals.publicAccountInfo.title'),
		name: 'publicAccountInfo',
		componentPath: './parts/ModalPublicAccountInfo',
		componentProps: {securityGroup: securityGroup},
        withClose: true,
	});

	return (
		<>
			<section className={ styles["main"] }>
				<div className={styles["main__container"]}>
					<div className={styles["main__item--first"]}>
						<div className={styles["main__description--header"]}>
							<h1 className={styles["main__description--title"]}>{t('headers.title')}</h1>
						</div>
						<div className={ styles["main__description"] }>
							<p className={styles["main__description--text"]}>{t('headers.description')}</p>
							<hr />
							<Link
								className={styles["main__description--link"]}
								to={url.howToTranscribe}
							>
								{t('headers.help')}
							</Link>
						</div>
						<div className={styles["main__description--buttons"]}>
							{CheckSecurityGroupPermission("canCreateTranscription", securityGroup) ? <>
								<Link className={`${styles["main__description--upper-button"]} btn`} to={{pathname: url.transcription.new, state: { isProtected: true } }}>
									{t('headers.menu.newProject') }
								</Link>
							</> : <>
								<a className={`${styles["main__description--upper-button"]} btn`} onClick={ () => showPublicAccountInfo() }>
									{t('headers.menu.newProject') }
								</a>
							</>}
						</div>

					</div>
					<div className={styles["main__item--second"]}>
						<div className={styles["main__description--header"]} aria-hidden="true">
							<div style={{lineHeight: '1.2'}} className={styles["main__description--title"]}>&nbsp;</div>
						</div>
						<div className={styles["main__description"]}>
							<div className={styles["main__description--text-header"]}>
								<h1 className={styles["main__description--text-title"]}>{t('headers.project')}</h1>
							</div>
							<p className={styles["main__description--text"]}>{t('headers.description2')}</p>
							<a
								className={styles["main__description--link"]}
								href={store.getState().app.config.ariadnaWwwUrl}
								target='_blank'
								rel='noopener noreferrer'
							>
								{t('headers.look')}
							</a>
						</div>
					</div>

				</div>

			</section>

			<section className={ styles["description"] }>
				<Container>
					<p className={styles["description__header"]}>{t('headers.descriptions.title')}</p>

					<div className={styles["description__items"]}>
						<div className={styles["description__item"]}>
							<span>1</span>
							<p>
								<a href={formsAutorization ? url.auth.register : authorizationRegisterUrl + "?returnUrl=" + encodeURIComponent(window.location)}>
									{t('headers.menu.createAccount')}
								</a>
								{t('headers.descriptions.item1')}
							</p>
						</div>
						<div className={styles["description__item"]}>
							<span>2</span>
							<p>
								{t('headers.descriptions.item2_1')}
								{CheckSecurityGroupPermission("canCreateTranscription", securityGroup) ? <>
									<a href={url.transcription.new}>
										{t('headers.menu.newProject')}
									</a>
								</> : <>
									<a href='#' onClick={() => showPublicAccountInfo()}>
										{t('headers.menu.newProject')}
									</a>
								</>}
								{t('headers.descriptions.item2_2')}
							</p>
						</div>
						<div className={styles["description__item"]}><span>3</span><p>{t('headers.descriptions.item3')}</p></div>
						<div className={styles["description__item"]}><span>4</span><p>{t('headers.descriptions.item4')}</p></div>
						<div className={styles["description__item"]}><span>5</span><p>{t('headers.descriptions.item5')}</p></div>
						<div className={styles["description__item"]}><span>6</span><p>{t('headers.descriptions.item6')}</p></div>
					</div>
					<LinkButton
						variant='primary'
						className={styles["main__description--down-button"]}
						to={url.howToTranscribe}
					>
						{t('headers.help')}
					</LinkButton>
				</Container>
			</section>
		</>	);
};

export default MainDescription;
