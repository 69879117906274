import React from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import "@Styles/_static-page.sass";

import howToVerify1a from '@Assets/images/transcriptionHelpPage/howToVerify/howToVerify1a.png';
import howToVerify1b from '@Assets/images/transcriptionHelpPage/howToVerify/howToVerify1b.png';

import howToVerify2a from '@Assets/images/transcriptionHelpPage/howToVerify/howToVerify2a.png';
import howToVerify2b from '@Assets/images/transcriptionHelpPage/howToVerify/howToVerify2b.png';

import howToVerify3a from '@Assets/images/transcriptionHelpPage/howToVerify/howToVerify3a.png';
import howToVerify3b from '@Assets/images/transcriptionHelpPage/howToVerify/howToVerify3b.png';
import howToVerify3c from '@Assets/images/transcriptionHelpPage/howToVerify/howToVerify3c.png';

const HowToVerify = () => {

	const currentLang = useSelector((state) => state.app.currentLang);
	const { t } = useTranslation();

	const imagesHowToVerify = {
		howToVerify1a: { pl: howToVerify1a, en: howToVerify1a, de: howToVerify1a },
		howToVerify1b: { pl: howToVerify1b, en: howToVerify1b, de: howToVerify1b },
		howToVerify2a: { pl: howToVerify2a, en: howToVerify2a, de: howToVerify2a },
		howToVerify2b: { pl: howToVerify2b, en: howToVerify2b, de: howToVerify2b },
		howToVerify3a: { pl: howToVerify3a, en: howToVerify3a, de: howToVerify3a },
		howToVerify3b: { pl: howToVerify3b, en: howToVerify3b, de: howToVerify3b },
		howToVerify3c: { pl: howToVerify3c, en: howToVerify3c, de: howToVerify3c },
	};
	
	return (
		<div className='static-page__content--instruction'>
			<p className='static-page__content--instruction-desc'>
				{t('transcriptionHelpPage.howToVerify.desc')}
			</p>
			<ul className='descList'>
				<li><a href='#section1b'>{t('transcriptionHelpPage.howToVerify.desc_1')}</a></li>
				<li><a href='#section2b'>{t('transcriptionHelpPage.howToVerify.desc_2')}</a></li>
				<li><a href='#section3b'>{t('transcriptionHelpPage.howToVerify.desc_3')}</a></li>
			</ul>
			<a name='section1b'>
				<section id='section1b' style={{scrollMarginTop: "120px"}} className='static-page__content--section'>
					<h2>{t('transcriptionHelpPage.howToVerify.section.howToAssignRole')}</h2>
					<p>{t('transcriptionHelpPage.howToVerify.section.howToAssignRole_desc')}</p>
					<p>{t('transcriptionHelpPage.howToVerify.section.howToAssignRole_desc2')}</p>
					<img
						src={ imagesHowToVerify.howToVerify1a[currentLang] }
					/>
					<img
						src={ imagesHowToVerify.howToVerify1b[currentLang] }
					/>
				</section>
			</a>

			<a name='section2b'>
				<section id='section2b' style={{scrollMarginTop: "120px"}} className='static-page__content--section'>
					<h2>{t('transcriptionHelpPage.howToVerify.section.howToStart')}</h2>
					<p>{t('transcriptionHelpPage.howToVerify.section.howToStart_desc')}</p>
					<h2>{t('transcriptionHelpPage.howToVerify.section.howToStart_desc2')}</h2>
					<p>{t('transcriptionHelpPage.howToVerify.section.howToStart_desc3')}</p>
					<img
						src={ imagesHowToVerify.howToVerify2a[currentLang] }
					/>
					<img
						src={ imagesHowToVerify.howToVerify2b[currentLang] }
					/>
				</section>
			</a>

			<a name='section3b'>
				<section id='section3b' style={{scrollMarginTop: "120px"}} className='static-page__content--section'>
					<h2>{t('transcriptionHelpPage.howToVerify.section.verification')}</h2>
					<p>{t('transcriptionHelpPage.howToVerify.section.verificationDescription')}</p>
					<img
						src={ imagesHowToVerify.howToVerify3a[currentLang] }
					/>
					<img
						src={ imagesHowToVerify.howToVerify3b[currentLang] }
					/>
					<img
						src={ imagesHowToVerify.howToVerify3c[currentLang] }
					/>
				</section>
			</a>
            
		</div>
	);
};

export default HowToVerify;
  