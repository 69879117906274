import viewerInitialState from "@/store/initialState/viewer.initialState";

export function viewer(state = viewerInitialState, action) {
	switch (action.type) {
		case "VIEWER_ISLOADED":
			return {
				...state,
				isLoaded: action.value,
			};

		case "VIEWER_ISMOBILE":
			return {
				...state,
				isMobile: action.value,
			};

		case "VIEWER_ISPROCESSING":
			return {
				...state,
				isProcessing: action.value,
			};

		case "VIEWER_NOACCESS":
			return {
				...state,
				noAccess: action.value,
			};

		case "VIEWER_ISSAVING":
			return {
				...state,
				isSaving: action.value,
			};

		case "VIEWER_RECEIVE_DATA":
			return {
				...state,
				data: {
					...state.data,
					...action.data,
				},
			};

		case "VIEWER_RECEIVE_INFOJSON":
			return {
				...state,
				ocd: {
					...state.ocd,
					tileSources: {
						...state.ocd.tileSources,
						...action.data,
					},
				},
			};

		case "VIEWER_RECEIVE_CHARMAP":
			return {
				...state,
				charMap: action.data,
			};

		case "VIEWER_RECEIVE_VERSE_FORMATTING":
			return {
				...state,
				data: {
					...state.data,
					verses: state.data.verses.map(verse =>
						verse.id === action.verseId ? { ...verse, image: false, formatting: action.data } : verse,
					),
				},
			};

		case "VIEWER_SET_VERSE_ACTIVE":
			return {
				...state,
				editor: {
					...state.editor,
					verseActive: action.data,
				},
			};

		case "VIEWER_SET_VERSE_EDIT":
			return {
				...state,
				editor: {
					...state.editor,
					verseEdit: action.data,
				},
			};

		case "VIEWER_SET_SELECTION_EDIT":
			return {
				...state,
				ocd: {
					...state.ocd,
					selection: action.data,
				},
			};

		case "VIEWER_SET_STYLE_ACTIVE":
			return {
				...state,
				editor: {
					...state.editor,
					styleActive: action.data,
				},
			};

		case "VIEWER_SET_STYLE_CURRENT":
			return {
				...state,
				editor: {
					...state.editor,
					styleCurrent: action.data,
				},
			};

		case "VIEWER_SET_INSERT_CHARACTER":
			return {
				...state,
				editor: {
					...state.editor,
					addChar: action.data,
				},
			};

		case "VIEWER_SET_VERSE_CONTENT":
			return {
				...state,
				data: {
					...state.data,
					verses: state.data.verses.map(verse =>
						verse.id === action.verseId ? { ...verse, content: action.verseContent } : verse,
					),
				},
			};

		case "VIEWER_SET_VERSE_STUDENT_CONTENT":
			return {
				...state,
				data: {
					...state.data,
					studentVerses: state.data.studentVerses.map(verse =>
						verse.id === action.verseId ? { ...verse, content: action.verseContent } : verse,
					),
				},
			};

		case "VIEWER_SET_VERSE_STUDENT_STATUS":
			return {
				...state,
				data: {
					...state.data,
					studentVerses: state.data.studentVerses.map(verse =>
						verse.id === action.verseId ? { ...verse, learningStatus: action.verseStudentStatus } : verse,
					),
				},
			};

		case "VIEWER_UPDATE_VERSE_COMMENTS_COUNT":
			return {
				...state,
				data: {
					...state.data,
					verses: state.data.verses.map(verse =>
						verse.id === action.verseId ? { ...verse, comments: action.verseCommentsCount } : verse,
					),
				},
			};
		case "VIEWER_UPDATE_VERSE_SET_AS_INDEX_COUNT":
			const value = action.actionType === 'delete' ? -1 : 1;
			return {
				...state,
				data: {
					...state.data,
					verses: state.data.verses.map(verse =>
						verse.id === action.verseId ? {
							...verse,
							indexes:{
								terms: action.indexType === "term" ? ((verse.indexes.terms > 0) ? verse.indexes.terms + value : (action.actionType !== 'delete' ? verse.indexes.terms + value : 0)) : verse.indexes.terms,
								places: action.indexType === "place" ? ((verse.indexes.places > 0) ? verse.indexes.places + value : (action.actionType !== 'delete' ? verse.indexes.places + value : 0)) : verse.indexes.places,
								persons: action.indexType === "person" ? ((verse.indexes.persons > 0) ? verse.indexes.persons + value : (action.actionType !== 'delete' ? verse.indexes.persons + value : 0)) : verse.indexes.persons,
							} 
						} : verse, 
					),
				},
			};

		case "VIEWER_SET_VERSE_IMAGE":
			return {
				...state,
				data: {
					...state.data,
					verses: state.data.verses.map(verse =>
						verse.id === action.verseId ? { ...verse, image: true } : verse,
					),
				},
			};

		case "VIEWER_RELOAD_VERSES":
			return {
				...state,
				data: {
					...state.data,
					verses: action.verses,
				},
			};

		case "VIEWER_SET_VERSE_STATUS":
			return {
				...state,
				data: {
					...state.data,
					verses: state.data.verses.map(verse =>
						verse.id === action.verseId ? { ...verse, status: action.verseStatus } : verse,
					),
				},
			};

		case "VIEWER_SET_CORRECT_TRANSCRIPTION":
			return {
				...state,
				data: {
					...state.data,
					verses: state.data.verses.map(verse =>
						state.data.viewMode === "admin" || state.data.viewMode === "verify" ? {
							...verse,
							status: "verified",
						} : { ...verse, status: verse.status !== "verified" ? "transcribed" : verse.status },
					),
				},
			};

		case "VIEWER_SET_EMPTY_TRANSCRIPTION":
			return {
				...state,
				data: {
					...state.data,
					empty: true,
				},
			};

		case "CLEAR_TRANSCRIPTION_DATA":
			return {
				...state, data: {
					id: null,
					path: "",
					pageNo: null,
					boundingBox: {},
					verses: [],
					pageLayers: [],
					studentVerses: null,
					titleLabel: "",
					prevPageId: null,
					nextPageId: null,
					viewMode: "",
				},
			};

		case "VIEWER_CHANGE_WORKING_PAGE_LAYER":
			return {
				...state,
				workingPageLayer: action.workingPageLayer,
			};

		case "VIEWER_SET_LAYER_VISIBILITY":
			return {
				...state,
				transcriptionLayersData: {
					...state.transcriptionLayersData,
					layers: state.transcriptionLayersData.layers.map(layerToEdit => {
						if (layerToEdit.layer.id === action.layerId) {
							return { 
								...layerToEdit, 
								isShown: action.value 
							}	
						}
						return layerToEdit;
					}),
				},
				editor: {
					...state.editor,
					//verseActive: null,
				}
			};

		case "VIEWER_SET_SINGLE_VISIBLE_LAYER":
			return {
				...state,
				transcriptionLayersData: {
					...state.transcriptionLayersData,
					layers: state.transcriptionLayersData.layers.map(layerToEdit => {
						if (layerToEdit.layer.id === action.layerId) {
							return { 
								...layerToEdit, 
								isShown: true 
							}	
						} else {
							return { 
								...layerToEdit, 
								isShown: false 
							}	
						}
					}),
				},
				editor: {
					...state.editor,
					verseActive: null,
				}
			};

		case "VIEWER_RELOAD_VERSES_AFTER_CHANGING_VISIBILITY":
			return {
				...state,
				data: {
					...state.data,
					verses: action.verses
				}
			}

		case "VIEWER_SET_SEARCH_RESULTS":
			return {
				...state,
				searchResults: {
					searchedPhrase: action.phraseSearchedFor,
					totalHits: action.results.totalHits ? action.results.totalHits : 0,
					pages: action.results.pages ? action.results.pages : new Map()
				}
			}

		case "VIEWER_SET_TRANSCRIPTION_PAGES_DATA": 
			return {
				...state,
				transcriptionPagesData: action.transcriptionPagesData
			}

		case "VIEWER_SET_TRANSCRIPTION_LAYERS_DATA":
			return {
				...state,
				transcriptionLayersData: action.transcriptionLayersData
			}

		case "VIEWER_SET_SELECTED_TRANSCRIPTION_LAYER":
			return {
				...state,
				selectedLayer: action.selectedLayer,
				editor: {
					...state.editor,
					verseActive: null,
				}
			}
		
		default:
			return state;
	}
}
