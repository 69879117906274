/* eslint-disable no-console */
import React, { useState, useEffect, memo, useRef } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import { useDebounce } from 'use-debounce';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useShowModal } from '@Elements/Modal/ModalHooks';

import Button from "@Elements/Button/Button";

const useOnScreen = (ref) => {

	const [ isIntersecting, setIntersecting ] = useState(false);

	const observer = new IntersectionObserver(
		([ entry ]) => setIntersecting(entry.isIntersecting),
	);

	useEffect(() => {
		observer.observe(ref.current);
		return () => { observer.disconnect(); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isIntersecting;
};

/** 
 * Verse that can be edited, but currently is not
 * 
 * @component
 */
const VerseInactive = ({ verse, toggleVerseEdit, toggleVerseActive }) => {
	const { t } = useTranslation();

	const { transcriptionId, pageId } = useParams();
	const isMobile = useSelector((state) => state.viewer.isMobile);
	const viewData = useSelector((state) => state.viewer.data);
	const viewMode = useSelector((state) => state.viewer.data.viewMode);
	const verseActive = useSelector((state) => state.viewer.editor.verseActive);
	const verseEdit = useSelector((state) => state.viewer.editor.verseEdit);
	const modalActive = useSelector((state) => state.modal.show);
	const mobilePanels = useSelector((state) => state.app.viewer.mobilePanels);
	const searchResults = useSelector((state) => state.viewer.searchResults);
	const layersData = useSelector((state) => state.viewer.transcriptionLayersData);

	const verseRef = useRef();
	const isVisible = useOnScreen(verseRef);

	const readOnly = viewMode === 'read-only';

	const [ clickNum, setClickNum ] = useState(0);
	const [ debounceClick ] = useDebounce(clickNum, 200);
	const [ contentClass, setContentClass ] = useState('verse__content');

	const downHandler = ({ keyCode }) => {
		if (keyCode === 46
		) {
			let activeElement = document.activeElement;
			if (!readOnly && verseActive && !verseEdit && !modalActive && activeElement.nodeName !== 'INPUT') {
				modalDeleteVerse();
			}
		}
	};

	const editCondition = !(
		readOnly ||
        isMobile ||
        verse.image ||

        (viewMode === 'student' && !(mobilePanels === 'student'))
	);

	useEffect(() => {
		if (verseActive === verse.id && !isVisible) {
			verseRef.current.scrollIntoView({
				behavior: "smooth",
			});
		}
	}, [ isVisible, verse.id, verseActive ]);

	useEffect(() => {
		window.addEventListener('keydown', downHandler);
		return () => {
			window.removeEventListener('keydown', downHandler);
		};
	}, [ verseActive, verseEdit, modalActive ]);

	useEffect(() => {
		if (debounceClick >= 2 && editCondition) {
			toggleVerseEdit(verse.id);
		} else if (clickNum > 0) {
			toggleVerseActive(verse.id);
		}
		setClickNum(0);
	}, [ debounceClick ]);

	const modalDeleteVerse = useShowModal({
		title:t('modals.deleteVerse.title'),
		type: 'viewer',
		name: 'deleteVerse',
		componentPath: './parts/ModalDeleteVerse',
		componentProps: { transcriptionId: parseInt(transcriptionId), pageId: parseInt(pageId) },
	});

	useEffect(() => {
		if(searchResults.pages.has(pageId)){
			if(searchResults.pages.get(pageId).verses.includes(verse.id + "")){
				setContentClass('verse__content verse__lightup');
			}else{
				setContentClass('verse__content');
			}
		}else{
			setContentClass('verse__content');
		}
	});

	return (
		<div
			className={ `verse__block verse__block--inactive${verse.id === verseActive ? ' active' : ''} ${verseEdit ? ' activeEdit' : ''}` }
			ref={ verseRef }
		>
			<Button
				onClick={ () => setClickNum(clickNum + 1) }
				variant='verse'
			>
				<div className='verse__num'>
					{verse.verseNo}
					<sub>[{layersData.idToNumber.get(verse.pageLayer.layerId)}]</sub>
				</div>
				<div
					className={ contentClass } 
					dangerouslySetInnerHTML={ { __html: verse.content } }
				/>

			</Button>
			{editCondition &&
			<Button
				onClick={ () => {
                		toggleVerseEdit(verse.id);
                	} }
				variant='verse-edit'
			>
				<span className='d-none'>Edit</span>
				<i className='icon-edit' />
			</Button>}
		</div>
	);
};

VerseInactive.propTypes = {
	verse: PropTypes.object,
	toggleVerseEdit: PropTypes.func,
	toggleVerseActive: PropTypes.func,
};

export default memo(VerseInactive);