import React from "react";
import { useTranslation } from 'react-i18next';
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

import FormInput from "@Elements/Form/FormInput";

const TranscriptionNewAnalyticalLevel = ({ languageOptions, roleOptions, isDisabled }) => {
	const { register, errors, errorMessage, watch } = useFormContext();  // eslint-disable-line
	const { t } = useTranslation();


	return (
		<>
			<div className='transcription-new__level'>
				<FormInput
					name='analyticInfo.title'
					register={ register }
					label={ `${t('transcription.metadata.fieldTitleWithRequiredInfo.projectName')}` }
					placeholder={t('transcription.metadata.fieldPlaceholder.projectName')  }
					validateData={
						{
							validate: value => value !== '' ||t('form.messages.requiredProjectName'),
						}
					}
					isDisabled={ isDisabled }
					errorMessage={ errors.analyticInfo && errors.analyticInfo.title && errors.analyticInfo.title.message ? errors.analyticInfo.title.message : '' }
					isRequired
				/>
			</div>
			<h2 className='transcription-new__sectionTitle2'>
				{t('transcription.new.section2')}
			</h2>
		</>
	);
};

TranscriptionNewAnalyticalLevel.propTypes = {
	languageOptions: PropTypes.array,
	roleOptions: PropTypes.array,
	isDisabled: PropTypes.bool,
};

export default TranscriptionNewAnalyticalLevel;