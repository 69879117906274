import React from 'react'

import styles from "@Pages/Transcription/TranscriptionIndexes/TranscriptionIndexes.module.sass";

function TranscriptionIndexesItemsLetterIndicator({letter, style}) {
    const letterToShow = letter.toUpperCase()
    return (
        <div className={styles["indexes__table--content-indicator"]}>
            <div className={styles["indexes__table--content-indicator-letter"]}>
                {letterToShow}
            </div>
            <div className={styles["indexes__table--content-indicator-line"]}></div>
        </div>
    )
}

export default TranscriptionIndexesItemsLetterIndicator
