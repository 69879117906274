const SecurityGroupsPermissions = {
    "canCreateTranscription": ["USER", "SUPER_ADMIN"],
    "canAccessProjectsDashboard": ["USER", "SUPER_ADMIN"],
    "canChangeProjectLimit": ["SUPER_ADMIN"],
    "canAccessUsersDashboard": ["SUPER_ADMIN"],
    "canTrainOCR": ["SUPER_ADMIN"],
    "canAccessAriadnaServiceManagement": ["SUPER_ADMIN"],
    "canAccessAriadnaUserManagement": ["SUPER_ADMIN"],
    "canManageTranscriptionExportToDLibra": ["SUPER_ADMIN", "EXPORT_REVIEWER"],
};

/**
 * Returning information whether given securityGroup have specific permission basing this on SecurityGroupsPermissions
 * @param {string} permission
 * @param {string} securityGroup 
 * @return {bool}
 * 
 * @function
 */
export const CheckSecurityGroupPermission = (permission, securityGroup) => {
    return SecurityGroupsPermissions[permission].includes(securityGroup);
};
