import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Container } from "react-bootstrap";

import { appActions } from '@/store/actions/app.actions';
import HeaderTopLang from "@Templates/header/AppHeader/HeaderTopLang";

import Button from "@Elements/Button/Button";

import styles from "@Templates/header/AppHeader/HeaderTopBar.module.sass";

import TopBarMenuRow from "@Templates/header/AppHeader/TopBarMenuRow";
import TopBarMenuRowMobile from "@Templates/header/AppHeader/TopBarMenuRowMobile";

import contrastIcon from '@Assets/images/contrast.svg';

const HeaderTopBar = ({ setLang, collapsed }) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	let currentAppState = useSelector((state) => state.app);
	const isContrast = currentAppState.isContrast;
	const fontSize = currentAppState.fontSize;

	const [isMobile, setIsMobile] = useState(window.innerWidth > 991.98);

	const updateMedia = () => {
		setIsMobile(window.innerWidth > 991.98);
	};
  
	useEffect(() => {
	  window.addEventListener("resize", updateMedia);
	  return () => window.removeEventListener("resize", updateMedia);
	});


	const setContrast = (contrastState) => {
		dispatch(appActions.setContrast(contrastState));
		document.querySelector("body").classList[contrastState ? "add" : "remove"]("contrast");
	};

	const setFontSize = (fontSizeState) => {
		switch (fontSizeState) {
			case 'font-normal':
				return (
					document.querySelector("html").classList.remove(fontSize),
					dispatch(appActions.setFontSize('font-medium'))
				);
			case 'font-medium':
				return (
					document.querySelector("html").classList.remove(fontSize),
					dispatch(appActions.setFontSize('font-big'))

				);
			case 'font-big':
				return (
					document.querySelector("html").classList.remove(fontSize),
					dispatch(appActions.setFontSize('font-normal'))
				);
			default:
				return (
					document.querySelector("html").classList.remove(fontSize),
					dispatch(appActions.setFontSize('font-medium'))
				);
		}
	};

	return (
		<div className={ `${collapsed ? styles["top-bar__collapsed"] : styles["top-bar"]
            }` }
		>
			<Container className={ styles["top-bar__container"] }>
				<Row className={ styles["top-bar__row"] }>
					<div className={ styles["top-bar__item--access"] }>
						<Button
							variant='link'
							onClick={ () => setFontSize(fontSize) }
							className={ styles["top-bar__item"] + " " + styles["top-bar__item--font"] }
						>
							{t('headers.font')}
							{fontSize === 'font-medium' && <i className='icon-accept' />}
							{fontSize === 'font-big' && <i className='icon-accept-double' />}
						</Button>

						<Button
							variant='link'
							onClick={ () => setContrast(!isContrast) }
							className={
								isContrast ? styles["top-bar__item"] + " " + styles["top-bar__item--contrast"] + " " + styles["top-bar__item--contrast-selected"] 
								: styles["top-bar__item"] + " " + styles["top-bar__item--contrast"]
							}
						>
							{isContrast ? t('headers.lowerContrast') : t('headers.contrast') }
							{isContrast && <><img style={{position: "absolute", top: "-5px", right: "-16px"}} src={contrastIcon} /></>}
						</Button>
					</div>
					<div className={ styles["top-bar__item"] + " " + styles["top-bar__item--lang"] }>
						<HeaderTopLang setLang={ setLang } />
					</div>
					<div className={ styles["top-bar__dividing-line"] } style={{ marginRight: "15px" }}></div>
					<div
						className={isMobile ? styles["top-bar__ue-logo"] : ""}
					>
					</div>
				</Row>

				{isMobile ? <TopBarMenuRow/> : <TopBarMenuRowMobile/>}
			</Container>
		</div>
	);
};

HeaderTopBar.propTypes = {
	setLang: PropTypes.func,
	collapsed: PropTypes.bool,
};

export default HeaderTopBar;
