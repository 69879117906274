import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { useForm, FormContext } from 'react-hook-form';
import { useHistory } from 'react-router';

import Button from "@Elements/Button/Button";
import Frame from "@Elements/Frame/Frame";
import FormCheckbox from "@Elements/Form/FormCheckbox";
import FormSubmit from "@Elements/Form/FormSubmit";

import { useShowModal } from '@Elements/Modal/ModalHooks';
import url from "@/router/urls";

import TranscriptionNewAnalyticalLevel from '@Pages/Transcription/TranscriptionNew/parts/TranscriptionNewAnalyticalLevel';
import TranscriptionNewTranscriptionInfoLevel from '@Pages/Transcription/TranscriptionNew/parts/TranscriptionNewTranscriptionInfoLevel';

const TranscriptionNewForm = ({ transcriptionId, defaultValues, isDisabled, roleOptions, biblTypes, languageOptions, onExportData, langs, isImported, isPublic, forcePublish }) => {
	const { t } = useTranslation();

	const [ description, setDescription ] = useState(defaultValues.transcriptionInfo.description);
	const [ documentSource, setDocumentSource ] = useState(defaultValues.transcriptionInfo.documentSrc ? "www-website" : "internal-collection");
	const [ documentOrigin, setDocumentOrigin ] = useState(defaultValues.transcriptionInfo.imgSrc);
	const [ projectColaboratorInstruction, setProjectColaboratorInstruction ] = useState(defaultValues.transcriptionInfo.guidelines);

	const history = useHistory();

	const formMethods = useForm(
		{
			mode: 'onSubmit',
			reValidateMode: 'onChange',
			defaultValues,
		},
	);

	useEffect(()=>{
		formMethods.setValue('transcriptionInfo.descriptionInputHidden', description);
	},[description]);

	useEffect(()=>{
		if(documentSource == "internal-collection"){
			formMethods.setValue('transcriptionInfo.documentSrc', "");
		}
	},[documentSource]);

	useEffect(()=>{
		formMethods.setValue('transcriptionInfo.documentOriginInputHidden', documentOrigin);
	},[documentOrigin]);

	useEffect(()=>{
		formMethods.clearError();
	},[isPublic]);

	const analyticInfo = formMethods.watch('analyticInfo');
	const monographicInfo = formMethods.watch('monographicInfo');

	const parseActors = (actors, actorsDataSource) => actors.map((item, index) => (item.name.length ? {
		name: item.name,
		role: { code: actorsDataSource.actors[index].role },
	} : null)).filter(it => it);

	const parseData = (data, dataSource) => (dataSource && dataSource !== 'none') ? ({
		code: dataSource,
	}) : null;

	const onSubmit = data => {
		data.transcriptionInfo.description = description;
		data.transcriptionInfo.imgSrc = documentOrigin;
		data.transcriptionInfo.guidelines = projectColaboratorInstruction;
		data.profileDesc.keywords = data.profileDesc.keywords.split(';');

		// if (Object.keys(data.profileDesc.links).length) {
		// 	data.profileDesc.links = data.profileDesc.links.map((item) => item.name);
		// }

		// data.analyticInfo.actors = parseActors(data.analyticInfo.actors, analyticInfo);

		// data.analyticInfo.language = parseData(data.analyticInfo.language, analyticInfo.language);

		// data.monographicInfo.actors = parseActors(data.monographicInfo.actors, monographicInfo);
		// data.monographicInfo.language = parseData(data.monographicInfo.language, monographicInfo.language);
		// data.monographicInfo.biblType = parseData(data.monographicInfo.biblType, monographicInfo.biblType);

		onExportData(data);
	};

	const modalTerms = useShowModal({
		title:t('modals.regulations.title'),
		name: 'regulations',
		componentPath: './parts/ModalRegulations',
	});

	return (
		<form
			className='transcription-new__form'
			onSubmit={ formMethods.handleSubmit(onSubmit) }
		>
			<FormContext { ...formMethods }>
				<TranscriptionNewAnalyticalLevel
					isDisabled={ isDisabled }
					roleOptions={ roleOptions }
					languageOptions={ languageOptions }
					defaultValues={ defaultValues }
					langs={ langs }
				/>
				<TranscriptionNewTranscriptionInfoLevel
					isDisabled={ isDisabled }
					roleOptions={ roleOptions }
					languageOptions={ languageOptions }
					defaultValues={ defaultValues }
					langs={ langs }
					description={ description }
					setDescription={ setDescription }
					documentSource={ documentSource }
					setDocumentSource={ setDocumentSource }
					documentOrigin={ documentOrigin }
					setDocumentOrigin={ setDocumentOrigin }
					projectColaboratorInstruction={ projectColaboratorInstruction }
					setProjectColaboratorInstruction={ setProjectColaboratorInstruction }
					isPublic={ isPublic }
				/>

			</FormContext>
			{ (!transcriptionId || forcePublish) &&
				<Frame
					title={t('transcription.new.frameTitle')}
					text={ forcePublish?t('transcription.new.frameTextPublish') :t('transcription.new.frameText')}
				/>}

			{!transcriptionId && (
				<div className='transcription-new__form--terms'>
					<div style={{ "marginBottom": "20px" }}>{t('transcription.new.terms')}
						<Button
							variant='link'
							onClick={ () => modalTerms() }
						>{t('transcription.new.termsRegLink')}
						</Button>
						{t('transcription.new.termsPart2')}.
					</div>
					<FormCheckbox
						name='acceptedRegulations'
						register={ formMethods.register }
						validateData={ {
							required: {
								value: true,
								message:t('form.messages.notAcceptedRegulations'),
							},
						} }
						errorMessage={ formMethods.errors.acceptedRegulations && formMethods.errors.acceptedRegulations.message }
					>
						<span className='asterisk'>*</span>
						{t('transcription.new.termsNew1')}
						<Button
							variant='link'
							onClick={ () => modalTerms() }
						>{t('transcription.new.termsLink')}
						</Button>
						{t('transcription.new.termsNew2')} 
						{" " + t('transcription.new.termsNew3')}
						{/* <span className={"transcription-new__form-required"}> *</span> */}
					</FormCheckbox>

				</div>
			)}

			<p className='transcription-new__required-info'><span className='asterisk'>*</span> {t('form.requiredInfo') }</p>

			<div className='transcription-new__form--btns'>
				<Button
					variant='secondary'
					className='transcription-new__form--cancel'
					onClick={ () => transcriptionId ? history.goBack() : history.push(url.home) }
				>{t('form.cancel')}
				</Button>
				<FormSubmit
					label={ transcriptionId ? ( forcePublish?t('form.publishTranscription')  :t('form.saveTranscription') ) :t('form.createNewTranscription') }
					className='transcription-new__form--submit'
					variant='submit'
				/>
			</div>
		</form>
	);
};
TranscriptionNewForm.propTypes = {
	transcriptionId: PropTypes.number,
	defaultValues: PropTypes.object,
	isDisabled: PropTypes.bool,
	roleOptions: PropTypes.array,
	biblTypes: PropTypes.array,
	languageOptions: PropTypes.array,
	onExportData: PropTypes.func,
	setErrorCode: PropTypes.string,
	langs: PropTypes.array,
	isImported: PropTypes.bool,
};

export default TranscriptionNewForm;
