import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Button from "@Elements/Button/Button";
import { viewerActions } from '@/store/actions/viewer.actions';
import { CheckViewModePermission } from "@/permissions/ViewModes";

import styles from '@Pages/Viewer/parts/ViewerLayers/ViewerLayersChooser.module.sass';

/** 
 * Layer selector in viewer, visible differently for readOnly users and transcribers
 * 
 * @component
 */
const ViewerLayersChooser = () => {
	const { t } = useTranslation();

	const isMobile = useSelector((state) => state.viewer.isMobile);
	const layersData = useSelector((state) => state.viewer.transcriptionLayersData);
	const selectedLayer = useSelector(state => state.viewer.selectedLayer);
	const dispatch = useDispatch();
	const { transcriptionId, pageId } = useParams();
	const viewMode = useSelector((state) => state.viewer.data.viewMode);
	const readOnly = !CheckViewModePermission("hasAdvancedLayerViewing", viewMode);
    
	useEffect(() => {
		if(readOnly) {
			readOnlyLayerClick(selectedLayer);
		}
	},[readOnly, selectedLayer]);

	const changeVisibility = (layerId) => {
		for(let layer of layersData.layers){
			if(layer.layer.id === layerId){
				dispatch(viewerActions.selectTranscriptionLayerVisibility(transcriptionId, layerId, !layer.isShown));
			}
		}
	};

	const readOnlyLayerClick = (layerId) => {
		dispatch(viewerActions.selectTranscriptionLayer(transcriptionId, layerId));
		dispatch(viewerActions.setSingleVisibleLayer(layerId));
	};

	const handleRadioClick = (event) => {
		dispatch(viewerActions.selectTranscriptionLayer(transcriptionId, event.target.value));
		dispatch(viewerActions.selectTranscriptionLayerVisibility(transcriptionId, event.target.value, true));
	};
	if(readOnly || isMobile){ 
		return (
			<>
				<div
					className={styles['layers__container']}
				>
					<div className={styles["layers__container__title"]}>{t('viewer.layers.container.titleReadOnly') + ":" }</div>
					{layersData.layers.map(item => {
						return (
							<div
								key={item.layer.id}
								className={styles['layers__container__wrapper']}
							>
								<Button 
									disabled={parseInt(item.layer.id) === parseInt(selectedLayer)}
									onClick={()=>readOnlyLayerClick(item.layer.id)}
									className={styles['layers__container__wrapper--show-single-layer']}
								>
									[{layersData.idToNumber.get(item.layer.id)}] { item.layer.name }
								</Button>
							</div>
						);
					})}
				</div>
			</>
		);
	}else{
		return (
			<>
				<div
					className={styles['layers__container']}
				>
					<div className={styles["layers__container__title"]}>{t('viewer.layers.container.title') + ":" }</div>
					{layersData.layers.map(item => {
						return (
							<div
								key={item.layer.id}
								className={styles['layers__container__wrapper']}
							>
								<input
									type='radio' 
									id={`layer-${item.layer.id}`} 
									value={item.layer.id} 
									name='layers'
									checked={parseInt(item.layer.id) === parseInt(selectedLayer)} 
									onChange={(e) => handleRadioClick(e)}
									className={styles['layers__container__wrapper--input']}
								/>
								<label 
									htmlFor={`layer-${item.layer.id}`} 
									className={styles['layers__container__wrapper--label']}
								>
									[{layersData.idToNumber.get(item.layer.id)}] { item.layer.name }
								</label> 
								<Button 
									disabled={parseInt(item.layer.id) === parseInt(selectedLayer)}
									onClick={()=>changeVisibility(item.layer.id)}
									className={styles['layers__container__wrapper--hide-btn']}
								>
									{item.isShown ?t('viewer.layers.container.hide') :t('viewer.layers.container.show')}
								</Button>
							</div>
						);
					})}
				</div>
			</>
		);
	}
};

export default ViewerLayersChooser;