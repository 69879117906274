import React, { useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import store from '@/store/store';
import { authActions } from "@/store/actions/auth.actions";
import { appActions } from "@/store/actions/app.actions";
import url from "@/router/urls";
import api from "@/services/api";

import { CheckSecurityGroupPermission } from "@/permissions/SecurityGroups";

import { useShowModal } from '@Elements/Modal/ModalHooks';

import HeaderMyAccount from "@Templates/header/AppHeader/HeaderMyAccount";

import styles from "@Templates/header/AppHeader/HeaderTopBar.module.sass";

const TopBarMenuRow = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const isLoggedIn = useSelector((state) => state.auth.credentials.isLoggedIn);
	const [ UserType, setUserType ] = useState("USER");

	const dictionaryUrl = useSelector(state => state.app.config.dictionary);
	const contactUrl = useSelector(state => state.app.config.contact);
	const privacyPolicyUrl = useSelector(state => state.app.config.privacyPolicy);
	const accessibilityStatementUrl = useSelector(state => state.app.config.accessibilityStatement);
	const regulationsUrl = useSelector(state => state.app.config.regulations);

	const ariadnaWwwParticipantsUrl = useSelector(state => state.app.config.ariadnaWwwParticipants);
    const ariadnaWwwProjectInfoUrl = useSelector(state => state.app.config.ariadnaWwwProjectInfo);
    const ariadnaWwwFinancialInfoUrl = useSelector(state => state.app.config.ariadnaWwwFinancialInfo);

    const showPublicAccountInfo = useShowModal({
		title:t('modals.publicAccountInfo.title'),
		name: 'publicAccountInfo',
		componentPath: './parts/ModalPublicAccountInfo',
        componentProps: {securityGroup: UserType},
        withClose: true,
	});

    useLayoutEffect(() => {
		if (isLoggedIn === true) {
			api
				.get("/users/my-profile")
				.then(response => {
					const { user } = response.data;
					setUserType(user.securityGroup);
					dispatch(authActions.setAuthSecurityGroup(user.securityGroup));
				});
		}
	}, [ isLoggedIn ]);

    const resetErrorFlags = () => {
		store.dispatch(appActions.setNotFound(false));
		store.dispatch(appActions.setError(false));
	};

    return (
        <Row className={styles["top-bar__row--menu"]}>
            <div className={styles["top-bar__item"]}>
                <Dropdown className={styles["lang-dropdown"]} alignRight="true">

                    <Dropdown.Toggle
                        id='dropdown-custom-11'
                        className={styles["dropdown__button"]}
                        variant={styles["dropdown__button"]}
                    >{t('headers.menu.projects')}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={styles["lang-dropdown__menu"]}>
                        <Link
                            className='dropdown-item'
                            to={{pathname: url.transcriptionsUser, state: { isProtected: true } }}
                            onClick={resetErrorFlags}
                        >{t('headers.menu.myProjects') }
                        </Link>
                        <Link
                            className='dropdown-item'
                            to={{pathname: url.transcriptions, state: { isProtected: false } }}
                            onClick={resetErrorFlags}
                        >{t('headers.menu.allProjects') }
                        </Link>
                        <Dropdown.Item
                            className={styles["dropdown__button--divider"]}
                            disabled
                        />
                        { CheckSecurityGroupPermission("canCreateTranscription", UserType) ? <>
                            
                            <Link
                                className='dropdown-item'
                                to={{pathname: url.transcription.new, state: { isProtected: true } }}
                                onClick={resetErrorFlags}
                            >{t('headers.menu.newProject') }
                            </Link> 
                        </> : <>
                            <Dropdown.Item 
                                className='dropdown-item'
                                onClick={ () => showPublicAccountInfo() }
                            >{t('headers.menu.newProject') }
                            </Dropdown.Item> 
                        </>}
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <div className={styles["top-bar__item"]}>
                <Dropdown className={styles["lang-dropdown"]}  alignRight="true">
                    <Dropdown.Toggle
                        id='dropdown-custom-11'
                        className={styles["dropdown__button"]}
                        variant={styles["dropdown__button"]}
                    >{t('headers.informations')}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={styles["lang-dropdown__menu"]}>
                        <div className={styles["dropdown__wrapper"]}>
                            <Dropdown.Item
                                disabled                                
                                className={styles["dropdown__button--disabled-black"]}
                            >
                                {t('headers.informationsMenu.contactInfo')}
                            </Dropdown.Item>
                            <Dropdown.Item
                                href={contactUrl}
                                target="_blank"
                            >
                                {t('headers.informationsMenu.contact')}
                            </Dropdown.Item>
                        </div>

                        <div className={styles["dropdown__wrapper"]}>
                            <Dropdown.Item
                                disabled
                                className={styles["dropdown__button--disabled-black"]}
                            >
                                {t('headers.informationsMenu.helpersInfo')}
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to={url.howToTranscribe}>
                                {t('headers.informationsMenu.howTo')}
                            </Dropdown.Item>
                            <Dropdown.Item 
                                href={dictionaryUrl} 
                                target="_blank"
                            >
                                {t('headers.informationsMenu.dictionary')}
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to={url.map}>
                                {t('headers.informationsMenu.pageMap')}
                            </Dropdown.Item>
                        </div>

                        <div className={styles["dropdown__wrapper"]}>
                            <Dropdown.Item
                                disabled
                                className={styles["dropdown__button--disabled-black"]}
                            >
                                {t('headers.informationsMenu.privacyInfo')}
                            </Dropdown.Item>
                            <Dropdown.Item 
                                href={privacyPolicyUrl} 
                                target="_blank"
                            >
                                {t('headers.informationsMenu.privacy')}
                            </Dropdown.Item>
                            <Dropdown.Item 
                                href={accessibilityStatementUrl} 
                                target="_blank"
                            >
                                {t('headers.informationsMenu.availability')}
                            </Dropdown.Item>
                            <Dropdown.Item 
                                href={regulationsUrl}
                                target="_blank"
                            >
                                {t('headers.informationsMenu.terms')}
                            </Dropdown.Item>
                        </div>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <div className={styles["top-bar__item"]}>
                <Dropdown className={styles["lang-dropdown"]}  alignRight="true">
                    <Dropdown.Toggle
                        id='dropdown-custom-11'
                        className={styles["dropdown__button"]}
                        variant={styles["dropdown__button"]}
                    >{t('headers.about')}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={styles["lang-dropdown__menu"]}>
                        <Dropdown.Item 
                            href={ariadnaWwwFinancialInfoUrl}
                            target="_blank"
                        >
                            {t('headers.aboutMenu.financed')}
                        </Dropdown.Item>
                        <Dropdown.Item 
                            href={ariadnaWwwProjectInfoUrl}
                            target="_blank"
                        >
                            {t('headers.aboutMenu.project')}
                        </Dropdown.Item>
                        <Dropdown.Item 
                            href={ariadnaWwwParticipantsUrl}
                            target="_blank"
                        >
                            {t('headers.aboutMenu.contributors')}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <div className={styles["top-bar__item"]}>
                <Dropdown className={styles["lang-dropdown"]}  alignRight="true">

                    <Dropdown.Toggle
                        id='dropdown-custom-11'
                        className={styles["dropdown__button"]}
                        variant={styles["dropdown__button"]}
                    >{t('headers.modules')}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={styles["dropdown__menu"]}>
                        <div className={styles["dropdown__modules"]}>
                            <div>
                                <p className={styles["dropdown__modules--head"]}>{t('headers.modulesMenu.ariadna')}</p>
                                <p className={styles["dropdown__modules--desc"]}>{t('headers.modulesMenu.ariadnaText')}</p>
                                <a className={styles["dropdown__modules--link"]} target='_blank' href={store.getState().app.config.ariadnaWwwUrl}>{t('headers.modulesMenu.goTo')}</a>
                            </div>
                            <div>
                                <p className={styles["dropdown__modules--head"]}>{t('headers.modulesMenu.edu')}</p>
                                <p className={styles["dropdown__modules--desc"]}>{t('headers.modulesMenu.eduText')}</p>
                                <a className={styles["dropdown__modules--link"]} target='_blank' href={store.getState().app.config.ariadnaEduUrl}>{t('headers.modulesMenu.goTo')}</a>
                            </div>
                            <div>
                                <p className={styles["dropdown__modules--head"]}>{t('headers.modulesMenu.history')}</p>
                                <p className={styles["dropdown__modules--desc"]}>{t('headers.modulesMenu.historyText')}</p>
                                <a className={styles["dropdown__modules--link"]} target='_blank' href={store.getState().app.config.ariadnaWolUrl}>{t('headers.modulesMenu.goTo')}</a>
                            </div>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className={ styles["top-bar__dividing-line"] }></div>
            <div className={ styles["top-bar__item"] }>
                <HeaderMyAccount isLoggedIn={isLoggedIn} UserType={UserType} />
            </div>
        </Row>
    )
}

export default TopBarMenuRow;