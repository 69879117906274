import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from "react-router-dom";
import { useParams } from 'react-router';

import { viewerActions } from '@/store/actions/viewer.actions';

import updateUrl from "@/utils/viewerHelper";

/** 
 * Footer used in viewer containing functionality of searching a transcription for certain phrases
 * 
 * @component
 */
const ViewerFooter = () => {
	const { t } = useTranslation();

    const isMobile = useSelector((state) => state.viewer.isMobile);
    const noAccess = useSelector((state) => state.viewer.noAccess);
    const searchResults = useSelector((state) => state.viewer.searchResults);
    const transcriptionPagesData = useSelector((state) => state.viewer.transcriptionPagesData);
    const dispatch = useDispatch();
	const location = useLocation();
    const history = useHistory();
    const { transcriptionId, pageId } = useParams();

    const [ searchedPhrase, setSearchedPhrase ] = useState("");
    const [ firstTimeOpen, setFirstTimeOpen ] = useState(true);


    const clearSearchInput = () => {
		setSearchedPhrase("");
		dispatch(viewerActions.setSearchResults("", { results: { resources: [], hits: [] } }));
        updateUrl(history, transcriptionId, pageId, "");
	};

    const searchTranscription = (phrase) => {
		dispatch(viewerActions.searchTranscriptionForPhrase(transcriptionId, phrase));
        updateUrl(history, transcriptionId, pageId, phrase);
	};

    const previousResultClick = () => {
        if(searchResults.pages.size === 0) { return; }

        let currentPageNo = transcriptionPagesData.idToPage.get(+pageId);
        let newPageNo = parseInt(currentPageNo);

        for(let i = 0; i < transcriptionPagesData.pageNum; ++i){
            if(newPageNo <= 1){
                newPageNo = transcriptionPagesData.pageNum;
            } else {
                newPageNo = newPageNo - 1;
            }
            if(searchResults.pages.has(transcriptionPagesData.pageToId.get(newPageNo) + "")){
                break;
            }
        }
        let newPageId = transcriptionPagesData.pageToId.get(+newPageNo);

        dispatch(viewerActions.loadData(transcriptionId, newPageId));
        updateUrl(history, transcriptionId, newPageId, searchedPhrase);
    };

    const nextResultClick = () => {
        if(searchResults.pages.size === 0) { return; }

        let currentPageNo = transcriptionPagesData.idToPage.get(+pageId);
        let newPageNo = parseInt(currentPageNo);

        for(let i = 0; i < transcriptionPagesData.pageNum; ++i){
            if(newPageNo >= transcriptionPagesData.pageNum){
                newPageNo = 1;
            } else {
                newPageNo = newPageNo + 1;
            }
            if(searchResults.pages.has(transcriptionPagesData.pageToId.get(newPageNo) + "")){
                break;
            }
        }
        let newPageId = transcriptionPagesData.pageToId.get(+newPageNo);

        dispatch(viewerActions.loadData(transcriptionId, newPageId));
        updateUrl(history, transcriptionId, newPageId, searchedPhrase);
    };

    useEffect(()=>{
		let query = new URLSearchParams(location.search).get('q');
        if(query && firstTimeOpen){
            setSearchedPhrase(query);
            searchTranscription(query);
            setFirstTimeOpen(false);
        }
	},[location]);

    const SearchResultsBar = () => {
		const flagClick = (event) => {
			let selected_flags = document.getElementsByClassName("selected-flag");
			for(let selected_flag of selected_flags){
				selected_flag.classList.remove("selected-flag");
			}

			let parentNode = event.target.parentNode;
			parentNode.classList.add("selected-flag");

			let pageId = parentNode.getAttribute("pagenum");

            updateUrl(history, transcriptionId, pageId, searchedPhrase);
            dispatch(viewerActions.loadData(transcriptionId, pageId));
		};

		return (
			<div className='viewer-footer__search-results'>
				<div className='search-line'>
					<div className='line'></div>
					<div className='end-line-left'></div>
					<div className='end-line-right'></div>
					<div className='page-flags'>
						{Array.from(searchResults.pages.entries()).map((entry) => {
							const [key, value] = entry;

							let calculated_left = ((transcriptionPagesData.idToPage.get(+key) - 1) / (transcriptionPagesData.pageNum - 1) * 100);

							const flagStyle = {
								left: calculated_left + "%"
							};

							let classToSet = 'page-flag'
							if(pageId === key){
								classToSet += ' selected-flag';
							}
							if(calculated_left > 90){
								classToSet += ' flag-on-right';
							}

							return <div className={classToSet} id={"flag-" + key} key={key} pagenum={key} style={flagStyle}>
										<div className='page-number'>{t('viewer.footer.searchResults.page') + " " + transcriptionPagesData.idToPage.get(+key) + " / " + transcriptionPagesData.pageNum }</div>
										<div className='flag' onClick={ flagClick }></div>
									</div>
						})}
					</div>
				</div>
			</div>
		)
	};

    if(searchResults.searchedPhrase !== ''){
        let elements_verses = document.getElementsByClassName('viewer__verses');
        if(elements_verses[0]){
            elements_verses[0].style.maxHeight = "calc(100vh - 271px)";
        }

        let elements_menu = document.getElementsByClassName('viewer__menu');
        for(let element of elements_menu){
            element.style.maxHeight = "calc(100vh - 271px)";
        }
    }else{
        let elements = document.getElementsByClassName('viewer__verses');
        if(elements[0]){
            elements[0].style.maxHeight = "";
        }

        let elements_menu = document.getElementsByClassName('viewer__menu');
        for(let element of elements_menu){
            element.style.maxHeight = "";
        }
    }

    return !noAccess ? (
        <>
            {!isMobile && searchResults.searchedPhrase !== '' && (
                <>
                    <SearchResultsBar/>
                </>
            )}
            {isMobile && searchResults.searchedPhrase !== '' && (
                <>
                    <div className='viewer-footer__search-results-mobile'>
                        <span className='found-number'>{t('viewer.footer.searchResults.foundNumberPart1') + " " + searchResults.totalHits + " " +t('viewer.footer.searchResults.foundNumberPart2') + " " + searchResults.pages.size + " " +t('viewer.footer.searchResults.foundNumberPart3') + ": " + searchResults.searchedPhrase }</span>
                    </div>
                </>
            )}
            <div className="viewer-footer">
                {!isMobile && searchResults.searchedPhrase !== '' && (
                    <>
                        <span className='found-number'>{t('viewer.footer.searchResults.foundNumberPart1') + " " + searchResults.totalHits + " " +t('viewer.footer.searchResults.foundNumberPart2') + " " + searchResults.pages.size + " " +t('viewer.footer.searchResults.foundNumberPart3') + ": " + searchResults.searchedPhrase }</span>
                        <span className='previous-result' onClick={ previousResultClick }>{t('viewer.footer.searchResults.previousResult') }</span>
                    </>
                )}
                <div className='viewer-footer__search'>
                    {searchResults.searchedPhrase !== '' && (
                        <i className="icon-close-circle" onClick={ () => clearSearchInput() }></i>
                    )}
                    {searchResults.searchedPhrase === '' && (
                        <div className="search-button" onClick={ () => searchTranscription(searchedPhrase) }>
                            <i className="icon-search"></i>
                        </div>
                    )}
                    <input
                        type="text"
                        className='viewer-footer__search--input'
                        placeholder={t('viewer.footer.search')}
                        value={searchedPhrase}
                        onChange={e => {
                            setSearchedPhrase(e.target.value);
                        }}
                        onKeyDown={e => {
                            if(e.key === 'Enter'){
                                searchTranscription(searchedPhrase);
                            }
                        }}
                    />
                </div>
                {!isMobile && searchResults.searchedPhrase !== '' && (
                    <span className='next-result' onClick={ nextResultClick }>{t('viewer.footer.searchResults.nextResult') }</span>
                )}
                {isMobile && searchResults.searchedPhrase !== '' && (<div className="move-between-results-mobile">
                    <span className='previous-result' onClick={ previousResultClick }>{t('viewer.footer.searchResults.previousResult') }</span>
                    <span className='next-result' onClick={ nextResultClick }>{t('viewer.footer.searchResults.nextResult') }</span>
                </div>)}
            </div>
        </>
    ) : null;
};

export default ViewerFooter;