const alertInitialState = {
	type: '',
	text: '',
	icon: '',
	close: false,
	important: false,
	onClose: null,
	target: 'default',
	alreadyShown: false,
};

export default alertInitialState;