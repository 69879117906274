import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { reverse } from "named-urls";
import { useHistory } from "react-router";

import Button from "@Elements/Button/Button";
import { useShowModal } from "@Elements/Modal/ModalHooks";

import { viewerActions } from "@/store/actions/viewer.actions";
import { appActions } from "@/store/actions/app.actions";

import { CheckViewModePermission } from "@/permissions/ViewModes";

import url from "@/router/urls";
import styles from "@Elements/Transcriptions/TranscriptionsItem.module.sass";

import imageOnError from "@Assets/images/icon-image.svg";

/** 
 * Component with pages of transcription
 * 
 * @component
 */
const TranscriptionsItemPage = ({
	page,
	viewMode = "read-only",
	creationMode = "web_form",
	isInTranscriptionDetails = false,
	transcriptionId,
	currentPage,
	transcriptionProcessingStatusDisabledButtons,
	matchesPerTranscription,
	layers,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation();

	const [availablePageLayers, setAvailablePageLayers] = useState(page.pageLayers.sort((a, b) => {
		const firstId = parseInt(a.layer.id);
		const secondId =  parseInt(b.layer.id);
		if (firstId < secondId) {
			return -1;
		}
		if (firstId > secondId) {
			return 1;
		}
		return 0;
	}));
	const linkToTranscriptionView = reverse(url.transcription.viewer, {
		transcriptionId: parseInt(transcriptionId),
		pageId: parseInt(page.id),
	});

	const [transcriptionOptions, setTranscriptionOptions] = useState([]);

	const modalDeleteTranscriptionPage = useShowModal({
		title:t('modals.deleteTranscriptionPage.title'),
		name: "deleteTranscriptionPage",
		componentPath: "./parts/ModalDeleteTranscriptionPage",
		componentProps: { transcriptionId: parseInt(transcriptionId) },
	});

	const modalDownloadTranscriptionPage = useShowModal({
		withClose: true,
		title:t('modals.downloadTranscriptionPage.title'),
		name: "downloadTranscriptionPage",
		componentPath: "./parts/ModalDownloadTranscriptionPage",
		componentProps: { transcriptionId: parseInt(transcriptionId) },
	});

	const modalLoadTextTranscriptionPage = useShowModal({
		withClose: false,
		title:t('modals.loadTextTranscriptionPage.title'),
		name: "loadTextTranscriptionPage",
		componentPath: "./parts/ModalLoadTextTranscriptionPage",
		componentProps: { transcriptionId: parseInt(transcriptionId), layers: layers },
	});

	useEffect(() => {
		let arr = [{ id: "download" }];
		if (CheckViewModePermission("canImportText", viewMode)) {
			arr = [...arr, { id: "loadText" }];
		}
		if (CheckViewModePermission("canRemovePages", viewMode)) {
			arr = [...arr, { id: "deletePage" }];
		}
		setTranscriptionOptions(arr);
	}, [page.percentOfTranscribed, viewMode, creationMode]);

	const downloadFileFormat = format => {
		dispatch(viewerActions.getFileByFormat(page.id, format));
	};

	const onOption = (id, chId) => {
		
		switch (id) {
			case "loadText":
				dispatch(appActions.setModalLoadTextTranscriptionPage(page.id));
				modalLoadTextTranscriptionPage();
				// history.push(
				// 	reverse(url.transcription.addTextToPage, {
				// 		transcriptionId: parseInt(transcriptionId),
				// 		pageId: parseInt(page.id)
				// 	})
				// );
				break;
			case "download":
				dispatch(appActions.setModalDownloadTranscriptionPage(page.id));
				modalDownloadTranscriptionPage();
				break;	
			case "deletePage":
				dispatch(appActions.setModalDeleteTranscriptionPage(page.id));
				modalDeleteTranscriptionPage();
				break;
			default:
				return false;
		}
		document.getElementById(chId).checked = false;
	};

	const imageRef = useRef(null);

	const handleImageOnError = () => {
		imageRef.current.className = styles["transcription__page--image-error"];
		imageRef.current.style.backgroundImage = `url(${imageOnError})`;
	};

	const handleMenuClick = (e) => {
		const all = document.getElementsByName('pageItemMenu');
		for(var i=0; i < all.length; i++) {
			if(all[i] != e.target)
				all[i].checked = false;
		}
	};

	return (
		<div className={styles["transcription__page--box"]}>
			{isInTranscriptionDetails && transcriptionOptions.length > 0 && (
				<div className={styles["navigation"]}>
					<input type='checkbox' name='pageItemMenu' className={styles["navigation__checkbox"]} id={"navi-toggle" + page.id} onClick={handleMenuClick}/>
					<label className={styles["navigation__button"]} htmlFor={"navi-toggle" + page.id} >
						<span className={styles["navigation__icon"]} >&nbsp;</span>
					</label>
					<nav className={styles["navigation__nav"]}>
						<ul className={styles["navigation__list"]}>
							{transcriptionOptions.map(transcriptionOption => (
								<li
									key={transcriptionOption.id}
									className={styles["navigation__item"]}
								>
									<a href='#' onClick={() => onOption(transcriptionOption.id, "navi-toggle" + page.id)}>
										{t('transcriptions.dropdown.'+transcriptionOption.id)}
									</a>
								</li>
							))}
						</ul>
					</nav>
				</div>
			)}
			<Link
				to={{
					pathname: linkToTranscriptionView,
					state: {
						paginationPage: currentPage,
					},
				}}
				aria-label='Link do strony'
			>

				<div
					className={styles["transcription__page--image"]}
					style={{ backgroundImage: `url(${page.thumbnailUrl})` }}
					ref={imageRef}
				>
					{page.percentOfTranscribed === 100 && (
						<div className={styles["transcription__page--ribbon"]}>{t('transcriptions.item.fullOCR')}</div>
					)}

					{page.thumbnailUrl && <img src={page.thumbnailUrl} alt={page.id} onError={handleImageOnError} />}
				</div>
			</Link>
			<div className={styles["transcription__page--block-bottom"]}>
				<Link
					to={{
						pathname: linkToTranscriptionView,
						state: {
							paginationPage: currentPage,
						},
					}}
					className={styles["transcription__page--number"]}
				>
					{t('transcriptions.item.pageNo')} {page.pageNo}
				</Link>
			
				{availablePageLayers.map((pageItem, index) => (
					<div key={index} className={styles["transcription__page--progress"]}>
						<span className={styles["transcription__page--progress-text"]}>
							<span className={styles["transcription__page--progress-text-layer"]}>
								{t('viewer.layers.container.layer')} {index + 1}
							</span>
							<span className={styles["transcription__page--progress-text-long"]}>
								{t('transcriptions.item.name')}
								{pageItem.layer.name}
							</span>
						</span>
						<span className={styles["transcription__page--progress-bar"]}>
							<div className={styles["transcription__page--progress-bar--wrapper"]}>
								<span
									className={
										page.percentOfTranscribed === 100
											? styles["transcription__page--progress-bar--active"]
											: styles["transcription__page--progress-bar--meter"]
									}
									style={{ width: `${pageItem.percentOfTranscribed}%` }}
								/>
							</div>
							<span className={styles["transcription__page--progress-value"]}>
								{pageItem.percentOfTranscribed}%
							</span>
						</span>
					</div>
				))}

				{matchesPerTranscription &&
					`${t('transcriptions.item.matchesInText')} ${matchesPerTranscription.hitCount}`}
				<div className={styles["transcription__page__magicBox"]}>
					<Link
						to={{
							pathname: linkToTranscriptionView,
							state: {
								paginationPage: currentPage,
							},
						}}
					>
						<Button variant='secondary'>{viewMode == "read-only"?t('transcriptions.item.show') :t('transcriptions.item.work') }</Button>
					</Link>
				</div>
			</div>
		</div>
	);
};

TranscriptionsItemPage.propTypes = {
	page: PropTypes.object,
	percentOfVerified: PropTypes.number,
	percentOfTranscribed: PropTypes.number,
	viewMode: PropTypes.string,
	creationMode: PropTypes.string,
	isInTranscriptionDetails: PropTypes.bool,
	transcriptionId: PropTypes.number,
	currentPage: PropTypes.number,
	transcriptionProcessingStatusDisabledButtons: PropTypes.bool,
	matchesPerTranscription: PropTypes.object,
	layers: PropTypes.array,
};

export default TranscriptionsItemPage;
